import { useEffect, useState } from "react";

/**
 * Component to allow a user to set the page title.
 * 
 * @param title The title to set the document to
 */
const useDocumentTitle = (title: string) => {
  const [document_title, setDoucmentTitle] = useState(title);
   useEffect(() => {
    document.title = document_title;
  },[document_title]);

  return [document_title, setDoucmentTitle];
};

export {useDocumentTitle};