// src/mocks/handlers.js
import dayjs from 'dayjs'
import { rest } from 'msw'

export const handlers = [

    rest.get('http://localhost:8000/api/location/search', (req, res, ctx) => {
        const mock_countries = [
            {
                "country": "Australia",
                "city": "Melbourne",
                "combined": "Melbourne, Australia"
            },
            {
                "country": "United States",
                "city": "Melbourne",
                "combined": "Melbourne, United States"
            },
            {
                "country": "United States",
                "city": "West Melbourne",
                "combined": "West Melbourne, United States"
            },
            {
                "country": "Germany",
                "city": "Hammelburg",
                "combined": "Hammelburg, Germany"
            }
        ]
        const url = new URL(req.url)
        const query = url.searchParams.get('q')
        console.log(query)
        const filtered = mock_countries.filter((country) => {
            const concat_string = ''.concat(country.country.toLowerCase(), ' ', country.city.toLowerCase())
            console.log(concat_string)
            const found = concat_string.search(query)
            console.log(found)
            if (found >= 0) {
                return country
            }
        })
        console.log(mock_countries)
        console.log(filtered)
        
        return res(
            ctx.status(200),
            ctx.json(filtered)
        )
    }),

    rest.post('http://localhost:8000/api/trips', (req, res, ctx) => {
        const {date_from} = req.params // "2024-06-21"
        const {date_to} = req.params // "2024-06-21"
        const {destination} = req.params // {city: "Melbourne", country: "Australia"}

        return res(
            ctx.status(200),
            ctx.json(
                {
                    "id": "bd9a913d-4ed6-43c7-84d6-3f8047832b60",
                    "destination": {
                        "id": "08965e17-0389-447b-8f3f-ad9c068b4ce6",
                        "image": "https://images.pexels.com/photos/415949/pexels-photo-415949.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
                        "description": "Welcome to Melbourne, Australia! Often regarded as the cultural capital of Australia, the city is a vibrant hub that bristles with a rich blend of traditional heritage and modern innovation. Known for its impressive skyline and picturesque parks, Melbourne boasts of an unrivaled charm that is infectious. With a lively art scene, delectable culinary offerings, and a bustling nightlife, it’s a city that invites you to immerse yourself in its abundant resources.\n\nDelve into the many layers of Melbourne and you'll quickly discover a strong passion for culinary treats, sports, music, art, and fashion running through its streets. Whether you choose to wander the hidden lane-ways and numerous gardens, or dive into its unique art galleries and museums, you are in for a cultural treat. Not to mention, Melbourne is the perfect gateway to explore Victoria's stunning natural landscapes, including panoramic coastlines, lush vineyards, and national parks. So strap in and prepare to be enthralled by the unending charm of Melbourne. Welcome to Australia's heart of culture and style.",
                        "latitude": -37.8136276,
                        "longitude": 144.9630576,
                        "country": "Australia",
                        "city": "Melbourne",
                        "created_at": "2024-06-21T11:43:29.902054Z",
                        "updated_at": "2024-06-21T11:43:31.204576Z"
                    },
                    "date_from": "2024-02-27",
                    "date_to": "2024-02-29",
                    "created_at": "2024-06-21T11:43:29.914487Z",
                    "updated_at": "2024-06-21T11:43:29.914524Z"
                }
            )
        )
    }),

    rest.get('http://localhost:8000/api/trips/:id/', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json(
                {
                    "id": req.params.id,
                    "destination": {
                        "id": "08965e17-0389-447b-8f3f-ad9c068b4ce6",
                        "image": "https://images.pexels.com/photos/415949/pexels-photo-415949.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
                        "description": "Welcome to Melbourne, Australia! Often regarded as the cultural capital of Australia, the city is a vibrant hub that bristles with a rich blend of traditional heritage and modern innovation. Known for its impressive skyline and picturesque parks, Melbourne boasts of an unrivaled charm that is infectious. With a lively art scene, delectable culinary offerings, and a bustling nightlife, it’s a city that invites you to immerse yourself in its abundant resources.\n\nDelve into the many layers of Melbourne and you'll quickly discover a strong passion for culinary treats, sports, music, art, and fashion running through its streets. Whether you choose to wander the hidden lane-ways and numerous gardens, or dive into its unique art galleries and museums, you are in for a cultural treat. Not to mention, Melbourne is the perfect gateway to explore Victoria's stunning natural landscapes, including panoramic coastlines, lush vineyards, and national parks. So strap in and prepare to be enthralled by the unending charm of Melbourne. Welcome to Australia's heart of culture and style.",
                        "latitude": -37.8136276,
                        "longitude": 144.9630576,
                        "country": "Australia",
                        "city": "Melbourne",
                        "created_at": "2024-06-21T11:43:29.902054Z",
                        "updated_at": "2024-06-21T11:43:31.204576Z"
                    },
                    "date_from": "2024-02-27",
                    "date_to": "2024-02-29",
                    "created_at": "2024-06-21T11:43:29.914487Z",
                    "updated_at": "2024-06-21T11:43:29.914524Z"
                }
            )
        )
    }),

    rest.get('http://localhost:8000/api/trip-styles/', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json(
                {
                    "count": 14,
                    "next": null,
                    "previous": null,
                    "results": [
                        {
                            "id": "9b575c0b-208c-4f0f-86b7-10c799d31dd5",
                            "name": "Outdoor",
                            "created_at": "2024-06-19T13:15:49.517825Z",
                            "updated_at": "2024-06-19T13:15:49.517835Z"
                        },
                        {
                            "id": "0e232ddb-1f2b-4df3-9677-19748deb569b",
                            "name": "Relaxation",
                            "created_at": "2024-06-19T13:15:49.522826Z",
                            "updated_at": "2024-06-19T13:15:49.522832Z"
                        },
                        {
                            "id": "46bee5a0-14c4-4891-a22a-076f91a599a4",
                            "name": "Child Friendly",
                            "created_at": "2024-06-19T13:15:49.525507Z",
                            "updated_at": "2024-06-19T13:15:49.525514Z"
                        },
                        {
                            "id": "33227711-cd48-43c5-8962-81c605116c4e",
                            "name": "Landmarks",
                            "created_at": "2024-06-19T13:15:49.527442Z",
                            "updated_at": "2024-06-19T13:15:49.527448Z"
                        },
                        {
                            "id": "87858780-587d-45c5-8af6-54d04a1d21e8",
                            "name": "Nature",
                            "created_at": "2024-06-19T13:15:49.529105Z",
                            "updated_at": "2024-06-19T13:15:49.529110Z"
                        },
                        {
                            "id": "779da0c4-be32-41b4-9b35-5be3667cd46a",
                            "name": "Architecture",
                            "created_at": "2024-06-19T13:15:49.530799Z",
                            "updated_at": "2024-06-19T13:15:49.530805Z"
                        },
                        {
                            "id": "19103ebe-48ac-49b8-85e6-f965c1cb4e25",
                            "name": "Historical",
                            "created_at": "2024-06-19T13:15:49.532514Z",
                            "updated_at": "2024-06-19T13:15:49.532519Z"
                        },
                        {
                            "id": "6c4ae06e-a6e9-440c-8ff2-432d51b4e510",
                            "name": "Religious",
                            "created_at": "2024-06-19T13:15:49.534127Z",
                            "updated_at": "2024-06-19T13:15:49.534131Z"
                        },
                        {
                            "id": "9ef63ea2-5b03-4941-83da-4b1452df796b",
                            "name": "Iconic",
                            "created_at": "2024-06-19T13:15:49.535943Z",
                            "updated_at": "2024-06-19T13:15:49.535948Z"
                        },
                        {
                            "id": "ee4e0f85-31cc-416b-bfc7-3ec7342eedf9",
                            "name": "Picturesque",
                            "created_at": "2024-06-19T13:15:49.537512Z",
                            "updated_at": "2024-06-19T13:15:49.537516Z"
                        },
                        {
                            "id": "3e45e8b6-e74f-46ad-9079-27498c80ae21",
                            "name": "Hiking",
                            "created_at": "2024-06-19T13:15:49.539070Z",
                            "updated_at": "2024-06-19T13:15:49.539075Z"
                        },
                        {
                            "id": "746badcd-185b-44ca-b4aa-1fd302c765cf",
                            "name": "Adventure",
                            "created_at": "2024-06-19T13:15:49.540691Z",
                            "updated_at": "2024-06-19T13:15:49.540696Z"
                        },
                        {
                            "id": "94ce5c22-44f2-4114-b9ef-77a6e2ce5651",
                            "name": "Educational",
                            "created_at": "2024-06-19T13:15:49.542106Z",
                            "updated_at": "2024-06-19T13:15:49.542110Z"
                        },
                        {
                            "id": "642169ac-28f1-447a-96df-c1ac2d5b4fa6",
                            "name": "Romantic",
                            "created_at": "2024-06-19T13:15:49.543885Z",
                            "updated_at": "2024-06-19T13:15:49.543890Z"
                        }
                    ]
                }
            )
        )
    }),

    rest.get('http://localhost:8000/api/trip-profiles/', (req, res, ctx) => {
        const {trip} = req.params
        return res(
            ctx.status(200),
            ctx.json({"count":0,"next":null,"previous":null,"results":[
                {
                    "id": "b01f4ca1-aa37-4464-bcb6-60c19a7e2fd7",
                    "styles": [
                        {
                            "id": "9b575c0b-208c-4f0f-86b7-10c799d31dd5",
                            "name": "Outdoor",
                            "created_at": "2024-06-19T13:15:49.517825Z",
                            "updated_at": "2024-06-19T13:15:49.517835Z"
                        }
                    ],
                    "total_trip_activities": 8,
                    "pace": 2,
                    "created_at": "2024-06-21T11:58:54.314281Z",
                    "updated_at": "2024-06-21T11:58:54.314314Z",
                    "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                }
            ]})
        )
    }),

    rest.post('http://localhost:8000/api/trip-profiles/', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json(
                {
                    "id": "b01f4ca1-aa37-4464-bcb6-60c19a7e2fd7",
                    "styles": [
                        {
                            "id": "9b575c0b-208c-4f0f-86b7-10c799d31dd5",
                            "name": "Outdoor",
                            "created_at": "2024-06-19T13:15:49.517825Z",
                            "updated_at": "2024-06-19T13:15:49.517835Z"
                        }
                    ],
                    "total_trip_activities": 8,
                    "pace": 2,
                    "created_at": "2024-06-21T11:58:54.314281Z",
                    "updated_at": "2024-06-21T11:58:54.314314Z",
                    "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                }
            )
        )
    }),

    rest.post('http://localhost:8000/api/trips/:tripId/similar-from-ratings/', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json(
                [
                    {
                        "id": "819b777b-eaf6-4d26-bba6-c01b3c86db21",
                        "rating": null,
                        "activity": {
                            "id": "8c4e6c41-2d4b-4569-bcf4-8e6440b9c838",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Royal Botanic Gardens Victoria",
                            "description": "Located at the heart of green parkland extending south of the Yarra River, the Royal Botanic Gardens Victoria is a treasure trove of horticultural biodiversity with a collection of 8,500 plant species, beautifully landscaped gardens and sanctuaries, and the beautifully restored 19th-century Garden House.",
                            "website": null,
                            "images": [
                                {
                                    "id": "f05b28d8-6475-4191-a880-1e2242b3a186",
                                    "google_photo_id": "AUc7tXXxUNqCqZ0Zlu9IeN_Ggdsi7FxJIfpPUsk6j2Wd2Sc5kEcw3WRs9s5v4gGZ41fKTsSBNW41D_7_XbeXIHjEhOCIo3DkUG11QYVdQY_V2oGI6CrrtN6gQEwzNnRxRJ22K-NCcsQCkq3P_paJh26iMjhIsIuU3neSfENdCHCS2V5fL8RC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHWuVuHKV__qClsssB_N75JxsDRpUCHpU-KQh2Pi1NeE9OLDsjGn3qCIe4N2x0PaExGKToLrU42bSytsSiuNzXbkG-dpLK2j6o=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110773365454945438434\">Luke Baker</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.398743Z",
                                    "updated_at": "2024-06-21T12:00:54.398757Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "cafdc0d9-b40d-4fea-b536-b36398629c35",
                                    "google_photo_id": "AUc7tXU4kvdnrWIqAlm3dxvJop-SREK1yw9y6KlV5-_GpP96y3aB88xbzTRa8aCgnf6wcpvYrBGj1UpfgF5bGtLmEEA-Gf6wpYPM3japo_MZOM0Rj4yvOKwb06HsaSnQNTt1v2Gy0VysG5ZLSDHVxag13v6C4mHAbgAc6qGz5jQ6qqQu2S-K",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEvjs5p2C35wRgDBFKNpErUIZlnicEIl8_LI80roTFMlvMScmWqtoo3e1tIDCSc-FIsGTVkW4EbqywIZaaqEVTp-EtGtw4m-P0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108164069666858808077\">Simon Labone</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.158414Z",
                                    "updated_at": "2024-06-21T12:00:55.158426Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "d92f7362-5daf-448b-bcb8-47ea489ab7ed",
                                    "google_photo_id": "AUc7tXX1FBJY4ro9oh3F9Sro6sTUF2ImvgjWQomQFT5rG6rzhsXs2bKWJz1t3X_Vqv3I5kn88QQCKwHPSoFMWRee3fVtQvo1UAH6SpxWQQbxFAjH1pSuNp73uo8akSDksysrvGLEd0Bqglgnm8navD5oimZkMjbeWdnaPlxy2AAgewNAdHF6",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHIqQTov1YMUjwlmKzM0-gICXJKCVM3wenZ1kabamagEoH7i0DJOV0Pby-OHvheptatkpiC1vol1S2EXB6DadAjYcHGWeqRxjw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101855308381690838780\">Ivonna</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.199516Z",
                                    "updated_at": "2024-06-21T12:00:56.199547Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "0fb8da3c-9757-4348-a01b-05eb5a143386",
                                    "google_photo_id": "AUc7tXVWd8OJOLzum312S3U1gMpEdnOMcPShab8BB0aP2osaP19OLsw0wNG2IFF_8FWB-b_c6Xrtu1H03JUwSR6ZGInlVOZg59ORGxDBgKpm1e9sLei6dDJvKJMJU6am2rc-VyOfzgZ8nEG2ayeJiJfhyHkJOp7r_DsJXvrJ01fin5zvtotw",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG2m3kNOqYpzkXmI_jgCL3_1muhtoUneQFV9J3ZZi_7UOEw-m8Hbx4v_BSsBz0aHw0mO9p4lLNgUFSuwEr9aPCGpxnaNqEAG50=s1600-w736",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113482343908983147932\">Randolfo Santos</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.942274Z",
                                    "updated_at": "2024-06-21T12:00:56.942286Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "9d7ca694-104a-41ea-8729-6998ed46408c",
                                    "google_photo_id": "AUc7tXXKC-jgyoAuTZBVP0kIYkw7HHOxcxqmucDHVLgc1crxE6ETcFIvXQ_FjArqBAMxC38McWEk0Z4BxGNr72LImORSCNUJVBl6zzfWQwaK7rAm3wJWisMQ7vzzczsX8wqU3txWy5WeyzHzyodGzZ3lDLw0uGPh-Dc3G_I8VqJ8moepekLY",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHUC835qyx3-ALSIvD2RsfX1T8bi5NftytK86A3ZHydRn11Fqxh03oz5ECS-u9PX-IhiIFUAR4OpiA04zBHXdCMYaSzJqTbYkw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110035741806686125626\">The Girl Who Flies</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.678987Z",
                                    "updated_at": "2024-06-21T12:00:57.679030Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "20664347-b252-4885-b67a-b8a6d03e878a",
                                    "google_photo_id": "AUc7tXWQOFB448rVbN-3LQH0Ri2KA-3KFmIv5EBScXkxvlp83mHIMfJlBoCStvzQnpwV_mCLFw3SVE5V9c6hkx-rF2jnab3dHyrNE1t5wUlUnNq50j8Yr-qF58l2Rp_bQSlo_weiuTDTjCLgrH-vX7Fz5bKV72Q-qgF9iXBFgfICN1vunZSG",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGfBvQyvi_qs-umW8kzegM6oktVlsUkml512t0Kgvva6tFgRDF-TSJHoajPe92mT6iNUlPwlO5tPh34S5zYKLxsuWZIPCyohjI=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/111306359296328700061\">Winston Lee</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.527895Z",
                                    "updated_at": "2024-06-21T12:00:58.527906Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "2948ffba-fdb4-40da-b093-4287e77a303c",
                                    "google_photo_id": "AUc7tXWCFplgvWMS84bZTdp08_bv-5JMaDmHg0F10XveZKycxiCqim5_PYhcaNexfs4ZyhTyOSz_vx_UU4UbYs6jvUTiqS5qBDM1hb1l9XGK19wez6M98zyJPWgt7_TZc9KWdRf-qG7H5LfMERa-00npcl7ox5qBLeyVznyT0rtUBq0rA9Z3",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHVBwcypaDxvy-eNHbBylxcBC3jrZm-Gwp6w1WZ2rb4QtbUabfF24A8bmsz0ZnBY0Eu87itUVmr-HvWwIJwCRK2nCgfXmOBp9s=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114836440654289524479\">Migo. C</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.463866Z",
                                    "updated_at": "2024-06-21T12:00:59.463911Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "06de854b-58a2-4bc5-811a-984a81aea167",
                                    "google_photo_id": "AUc7tXXMPLTvb_v9_n47NQ-TtpEkxK5VGAvziZoJ7CfaWoq4GVaQnCNv_3BTGib6B-X9ADsut0MfZ3wnVYe1w0lFcQbIWinO9wo1FNtvLpDpfGcH0vQ0axqaki_Jj6H0-gPt9X6ggfo14WodJR7fqD3rgidgIrHOOqGZhoy-PckQuaVCSBR6",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGXGuNouPqR_mRQhq0AfNKgj-ywIsPIxnk5o66xdtj-tjJHFuyFJEJErYwCIfh9Gp5wxG-h5Ie_qmxaBjUeyTn_BrftxtkGnyo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117280566143560612924\">arpan panchal</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.571008Z",
                                    "updated_at": "2024-06-21T12:01:00.571022Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "5483264c-246e-4cc8-bc07-035aa6a92ee7",
                                    "google_photo_id": "AUc7tXVXwOzI-H1ESkZzQlBGFhmlL3FM1Iof_Re1o4LW2uet-q3lHk7re3vlN26iw88WEW-pDcQFSFMLA-IdIGU9Ogurvu-F-_k26FBW6syX6CrMtSyqJIu8_cPummpMOiCGtPiGopAE0dIT4pts6iI1AyChSnoVxX-hdDF_H4drTU2BlSEN",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH9u1mDXgd0rwkbJEsqnq8PY7kTPIzb5pQc-eAZ9XbMi__47OiYjH6BU5IyWWKrsFVxv5weDZrOEfesy04fUCNFXp5gHcLf-eU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/103967285565048036172\">Leisurewithtees</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.709756Z",
                                    "updated_at": "2024-06-21T12:01:01.709796Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "f7056816-1cfb-4518-a1f1-148267ec4627",
                                    "google_photo_id": "AUc7tXUJpxOt1607ApmfeRFQNtZf-qUK3wDemod-V98Xvi0Y9GTi9o0j7OzSuYeBI8v-h8wCT9-hjuCG3wm333AwvDloKy9z-ix7K7TVa0mYBFAeefWBtYvJHpATQlpiqqSwu2opHWUX3auDb6FkpaC847n1szT4M08mXYtFQyYtatanmKqB",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGZF43bpRXqjCO5_GVpZpgB9bjKYZYWxgjLJRfBtP4-yxyebY0jTqA7wjWUzF-5GeuncOcHOnEpiFopv5_H4IK705nEpMG4thU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115966924984810708712\">Gaius Aurelius Valerius Diocletianus</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.800349Z",
                                    "updated_at": "2024-06-21T12:01:02.800362Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Historical"
                                }
                            ],
                            "meta": {
                                "website": "https://www.rbg.vic.gov.au/",
                                "address": "Melbourne VIC 3004, Australia",
                                "phone": "(03) 9252 2300",
                                "latitude": -37.8302443,
                                "longitude": 144.9801496,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 0,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 1,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 1,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 2,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 2,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 3,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 3,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 4,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 4,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 5,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 5,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 6,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 6,
                                                "time": "1730"
                                            }
                                        }
                                    ],
                                    "open_now": false,
                                    "weekday_text": [
                                        "Monday: 7:30 AM – 5:30 PM",
                                        "Tuesday: 7:30 AM – 5:30 PM",
                                        "Wednesday: 7:30 AM – 5:30 PM",
                                        "Thursday: 7:30 AM – 5:30 PM",
                                        "Friday: 7:30 AM – 5:30 PM",
                                        "Saturday: 7:30 AM – 5:30 PM",
                                        "Sunday: 7:30 AM – 5:30 PM"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Royal Botanic Gardens Victoria"
                            },
                            "min_time_spend": "2.00",
                            "max_time_spend": "4.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:02.831005Z",
                        "updated_at": "2024-06-21T12:01:02.831016Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "5cf5c453-2c28-4219-a53a-3966b8583e88",
                        "rating": null,
                        "activity": {
                            "id": "c31a3e9f-7d47-4abb-aa2a-97c64925a5ad",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "The Great Ocean Road",
                            "description": "As one of the world's most scenic coastal drives, The Great Ocean Road offers an experience of a lifetime with panoramic views of the Southern Ocean, Otway Ranges' rainforests, towering limestone cliffs, and beachside villages. This Australian National Heritage stretches over 243 km and is the world’s largest war memorial, dedicated to soldiers killed during World War I.",
                            "website": null,
                            "images": [
                                {
                                    "id": "f2a26f7d-bad8-4f16-a147-281a72a82c45",
                                    "google_photo_id": "AUc7tXWYpH56qB9DKaxLqKxqPgRFbPBHv6UPM6kYtl-mTpNyov1UPe8PsD0AjjlETg8Z_Cx-SOiOO_Ax7aqTSqdaV0EvSGpKc2O40iZVPIHOSlA3ncrzv6Um5r6pT78ac2EdrGPW5KnOJ1_57M42MN3eIvBsuoq7uuRPE6hvV0TwJipaIil8",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHCxxmBJRen3X2p-CH_XAb0NcNwlb2YB6_EgyIYlUgK4tSv4TOQhv_y2ft43MGB0J8Dig92VDsVAju-b7z2Cn0Du8UcLv8GFK8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.758920Z",
                                    "updated_at": "2024-06-21T12:00:54.758932Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "a8cc525b-6582-4c06-ada6-fe270b04e4cf",
                                    "google_photo_id": "AUc7tXXKSbsULCMucojfMqN6niAHVILgzPPmdm7sZAfkNjANdUAVYKVLEuHtEeQw51kQv7ULjdoTm4YLxHDf4zyGxy771i_KWnt_a8ACRAMjCmwMkSR9zeAWZXhDSyYBGnHk1LpvLdGWPjsRDnkHMVP6lCFwtgaCBDVA-xLwdrkNxDs9KsMz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGxcHja58HcmrmtfTAtO8Mt0z8_nwNMVXwsGrRAwRsAMprkqXGioW_0-gv85mRIokXRpLfghpAtok-NCM-k46DLwDRpai6xm0w=s1600-w612",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117233789177808345940\">AKSHAT RAJ</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.552757Z",
                                    "updated_at": "2024-06-21T12:00:55.552806Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "a67d9ed9-b34f-402b-87d5-744cb196a012",
                                    "google_photo_id": "AUc7tXUrX4uUK1laGuLeqwVIFpkZmC_XekJw82woCspWE0ffrhIFwcvx-rotwYT4sMKl4RCv0LFXRs1mXFnvsaeDphwG2tw3LwIa0ntJGjYDRO3NonqVX3svW08UuHBNCZ00fDBgs_CqzuZiITSQmYDUiIkw8i00b0qD3xpH2m5ruElU6xhg",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHiv8Rjv5YRVlsPz6HvAzBygxmoZgi0rg8U4bOTDqYqQc85XjbUQoDJkdSdhxmFyQ-pCDxomz3VST8LLUA6j4T7bwYDgz80dmw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.407608Z",
                                    "updated_at": "2024-06-21T12:00:56.407619Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "1f3601f0-8af3-4394-bbdd-ca366d40644b",
                                    "google_photo_id": "AUc7tXUi9QtYqocr7h23ya6mgTGX0F6T22ps9ns2105r_nEYBEdAIKw0EkJMCh2tlzFbqc1xb8kcYsdfBff8vYheiFwwv9CUQFKxa59jg9wy6w9mIlHXsqLvfnkbO_gYTYK5Jh_jCig6G5YQiZJcgEs-eBhdaDJIklDZps3LhjFOPKn63U6T",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFtZw_rhfx0NuN6Hk3e92rIE1XV5PiXLbw7uJ83bjkmrYZ6aXLl8yZR9zTCjGthu2fHoWhLHimIXY7-Hg_NkHaWiL2MNNOC2Bo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113142087721512063500\">Philipp Mauerhofer</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.332220Z",
                                    "updated_at": "2024-06-21T12:00:57.332283Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "4456361f-a172-4629-83a8-5dfb1e0c4484",
                                    "google_photo_id": "AUc7tXVuDaNKBOs_kM9Iy3JabEyKZ62MV-njvd8igS9Lp1HYT-KG_OddD_SRNLf5pY2Ekfw3ROxKHtaYGPBzWjILJdYIMLazsBIwvNziIM_3x2pbLC6ITJMtAwW2BVDCR9gFN5Mr0WyeyxvmCJhGYtKkOpYP74G6dpL6jfDIHb4Ne8E5tnoC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHiF0_4pNKZGM92Kwf23yOGV01GKkOjux-X3jG4Ggkd6YVHbHSiJKcLgWVs_BbazjhbCVlf1Hyvl659p3KP1XqfOm0NGDJ5ckg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117992426843348195394\">Abid Ali</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.271438Z",
                                    "updated_at": "2024-06-21T12:00:58.271485Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "ff4cf7f1-5a5c-4e25-a725-e23de9258d8e",
                                    "google_photo_id": "AUc7tXVotsq1cUj9SVPB2f3HLR2ZpKVvmItbjOitttucyIrkwk-1xZRTZsZ00LayydG68oJ67dNceNScJpO0MgU7k4ui7I2GQVIdX4NZxWAqugw05Q5_xXifMwDqAgrbIW-FpuZxpNMY8TU7Aj6BplE2-bV_fhzjlEG8GbFyoYhAY_xR5FMT",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEg9ShKxTdk7LQMDqlwAq4a6Y-li_-J5rqTkrMw2diZnpMEcOWRQ4RiAP4WgwBjEdZ48BFVJsuDCsqHKcsCA09D8u5f-fK_7n4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/107520450214732015131\">Sandra Giebeler</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.146565Z",
                                    "updated_at": "2024-06-21T12:00:59.146620Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "8b475719-c8f0-41ae-b4ca-a26f62e9a55a",
                                    "google_photo_id": "AUc7tXXg6K9xkynE-t6X18lYM8wwB0gIlvqKU7jF0ECCgnEGmM4QMxWqOvWD9XfWPKqxT7CGPyn25mimQ6ZjYHDYYyYwGMepqSXrtkFiwr2o0rVJJAJQvfbmR8UUr0IwoC8ehVMiTCKTXMgcpPUi0FfW2dYm2KbPLsyBa7xPrS_Co9IB3F14",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF5Yn-jsgNHqkB3M6yo6mzRiAey_UNxCofli42paP2mkShqYpkIP0kXH8Sfra_PUCOpAaUOjn_F2ICU65mcTPW3iOGtsRWKCLg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114625849255864946925\">Roger- Philipp Rickli</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.315285Z",
                                    "updated_at": "2024-06-21T12:01:00.315347Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "ee67f883-1018-42e3-931d-f20aa92b357e",
                                    "google_photo_id": "AUc7tXU0vKScl8ZdynNW4JHIHypZNmjmt4Ujdms1jMa2C8EZ3zV7tBP2njANPWIDbR0gxZZVxPBD-coa5FRe7gmUrPZl_DJa4qEwR_yYXSKt0pgqd_i45RO14Z3M16cbg3JItdgx2soOgD2RE1qQrxzJHlpfdd2h8pEVN_Zg4xCG4OGXU68M",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHYImg1riQW6rXJJmBP-_EeJ13G9GCxXvIWd1o4R2FGuxqpGljGJ9rxj-03TUKDW8gT7ybsujkFwSCUFT-qkGR5xGVwxqeMgsQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.243356Z",
                                    "updated_at": "2024-06-21T12:01:01.243384Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "af1db06d-0d35-4513-8a48-6781106e4199",
                                    "google_photo_id": "AUc7tXWoVig2uM_Ujz41EGozVrwyaISDmALAQetkgYBRutYdiTqbYsrF__QeqM8jdP7NtFAZch6Offk-6QjIjZFGThnJpOo0EpZru6sJJg8cm6t6fd_vNZjRjaTj360daeToBy5KJZ4mA7PqVtNLAwEo42-NSxzuAXBSR-sv5ggTFRgSJaH4",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEMBQ4ZwvCX_dvzmF9VvpT_Twp0cMwmirrMbpvCcieWk8-uVutmgwCTps5oEXogWyC_JTQG0qw0IAUE0uSvtinwFiTgklkumlM=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/105913246834688042259\">Jason Buhaguar</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.097837Z",
                                    "updated_at": "2024-06-21T12:01:02.097891Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "f9a9bc36-36c5-48ef-af86-ea8e1f7a6ac2",
                                    "google_photo_id": "AUc7tXUsBM52YZF_5nTZErFIrc-x2J7hDA3IALS2zvtaqh2tJ_TkjaWfd1EBjli_3K3GAU2CmwcRg0aD3fpB9m1ZPtUtYJniYUneYZrZCfT2S4T5M7uf-mGl7b5MILr3rO1G682LcTxuNPrNQ-68qRBML1Sjg3_T3Aerq8GDvNaXqZk1Mn_P",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHMFSoiBl9hpp_nbffZoZwG_w0trgrDWUx0Wgvo-Ofg9Nf5FpeUVFWuMLA1J1HEOZBqfCInQp70tprpsDK_lm2oPZz3JqYUbcY=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:03.099989Z",
                                    "updated_at": "2024-06-21T12:01:03.100037Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Picturesque"
                                },
                                {
                                    "name": "Historical"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "Great Ocean Rd, Victoria, Australia",
                                "phone": null,
                                "latitude": -38.6804339,
                                "longitude": 143.3915596,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - The Great Ocean Road"
                            },
                            "min_time_spend": "9.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:03.122032Z",
                        "updated_at": "2024-06-21T12:01:03.122070Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "f57e1c8d-229d-4394-b915-d6bf71f2cb4d",
                        "rating": null,
                        "activity": {
                            "id": "d7412cad-39e4-429d-8cbd-0d31ec7cb60d",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Melbourne Zoo",
                            "description": "Home to more than 320 animal species from Australia and around the world, Melbourne Zoo provides a great day of outdoor fun. You can see everything from elephants and lions to kangaroos and koalas, all in beautifully landscaped settings.",
                            "website": null,
                            "images": [
                                {
                                    "id": "57841744-1f7a-41bb-8589-0a2b2ba43fa3",
                                    "google_photo_id": "AUc7tXWGkff9nrrH6jpFg40F7hL5mNnX0Y9NBdOivUo6kI-0YGwztBfKEn8Nt84HeREOd9FBnc2X3YGgNmmKPoIdjtK8mtWBf-xDmYd5L_NabUXhX1Q0kwDcgQnvrCndf86e0t-GcEvJXWGf7O807lGjidhxcCO4qxOYdQ74kdw2sZ712y8z",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGabXjBEROCgh5eMZmD77fWB4etcw6rajneAWtNa0GgUmurYy-Su_sQ5PAKusFO2WToLOwdnfC5xwwgydxY3EfZUPc6ngkB1b4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112229653183258276103\">Epic Walrus</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.292145Z",
                                    "updated_at": "2024-06-21T12:00:55.292161Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "4fc772a9-9df3-472d-a77a-25c670e9e0bb",
                                    "google_photo_id": "AUc7tXWD5Z0a_awkDA3PwF5iBd_8FwLgVXPDzaeaUQUlymlkvO1pWPAPyHgg3uKOW1RrJqlWl0dCJLOT8HK9jrQY7k1cZMEh-pWICrCsnzssv5IzcZXzi0zG3guIHWoRbtctYb1fZ8-RRPETuHya03qQbXEm7ovN-9csZIa0DzjCN7MFkHXm",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGjERkILD4Lgn8DmSa2C8T2nbWwENhNaV9xyXMMENeCwxpA7tbjH_jgeq7XTrZndu83jzN0KSfX4pdPp89-V_sHf3qTWOSo42U=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102087088896800693616\">Deborah Woodberry</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.292573Z",
                                    "updated_at": "2024-06-21T12:00:56.292586Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "36934364-54e7-4e47-8a5b-7fcb74c16b04",
                                    "google_photo_id": "AUc7tXWi1mKSomBYM1XyffT55HWLwnR4PNMHUnsmOT4eGK4AaR3ouEfypUsQmmNJH0HhSiKNzR_38H7t9OIAqwbANvfsBCa6S14xQAQZT-kdTVjouIg3kiPxDrvxV0srz6L3sT_YeQuTI9REWclQ1FAD5kbgwi4Z7m3_ZxC4uwgfHrB2tAYN",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEuy6f6x6pdRtUY1lb9Tr6uLJsbPF6GOitVhXBGFjV0F-nJaeWCBzdp3moPYzAf7kdq_iGH6frNbDhM-EILgbIgjzyLLlaWFew=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102089377006409655709\">Edward Wrightson</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.050491Z",
                                    "updated_at": "2024-06-21T12:00:57.050521Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "4746e87f-6f50-497e-8d95-b0167c2fa18e",
                                    "google_photo_id": "AUc7tXVbtjgYDuUByQ0PIbaklbWVYfTScn0-2B7OBbncMzG-Q5veJtTU9m2m6JjtxheIDRvOfr2s0uhY0O6poaV46Nr9GPNmu7vaPFupD59zBOXM8wFQ9QlyN7_-Qj4O3qWTTq0vkr2mcfq_NOsnRBRzU_h_DONUEz4OqbLTrmwZbyVUn2E1",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHCmIN6PJFFamWRznzYz5dq0JcEue_kZz4Qb9d9bNRkxOoCyhgjPHaRCSMzLaMht_3geYh3kZTrL2r4AXmNqf8q_dbCf0h1gjo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113182075871475519618\">Amelia Macca</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.199239Z",
                                    "updated_at": "2024-06-21T12:00:58.199287Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "a27b10cd-ec2d-4c75-8c8a-b9dfa51eea38",
                                    "google_photo_id": "AUc7tXVNLL72zdUNQr8uTsYD_XMCsNto4v-oUjcA3tABMKTjWvgoF1fLZGR-9akJ8OOTblpMUJUKDebNMR50RcxMjHjw4ux0J4IH4VnKJERNngNiaUViUNhR2kTLjQD9vFswtXcq4XQvpN0A5cv-aV0OKYfYdQlj8NwmP055iPSlN6DP1ACa",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGds2PdUMO2z3_Ijs_QlEhPb7-7wB_-TXpvVIpSufGu-fpKWAeOgq-q1L5HDra-MB3AZqDSVr1v7A1uVecvsYiZ2KeqUq11enA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115266825001406401620\">Huyen Duong</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.180839Z",
                                    "updated_at": "2024-06-21T12:00:59.180888Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "b4866b9e-6c01-4f8c-b862-e54a1e07dc52",
                                    "google_photo_id": "AUc7tXXnSQR4jY4qmWzU5r5vdW1KPrZO8yHoENFzerb2QrRbBIQgi31d1mrY9nK9xPPwYN9TNLLak3beV93BlxehwUqssZ5xhkfypbjtihg2QcQDu3c-GndD7mVf69AzoGIfdSpmZTTx7uaw1nQWy_pU5XrvvsNgsD_INb2BoIf3sKMCfGT3",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHhvOWhPNPVytPAHo0fUJalXHKQzgM9f3w90T2m7adamwKdCuR4equakyFPtAbM1NoJBJYzYtaBSJSBSx8DKYmb0h4rn7ZgMBQ=s1600-w650",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100682561788400770828\">Krouse Dorrisag</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.806873Z",
                                    "updated_at": "2024-06-21T12:00:59.806895Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "3966b895-498a-43dd-92f7-334cee4060b6",
                                    "google_photo_id": "AUc7tXXR5JHmDwvWmE9N9NlHCMWU3lY33fJ-roHTggoQ39JQESqhrX4tujA5Hn1Uz3Q4tBtBigMt73v4ydt78tID_XKDMPRWNPcra1FDV43O7wT-c7umXEp40HfNqdBzYdF6ZVaolFZClxoalZ9DHm23zHW6JXrWMfZF89O6vWcu84atQjZ0",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHELtQ8KtEO-hZKhoIg1yRbqiY246Qt2WgNTOUJOICp36Vq5vdh4yK9Tgf0pXlBPd1gM8hCOVbQcFJtI-GzPCL-PZlku9uYQ-U=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/103440315744658822029\">Sarah Archibald</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.559637Z",
                                    "updated_at": "2024-06-21T12:01:00.559728Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "935bcef8-d769-4000-b328-c682e5512c15",
                                    "google_photo_id": "AUc7tXXuJ2fPPGPq2Q1fFhDkz2Z5jl9iVbTxQn7vm9kYL_nIR92f_871OtEfyZtee7irlZmf61qcvu5ei9VDB5fUcbAY47b50O0noIX2Tdue2Ek_fCNbwUR21I8stfJSPxAQx_-me0WxcC1lqpfF7M7yr4PmP_1ZeOh9IZ1RibY7jyOfl0nL",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEoqoDpDocEUU9mhBI88kRDv1320lD-xvoMlPFkNsibGR0bPUdn_2gMZwE9gur-kFqZNJ8VD8dDTIc7lpjcjMBsqO1DLmZL1RI=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113084928441512030101\">Kerbray</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.960924Z",
                                    "updated_at": "2024-06-21T12:01:01.960949Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "d09e5ee3-cb34-4824-9c9b-e82adc533144",
                                    "google_photo_id": "AUc7tXUr06ak7n8j0QblhQnUzPQ70EDJu0wXHTek-GOOQglwJZKBerR-BgiBUUZAyw1RY1CnNtw0K5TXHvZgXDx2dhBDJ1LDqrfKj-nX5c7DdkOQw3SiFwvT-Y3_hMSWldyDB8j9relgs9GIs1C0BaV58TkdQ2SF1KGhcaHdOZ1CbeD5mwYS",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGOD1-9P7Cb0xUSgrcH_iJBJosGzHu_VK2wYvDLwKmaorTDbVmcYrsc5bX2C3hjWIYo41FRSWvjVz9VMChVXg_hakq2nXLFFro=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/104143051584790495708\">Andrew Thompson</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.930902Z",
                                    "updated_at": "2024-06-21T12:01:02.930918Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "fd1c71fe-b93f-44c9-a482-b28c78af2bc3",
                                    "google_photo_id": "AUc7tXWLy8ZH7SF7pQmkgoFAMwHFeQNs03SxxxzfCyzxcEQHnW_vkCOoap8bQtWkljgLg9R34xu-6PS2j7uZUHjSrX6nZI0mVboH6A2DZZAyaSmZnadxVgTwXFqmDmprjmaKDjQZuCi4pX2VvGwp7dwbY_2X1xZJuA5QCwdHs3EpORc_DSpi",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGhSwXaTrO3pgTXJDmyMA1wWEDZXhGNS-90Q9YnEEZx4ZZeSrYm4_ihVM5WJ9jpW_JpWq7y8Tz7jeJ0wgc95DK2T2jjnsxcPZU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/111392597415527891953\">Binh Tran</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:03.736221Z",
                                    "updated_at": "2024-06-21T12:01:03.736270Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Adventure"
                                },
                                {
                                    "name": "Educational"
                                }
                            ],
                            "meta": {
                                "website": "https://www.zoo.org.au/melbourne",
                                "address": "Elliott Ave, Parkville VIC 3052, Australia",
                                "phone": "1300 966 784",
                                "latitude": -37.7841346,
                                "longitude": 144.9515473,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 0,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 1,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 1,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 2,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 2,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 3,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 3,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 4,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 4,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 5,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 5,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 6,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 6,
                                                "time": "1700"
                                            }
                                        }
                                    ],
                                    "open_now": false,
                                    "weekday_text": [
                                        "Monday: 9:00 AM – 5:00 PM",
                                        "Tuesday: 9:00 AM – 5:00 PM",
                                        "Wednesday: 9:00 AM – 5:00 PM",
                                        "Thursday: 9:00 AM – 5:00 PM",
                                        "Friday: 9:00 AM – 5:00 PM",
                                        "Saturday: 9:00 AM – 5:00 PM",
                                        "Sunday: 9:00 AM – 5:00 PM"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Melbourne Zoo"
                            },
                            "min_time_spend": "3.00",
                            "max_time_spend": "5.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:03.784658Z",
                        "updated_at": "2024-06-21T12:01:03.785362Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "f10c8193-22bc-4bcb-9488-852ff3a143f3",
                        "rating": null,
                        "activity": {
                            "id": "56b6b676-79cc-4ff7-8dc0-eefc71022eea",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "St. Kilda Beach",
                            "description": "Known for its coastline, St. Kilda Beach is a great place to relax, watch the sunset, and go for a swim. With its long stretch of sand, it also offers a wide range of outdoor activities like windsurfing, sailing, rollerblading and kite flying, making it the perfect destination for anyone who loves the outdoors.",
                            "website": null,
                            "images": [
                                {
                                    "id": "98f84833-372c-479b-9137-e602ce28338e",
                                    "google_photo_id": "AUc7tXW5PyEXJMQosnzNVnd3hNJ0QLNf-U66T-SXSTSQBY3rdDUfqPoXflQqqjp1rEKEgidin5r4mz2a3vsnWgnfRjaM_-fEMLrGh6JxkXKvI-qZRERSJm-Cd8YMcDLEFwQDVQ0-R69rZqeWdAp6G6oCklxt6gTcIAF5UhZ3TC2TaWrJEymb",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGtyYZwtEuYVleYpgzV2lNszEbr84pPBKxlo4zqubq5kyKxD6_XenLb6hAjmd08r2wI1fZdKhRHc_dT_FFONQcX50r5GLmF_1c=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115853956143232834796\">TW Picachu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.281665Z",
                                    "updated_at": "2024-06-21T12:00:54.281675Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "7890c01e-0a15-4527-9e7e-09d942fcfcaa",
                                    "google_photo_id": "AUc7tXXs7rP_GB-vlCwsICVCioCBc0HyNJBoEx4cega_GB3sFDZC9HNQPNjuU3M_eb4y7avRw8M_hVqNifr8tBGpJom6OQ-SwYB5poUmiYZDI8QhBpVdu3OMH_jceCaQvLRc0nSqjrC5Y-mYrATII2n9kiusryA3aniWe6bOsoGXaUUy2FNQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG_srBVp-P4pjQFv9Y-X6C7Sjt44zN6wzFOFtvt4xUmZ8kP7HG_xADUKEOrjs9oJ9ry0VWtJm_gvyK21IeDkl67q8qnJys_Xpw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114269478183296619728\">Raghu Bobby</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.237244Z",
                                    "updated_at": "2024-06-21T12:00:55.237258Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "0d999110-e3e5-496f-aaeb-d7d8182fe6b2",
                                    "google_photo_id": "AUc7tXUOz769-mqa_T0uMBp5joOKCPciVRJZ2noI1VNjUfYlvMSXITGt7v_-8J6rnmiyb-M8lGFLdwwnFRjRcm3ta8JbQ4W-w_egZ_z8yP75SvT_louPa4obaY-ijgCkkdagFL1fIIRxGgXzPdTRzixxmKZvqUV7-co9XLhPm11_xB1cWSTQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEz9mFAoXzAZoA9BwDDHLrCAPwgpdZL9tlKvbNLIpE5va1qOlOJYz3E9jZHSiCMLKFa2cEtz5WsKAfgoZ92KgK6xY-LJHXq92g=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115433816130106971976\">Arysanti</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.621937Z",
                                    "updated_at": "2024-06-21T12:00:57.621964Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "1048e46a-3922-4e2a-a8bb-f3aba2172a8e",
                                    "google_photo_id": "AUc7tXWUR6QDf1qIEG2LByBTZWaGSLYu8F5WXEcT9UM-2QfsyqmjpOs63iTLZgw0Y6gL9_td7snbXDIrZmJPLPZigTJWBirz8qDwpxiwtBMEAxwZNJ4LAC8QzK2Dk1V3PFOz92erncBRmeDWTYeFJkjCcFUb0W8Itv9GHveecdgNYcRwZAQr",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH7MD_CA3OjyIPW0WBEcOYofpk5WoeACFc5Ffje9tR_HHiLyhsoTe7eq4kaHRXUaNSm2YQvhbnW9tUql3GYsnoaE3dIIzpQDys=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/116120903960213642188\">Vaman Gungaram</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.653878Z",
                                    "updated_at": "2024-06-21T12:00:58.653895Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "47d9d17f-9178-46ad-8296-587344285b40",
                                    "google_photo_id": "AUc7tXWipoT32gOZGqAOotsGs1o9E6c9AGIcJCTx2z8uxfEOM1P5SN3kgsSjwA5QasPX7dinDHBSL6FPzLyIopX92OFW_h2z3f33pSiVhWyUEu-8DrIFHj8dlhd2jw851jiwcvciwrYRhrdJpQPGoo9rmNNj6t4bg0qkGCJE59MGJz8Wpz0S",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGcerFUNByYcmL5CJJ_wV27dhSmtCEORHTBmlNI4bUIRGP1vXZxrhOeKNDcxPXVcGMannFH4LZhaQNPaS9XcmgY4_CJRj6_Mw0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117245483495021826249\">Azman Ansari</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.614132Z",
                                    "updated_at": "2024-06-21T12:00:59.614168Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "03f15371-9c7b-45eb-ae4f-e7eeb81772c7",
                                    "google_photo_id": "AUc7tXWf4Dp4jV4gc1wR-7cUrYRQIZYnnqAjohQsCuiZtUhnwa4BPc9zPkpAYaVdrrTDCxETO1qeUMCdDHNHoeHPi0g_rwWLbCbe6lYIDTqNX0AQ6ouy2eVEdrLnn32wwTHTqRfdYYJIkPJJaLfekNfb4KytnHTb_T0RZAoz20TWXkpVkESs",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEIqmpIBqbIALko9fluf2ixk-9BPjBFR7UmzQjMqyUuurdPqg0HDe5iP_-MQ6JgQO9LsWbNGF3tkm4LnNQx-COg1Kl2815AP9I=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/118258442269396034066\">Khánh Tùng</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.432161Z",
                                    "updated_at": "2024-06-21T12:01:00.432225Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "f8cd30f2-3e34-41fa-be7c-511f62fced00",
                                    "google_photo_id": "AUc7tXVzQSRkEpa-x35_PUUooyDTPagZNSS2qxKetT-2rmsxHDtz11zhdnn10DQEukCY1XKa5ADhW5SRcNv3_uqwCg92_17P72aPFz6AJfBEqsvb74O99LG49qZK_uMwnaeCEfUGq7Yd8JbWh1T9ZXpt0UKx31bEMxNOwt3aAio214Uulx2t",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFM1tZO0HdvxR0DdCCm6ibbMH3lF9R0Gz00qfKKMLevUTU5_xy0T0nA0SSodvhjjSAjvCi4ESTAfW08vXnZSvgVzgFvyzxbGzU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106036189036000429674\">javr fotos</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.584442Z",
                                    "updated_at": "2024-06-21T12:01:01.584503Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "69a13d22-d988-45d9-aacc-1588809dd5c5",
                                    "google_photo_id": "AUc7tXUVqG-ichkk2Gn5_qPsoD2pV1MZyKda8gbyq96_bAGA0-MgFc_IPORSerESqhFr_BeGEOkbvhHqeTU-a4uT95q59kNwm7fpsEnsYWRS8I_GhTZgXsZ1_RAJZk4NpY9W-lduWWSTBIZ7s8s5nUUDqnOHANBLDWHlqR20XFb_IrBho-_N",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHGr-BtxEeOXESI8d1gDZhX52IC-XmWNhzyxBw-vnJ9x3c4MZ9AvrRMeh1P4DNjpNqYjAt_zGHe-rGyi2QptfzqRofy_OGSbVQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115433816130106971976\">Arysanti</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.546649Z",
                                    "updated_at": "2024-06-21T12:01:02.546706Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "7c05f797-f696-4d63-bc67-65fa7accb85e",
                                    "google_photo_id": "AUc7tXVP8_Y-aZyYnBJdCeJxTnDPaZ4ujNZUvv79uFy5rwWHKos8Ebhy21DVzRB1OK-C0y2AtP3a2GmW651b3Kv9MaghOZpBanNY5hDVwYg4LidwH1d9H94ChgvOiSdIM6uSbq-EwmyfEaO8QSf62nE7eFyUASejMgwTP3DUMm225KFwCcJe",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGbFKnC9NT2jXq3I9OJ7lAxDGhW8Ow8SLpFmptGCXMOFlzfZU3DTbxQGxGdtMctpMZl4bVMmlTN734QvmMThoFQTKrJW9-ylq0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110783594486394383401\">aman saluja</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:03.373940Z",
                                    "updated_at": "2024-06-21T12:01:03.373985Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "1b255314-4bd6-46d6-a834-14814b2f69b2",
                                    "google_photo_id": "AUc7tXW5s0bbYoT-t7KGbm6pAmvxfjONm81nNS5kRHMt6zA_nfsV6v30pp6lgyoWlkHgysU1ezPMdMcjaQZeKxj7TZvA6ivoOOpqEVFsqoNWhvyiMiXobvvgOqrF1ZsRkIta6aetMzJAaMxNCSlwUwuwt_LxywKVuG01WbHqAUmlAgvYd1OA",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHur1sltOZAj0xkbkQomKI-caE7OY4BDDUppUKIC-g45IU0NbsPv8J-MvCKXAkYNg4ga00WLS604Q-GmoJyHDFJ9MOcPvnXajc=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110723671093101482452\">TINA</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:04.293998Z",
                                    "updated_at": "2024-06-21T12:01:04.294052Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Relaxation"
                                },
                                {
                                    "name": "Adventure"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "St Kilda beach, Victoria, Australia",
                                "phone": null,
                                "latitude": -37.8678765,
                                "longitude": 144.9740049,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - St. Kilda Beach"
                            },
                            "min_time_spend": "2.00",
                            "max_time_spend": "5.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:04.330558Z",
                        "updated_at": "2024-06-21T12:01:04.330606Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "d478b1f0-ec78-468c-8462-0ec23f3a335b",
                        "rating": null,
                        "activity": {
                            "id": "0f0906c7-0360-4e80-9589-1be05ec3a5f7",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "The Dandenong Ranges",
                            "description": "A set of low mountain ranges, The Dandenong Ranges offers a mix of pretty villages, rolling hills, and natural scenery. You can enjoy a plethora of outdoor activities such as hiking, cycling, or simply relaxing in the lush landscape.",
                            "website": null,
                            "images": [
                                {
                                    "id": "d3ca2bde-be4c-427e-9a5a-7dc435221b1a",
                                    "google_photo_id": "AUc7tXXJqhxYVKuFBuVPBjlcaHT-mLWEkfBgYboLUXbDnFoGS0U8JjGuqW2OlHNurFKQWzYch-SFJ4pjx7qBLVB2c52V-N_VcQ8JEWg9OJaTH-7kvAYISJ7Edc5chXzkH8L5t74ikHBXJLYH80I-3fxXalkIkt9I1yM12dOxiISUhSogyyju",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEyaB3lt1ZELhTbwln681u4TrtnBaOInjYwnu0heDcptHhdAMiR_TWnky71OFuPf8TB_BzQLhbNK_xvDeGIYve663TukNy2-to=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.235365Z",
                                    "updated_at": "2024-06-21T12:00:54.235371Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "648bf713-a2e7-47a4-baae-a91c070916bd",
                                    "google_photo_id": "AUc7tXU0OJOG0MWRuNqsZHpElqx0jacKo15VQZwuTDEvz9lAPVoG9P3fPBJK7CtUl_28HQ8og4ZntjEk5uBGg4ct4F_UDVS412MtynmJsBJHFRoo4ZjRMU_RReHe_tHg0nT4XWFhHGJ0Bh05936oD5HYe00MooA9DR0PLHHg3g330wAIXMJC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEABMc2B8f9h3f_3yyyvCyAsRqmtxwf0sOkt1s71vR1NUzJd33OOREeZmr11Cn2nYqkM_fJhccBBV_1rXa6ZpACZoAyLRYtj6w=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101033523556726757641\">Paul Mac</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.242637Z",
                                    "updated_at": "2024-06-21T12:00:56.242654Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "866e159f-4108-41bb-9ec8-cc46614c17e3",
                                    "google_photo_id": "AUc7tXU3viUHJ82-3iV9ylztSp2hwQ7PGxKP2tqWZBhvZks0JNvHArpBEL1dItEr_s1OYOeDLYTeu02NEgN7N_o53CH2eDBgscYvakSuEKU6IAoTVxpgC4Nidt2vb-SUoVnmJ-TxbhxqoAFqSiUprDGqW8W0lRBe8QSgeRyHB33Zsa3AtjFb",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGOcvZiWKiNqNXmp_rehAbrGdlh6XMeHnGnNU0qeL5q6RjTzI_JwuXbijwoWaR0LLQDfW4hCBU82YXqwiClCI2UiwcsrqtsQvs=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.103409Z",
                                    "updated_at": "2024-06-21T12:00:57.103453Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "2cc72df5-dde8-4f35-98b9-6651a359b25e",
                                    "google_photo_id": "AUc7tXUSGZoYwSmUlsTiaI4vBZawn2m2q3gzPJYH3h4Yzpmw_EvkzYCrWtV60koyS9BctuLrI8XZ8MSt52dWjka_2vd2E2uZ5jXmzz4oKCooO2-eiSsMll6PvSfcsTt0HJKCZoZeFuM4Pc_9pjcnjb8p_LiwXtmBMiy2ifGkFCDwfpHJek4G",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqERo-WQgo_NSXsrZbLIdu82Gl1j5PuUVhq0YT77MK4LsAWkBXvmTdpAWHqjG3e2oVw8P46Q8tSdoUOPRSvfCfHkRggAI047etU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115364935453021434381\">Aditya Sudarshan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.816790Z",
                                    "updated_at": "2024-06-21T12:00:58.816810Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "0d016452-b46a-4989-89f2-160bd4e4342c",
                                    "google_photo_id": "AUc7tXWzg1EMSsSK6alw1n-kAeoP6SbHvoeCh_-OhnS6fYetCeySeYxEJlfaX5IgMAEEgCqLbzWXZ9WXHH5CNHhGiKaPtkMpe7flVlyvGWDbimEOD3WWnMYrHZGSPsGhm8X3hjjtI-nWQfs0RyEI5ogZ2zg_9eA_V1RcbfmjkmUbuqJnvY5m",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGEDLQvOKRMKF8E11tAogZbYLydR6NEImq87gWCjAjFdd8pYRJPCnz122wXzePyPKdelqmmF2Jiwjc8ZX_S4I5JMBuzNsSE9hk=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115364935453021434381\">Aditya Sudarshan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.135780Z",
                                    "updated_at": "2024-06-21T12:01:00.135812Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "525ea220-6a9c-4a2a-b5ed-e216894ef7b8",
                                    "google_photo_id": "AUc7tXVrcreQM6g8twLPChbXSPF2sdWLcckzNBw5hStdG7JrBljLFXy6w3tffPiLk5X1XYO-UPViPgH6WjFY-phBt-ihNKc-_I3FhWmlwm9zzNYROeMwCc1kmCJCmoBwFJAMkhA-FwqLZNYGyH9IeVD6XNrvdalFQQYjEJ-0Ia3cnRmJy3nn",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHRo3bCZ5GFNKD_9AcAU7SYsiyzOQYLJeNJbDGdgPzWybFUKtuC2php8S_uI9YrlVqFrjNoagOEQfzsW0y1Gge5b5lkoHVE7ds=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101249622435067301550\">Neha Sandhu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.616103Z",
                                    "updated_at": "2024-06-21T12:01:01.616118Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "8f5859d1-4c0e-4527-8d51-902eec919287",
                                    "google_photo_id": "AUc7tXUJBrdQQLijJfpoVPefDy3xq6sntR8vI6bIfNmGtPMKNHtaUJKxwzomizwxEEhniZ2Rigz7PJXBBkEctpyvCBo6NYRR5z_hPJzY-YSpt45qbtqUK6s6ThCzayGSGUwcA4qElnitng4yCFfETo7D14fS893ZntbD-Yj9CfILjnqD0Ppe",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEokepFuwFPXfuDcF1fcBkw2oFBXO07VjL4ctxRsrx-_jeg-cI42p7gM7nqkpHukj94dHAX2WS1PP6NaCGTwbA0a49xrfC2I24=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117861488121494647937\">Geoff</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.939229Z",
                                    "updated_at": "2024-06-21T12:01:02.939238Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "ac13c88c-71fe-406a-bba6-ba2b115e9a20",
                                    "google_photo_id": "AUc7tXUhvho3VrAooHQWGsG7lbZzyrSwKnr6uClra5bFgwnBFIYIV8dk9j4tXeT0d0-OVKQrpDSVQct6OYRMmvoxMSIeEPXZJisq-PFdwACKD4CSTH3doYG2NCuIfhNQryHva4hxHYUJU8W4YxJKjuWXC7bI_FwU1H1qMPn7HCwUt4HCwwrs",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGifA3rwxceINtPg2AquFgmzaaVQouWkrN6QY9Rjukc2OaumMhLRV4FdFxgUYHK5vRM33XJlWdUa7MSKQ8TsQWzgI1Lpz0_8Y4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/104636890233270502260\">Rajeev Poduval</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:04.271044Z",
                                    "updated_at": "2024-06-21T12:01:04.271107Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "4a6b95cf-99b8-4b80-be84-f35d4c1b6617",
                                    "google_photo_id": "AUc7tXWhwimYm4vYc37NnKGOV0eV8qhGs8G7hedi4kiydCqa0mfxyButQoOq7KJKixOqpvEsow5ZjWyJ8JvcgCDllhTfc2G4EsU0Rft3HgrYsp6t-t_WfX0dQVXwu08oLF1sBq-pseQ-tF6xpZ7Sj65Ec8YvofvscpN60RPjpXUnUnC7xnqz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFdzwNUeMKL5MHqD4IsN2O_4YFT8Kt49vNKBx9uhh7Z5eaYcuRNKpUmLyHQwORgv9OMUeRBJQj6OUvrQhmVl7G3Mrlxp2SucpA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:05.472187Z",
                                    "updated_at": "2024-06-21T12:01:05.472236Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "4b0555d0-b8a2-4420-9cec-18a70fece495",
                                    "google_photo_id": "AUc7tXUGrb6qapP70fK7tFLn9uTb12e2kXTYe0R9-hIM75Pvo4yxvrrAMiflLuB46PujaMhE9XLaX6lUxMxVOJhN35-QBmg-b8FT40XvbuKXUktrBVF_RtgGXxTN0uR9tN8c1XXZsaL22hNG8KSN5V9WyONsfT0Bxw2RdDKYuDHOo3jjzbV4",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEnPBUrP-WA1HTXPqKjySiYew1z9CKNpyMbK-4fZTwMxUp4zU9TmtYsbZpoRSNVXhx_DJZdlerRIrgLZn40NkU5GMqy6jVxVEA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:06.764390Z",
                                    "updated_at": "2024-06-21T12:01:06.764444Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Hiking"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "Dandenong Ranges, Mount Dandenong VIC 3767, Australia",
                                "phone": null,
                                "latitude": -37.8334732,
                                "longitude": 145.3501044,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - The Dandenong Ranges"
                            },
                            "min_time_spend": "4.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:06.786316Z",
                        "updated_at": "2024-06-21T12:01:06.786353Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "b4d61e64-7028-4438-8ebd-547273807782",
                        "rating": null,
                        "activity": {
                            "id": "63946b7d-dde8-4906-8de6-d140ca05420c",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Yarra Valley",
                            "description": "Known for being one of Australia's leading wine-making regions with a history dating back over 180 years. You can enjoy a scenic drive, outdoor picnic, hot air balloon ride, or wildlife spotting.",
                            "website": null,
                            "images": [
                                {
                                    "id": "93b57a60-4af5-4d6c-8f95-a2fe07a456e5",
                                    "google_photo_id": "AUc7tXUOOmp9Us2g0710HHyB2Adt4CSztsddhVBiXC0VzEaHKFn5TXPshrJknYDRfYd81XC6BhX1holPd4hg1HzPu1wWm9ADi9kZUoevg1PuC27qFleXNVMCAXV_HVsfyIUHVjKbLkg9qIVY-jCcNkoQoEq-vQQP_pQAQrV_Ui0Vx-nMsMp7",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFE1hrcf3cNZ_HHfmhCn3w3PWLrrz7JbGK87MhozCh00Im8aP8eLYIySmQmaCx_ZbHFCb1qKbGQiYjiwmWq1ymrNvoztnk0JAg=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.436497Z",
                                    "updated_at": "2024-06-21T12:01:11.436519Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "96ad4082-3d11-4f45-9046-8a17cc748ba1",
                                    "google_photo_id": "AUc7tXUpIRTeud-6JYHtdrcbsXczNwxMSHUFxx3DiRgSyIUuMXySxT63gVV0LnsuRBTASYtesJZE31ViGTxyLcujE4OWh5upbT5AgpTOOd70RpfpmQaD7EjP7ugLfpu5cDYjX0h7BTdo2H-auu44VHehig5LBix_Hp-_OGi4Dy9nW1CZO3vk",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqE3NEF52nyNCMFXc8VkRxiqSbIhfBDMJcK6wT6X0vUc-PBuoeq7eoh2ZB4Q2762Wfzoa3eCSP_0Btk-3LFaPvpv3p5RH9ipL14=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.333283Z",
                                    "updated_at": "2024-06-21T12:01:12.333322Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "a9bf8bc2-d740-4838-91d0-69989b69c143",
                                    "google_photo_id": "AUc7tXVNPZBp3daJhlvMMs2if5LAE9WKe8Z4izseAPSyOWygOOZ9sZoY2VfgGZdWzqGUiCsri1aplpoIheVCoVRDDsCgdjneUeCstKfrH_3Y3Yl8HxCpM4YoJcq2pfP13Sr4-vaxk-OW9gP6nstCa5oNsaLQtK1TSRYB-RZ-mqcJKEA-na--",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGJaLUgjYh3hmaDpOK_xn7uh9GBgcB2MpY1zRGNcuro133ZQNgttcM9joDegXfnl1HpMI9Ad58zPfoWZfdXNbqiO-0eq9QUDrc=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:13.222230Z",
                                    "updated_at": "2024-06-21T12:01:13.222242Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "df25322b-c7a1-40d2-8b0b-9ae48c224a43",
                                    "google_photo_id": "AUc7tXX1lsL7lVr1dCXpmmzAED8nLrIP5NWYFji3wYMUNWKJAiQf-q_nt95IyQ8T72m7pjoFANAw88cwMRCO1ARBwgiOKmtc0PlcF2VtC865smFCPhC8TX-en3MRrYZgbI65bJk9PCF210gIiHJG7JD6QgwvTRkgitqe47Ne0rczBS255ufZ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEBdlGar_Md2mJ0qyazhHvwMgDp1pBypeOnpnQwbcUEY5snDy-YqpbOA2-OCN9D-3M_0UQQKy_WE02Q2_KDHr98k-V4dwVdvm0=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.166464Z",
                                    "updated_at": "2024-06-21T12:01:14.166503Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "e2796a58-949b-48ac-abf0-e64d86fdf857",
                                    "google_photo_id": "AUc7tXX3vnL_-UC1WJqnoxXgfqBWmmA8p9BqCF_d1y9SYaKjQGW18kCwwL1wJ2DKjLBiPdwvFTIVwwl48YxSq7uD187VieBgtZhpVS9AxMAPAhzFXTZnhbky9tyGityoG659BOX5cGY4rdSwEDuCcdTEQqRXy0gIRWXtcetO5AFVbBU2UsQ-",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHe-sBQX4oqhl-EzOTFL2CrfO0HrfQkPwUHGoK0kP4xmzBNFUZpFNGGC0pjl8VSnX-Bof5LFpQ-KXzHH6a-7DHhh8flTTvppS0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.170029Z",
                                    "updated_at": "2024-06-21T12:01:15.170067Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "9b6c5e61-49e3-4cbe-9cf8-5508e058df85",
                                    "google_photo_id": "AUc7tXWhONV6WVrENqL1UrhKqdEY7v_WxBadBRoHC5BYyqj6GK-e2QIN-kCLfK0lQj4Zxn1A4REA1JXzVfWyQNtJa9eWWs-3gNRbdXzQVObzfatd8QCPWU0WtBRh4e0hpgM4tg6e_rEqtns3-arHWUVxKc1AzXgv2DjIimWuayKkGm6iURXQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF3rSHjaIe6cXlhLhmlelSKtb26nGo6-B5YhQO09cSPPJNBhANO62j8QLcKe2DiFjQw4tbOvXxCZfXPqD-QKlpcXWx0hRifXgA=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.227252Z",
                                    "updated_at": "2024-06-21T12:01:16.227307Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "46d3b071-4414-4956-93fb-146bc72d218a",
                                    "google_photo_id": "AUc7tXUoFHUKg1Mar3M8u_KwPW2K0mDHmiGHJbR63wXq1OD3vQUsueRN98FbvclkNbMDxjWL9D6mYHOrgzZldJxW_1amGGPz_gidhaoRNUXn0Vu7LnEzhQ1GIvzd7qkTA7DIvHxYXoyfEhyujaBznRfip1TYE5F8KqW1gs8XMVOW3XVohkwJ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEcYMsO_Ii07ErnLT3DmlR3VqwqbrMxSUnJDLxwiJBazgthJvhA8ogcIQSPL7x7LyoiJKPJ7_695BAaKk5VshPeRKxoOvC7y5w=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.261260Z",
                                    "updated_at": "2024-06-21T12:01:07.261322Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "cea4cb49-ee2e-4c75-b1a3-25bc159d7248",
                                    "google_photo_id": "AUc7tXWDBlY4ql92h0hUHIzVL9NhB5RNhh58eMfGX319y9VYpJBZHBg3M0NmIxxGV883OKkVvNprWH2SosFP87dvQFBpFMX0Lvvyi05F_vbp_IBeDB3W4H3k7V1CZEP5THIUpzFZZTpkuKzvbY6D78SBtuqMDK05BwXYgoP-EriBaZTm72P7",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFUVeBTgiWYsaybxX9jOpNuvBDT1KDq_Jvg7gf1_PqIQz4YDLjFjUn-VjkO_J-4UtfwdzLjsVXUa_0nbyCIAyhPVSjXZmgT8uE=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.909035Z",
                                    "updated_at": "2024-06-21T12:01:07.909089Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "d8510741-c239-451e-b9ac-91c53c3c5ca1",
                                    "google_photo_id": "AUc7tXXOc7kMZ2BgHr64UjuNfmu5pX5gpU76OrIbHOkBZQFiZafDlYipgjwEpGJ-ikVkuJNhiQStlztLCcpmzwYMPXL2TjRQ_Zt_3H-FdbMdr9pwpL4p58W_f65lLnEZbFJJWbFaaRjy1pcJLd3epOTBDNeE6zcGBEvp9_mSRQLkufeVkvu2",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEmSgES80vhVjJUTWJXzZBhxy3iYn7opwqVWIkbx2sFv7qVOqQnhCR9yzlZxgKKaILMcjBJLhoANpWajY4Z4EQzVjmrbwFx8Cc=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:09.154881Z",
                                    "updated_at": "2024-06-21T12:01:09.154897Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "b6b14f74-645a-4e46-8a46-d703172ef9b8",
                                    "google_photo_id": "AUc7tXX_wgkbnAIDsOLk4BPseRNvbvvw9lQD3EY_CWggS4NrJdgG_ysHzZ1ScTcAnVCr2_mnP_h5f9weIhgGlbpbfKkx7TaSH1x79CtG5HF1GBHmttcGGzW5IEju1dDk2B_KJ2wJr6K5uzUMMs76SUsn2P6N-eVao7XkErl7lCFt_Pbz-83N",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFoF6Iop8G6u4n51haEl2vpepfh_p5meC1_hKI4FzoKC1qZZOGxvnLmxVDEj1_GtotXgBrxyX4-AGh_sAV9fES7sn3uhHTmkFA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.579417Z",
                                    "updated_at": "2024-06-21T12:01:10.579428Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Adventure"
                                },
                                {
                                    "name": "Romantic"
                                }
                            ],
                            "meta": {
                                "website": "https://winerytouryarravalley.com.au/",
                                "address": "Unit 201/98 River Esplanade, Docklands VIC 3008, Australia",
                                "phone": "(03) 7042 3201",
                                "latitude": -37.8243796,
                                "longitude": 144.9439772,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 0,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 1,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 1,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 2,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 2,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 3,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 3,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 4,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 4,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 5,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 5,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 6,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 6,
                                                "time": "2000"
                                            }
                                        }
                                    ],
                                    "open_now": false,
                                    "weekday_text": [
                                        "Monday: 8:00 AM – 8:00 PM",
                                        "Tuesday: 8:00 AM – 8:00 PM",
                                        "Wednesday: 8:00 AM – 8:00 PM",
                                        "Thursday: 8:00 AM – 8:00 PM",
                                        "Friday: 8:00 AM – 8:00 PM",
                                        "Saturday: 8:00 AM – 8:00 PM",
                                        "Sunday: 8:00 AM – 8:00 PM"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Yarra Valley"
                            },
                            "min_time_spend": "5.00",
                            "max_time_spend": "8.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:16.257804Z",
                        "updated_at": "2024-06-21T12:01:16.257832Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "ed7f5b58-7dee-4152-8d5b-e95503960190",
                        "rating": null,
                        "activity": {
                            "id": "5de2eb4f-a08a-4fab-9a09-53ce0862476e",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Phillip Island",
                            "description": "Famous for its Penguin Parade, Phillip Island is an excellent outdoor destination for wildlife lovers. Visit the Seal Rocks, Churchill Island and the Koala Conservation Centre, or take a leisurely stroll along its beautiful beaches.",
                            "website": null,
                            "images": [
                                {
                                    "id": "ae7dbea9-f386-49b1-87c5-8530b47046ce",
                                    "google_photo_id": "AUc7tXXFA35qAlFNbLMfZ_Y2nJw-e3LDOBkgrNupZpseI-Z4JuyQ46FD5Z1h4-igaY8NsT4ni-myDyPmAovL41mzNa6puquYnvmvrUjHys3aPqUaU_BA4HtUr7tuWdAUYxzv4hcsMDRDd6iipJ6FbS03FM0191-EN9x9-QjhE3-rSz2v6iNR",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHfarNLr1cp33eZLqZ2zRUHWihvgvahCC77C6Ob6j4xktRqqjn1qcELcs76h5KWHZWn_XsahaqM2DqT1eTWmp8p6yBOPLM5I74=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102926393754522234777\">PO Vignal</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.933075Z",
                                    "updated_at": "2024-06-21T12:01:11.933099Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "327f99f5-5a7d-404b-bdb2-0b852c33607d",
                                    "google_photo_id": "AUc7tXW2ZwjVyOBIvzNck8Zh3z4Qyiusri6IE0YwkjsID4pvAIZpxpu8biSLuvh8BUZVN6UG_-oKF_RGYHOWfGGHEwFvQblyhr2-tKo-QvnquCfB6VFlJBBSn0DatFnUIL9Il4IOmp1l28YgANLyFtuO4dzyVgJySQOteRoebylyvul9jAVv",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF4rqBruOnJg9dXR1zNNgM2NI1iZI62RK5xDQqzJBp-2JtMZJoHJ8CnjAz767udWPNkjLh7MiODLcNoWB24G1p5SbgIg12ghQQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112004520561690399735\">Pranav Malaviya</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.989751Z",
                                    "updated_at": "2024-06-21T12:01:12.989793Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "160db6dd-786d-4fd3-88a0-fea6578e3853",
                                    "google_photo_id": "AUc7tXUqejkAXMccXpN0k4zqDRAyMwVDXyG0gcfLKyKvpybaz4oONMs1ZhguDQPU3IbWZ1jWiXbNvhWOIoUns8_cDM8m0hAj5v6-pwihVmCODEHYbmpsti04x43Y9buBHCzQm4i3_GfJfD9m8aub-2vSQgRzKT44Yliqyc7uibKRjiO3Y5MX",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHRehSoCGC7qoGiKbX13NSw15bRf1ajDXyUgJ6jfsVB1foOWu4SzsT0GTY_dU4iKWVYOcSqHSywfnWsvBaC2i7eA7GqmUl-V0o=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112640599163821426207\">Lucas Cumsille</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.006895Z",
                                    "updated_at": "2024-06-21T12:01:14.006909Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "16c82653-3561-4a4b-a0fa-75b8cee9bd94",
                                    "google_photo_id": "AUc7tXVOOgtalG8trNjE6c6WJSXA6ADVXIJRNn9JAzKEa92SBUQ8aIiclOhcJHgzHTYMHBF19g0NArgs0N4xtYT2kL2yks8mYjhhkgWPAuTVAeN6oZj72XprdPRc58pRL5aZIZIGbAs85xPnM7nIBjRvGU-aUk1nCKsTE_lUdSQ2I520VKJ1",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHn2pl6R-vtq_7O0YFFdlxYD0Y_5ezokiBG-bCWKJdPt5Qd9-Fd6hzaqjUk4MdCu_CF9jJinZ3kL8WgkSbF0Gtfw0I7She6a5g=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106229234894671324773\">WD K</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.921308Z",
                                    "updated_at": "2024-06-21T12:01:14.921343Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "c0012c59-5585-4fb3-9c52-f45b42de920b",
                                    "google_photo_id": "AUc7tXU45XWKAaayIhp2tA2El1aWj_EfMXlr03ic1k5LNhFkOaVsUDIm0KybzwbqHHykGmIra4r-QI-FtJxaKhhiam-HTHx_MzuGkkW0tGzzBMxQb1a1OWCpUVJ5MHxWozjjacwzBZDo_6GfYgB-7cPkVJTd93nzMBUuR6fmhQMDV-kYDZyz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHy-Y5_lzBocw4rlkT3f4Sg-w1Rg3dEDk1iBcePufJUj_0aOZ-YsXuEr8HYOteyRa0ONX7ChwqNrqHx5MQ-QU1T3tJBSZyw6n0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112640599163821426207\">Lucas Cumsille</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.278747Z",
                                    "updated_at": "2024-06-21T12:01:16.278791Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "7bdc9a06-7ebc-4972-8286-014b190afb53",
                                    "google_photo_id": "AUc7tXUpkoCkvhM2xJat7tHBXc98kKb2nq8ogRE19BjNLlixoP_bYm3JMN_0kJWMWIl-5rfUFwbaeitF0CtJKa520lavC8z-s04rBOPEa3bk-ljFGzNcVPCrUgD9SYefU3or2lJXJ7cphsYLBV_Tq1BdxU0AKYnB8dSH3eMlkYYOxf81PMuj",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGk3sqo6S_1wZC5MSqV6ozQ60vFbMXQobzCUsPhtzwnfZMdWn9px8vHZdR8MbYz7pYinVHoMS4q_CUruap9IUMPRREt50w-4v4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106717742720312975081\">Jean_Pascal Grenier</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:17.293834Z",
                                    "updated_at": "2024-06-21T12:01:17.293872Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "303f9edd-338a-4d80-9761-052148444009",
                                    "google_photo_id": "AUc7tXWoikcy3wWKqrcueMeUTRLvwQPcXufAtJwC0Gzr0cNibXLsv2gtTTId59uwzGr0hA3xTDP3jLibpGlSuMRfPVdnVx3YtFhzCTO6Sphbu3bnWEmqBn5iybsA7bDuTMnDQ6gh3iFGikP1Ad-hnX9-CDEY2bPXLejUFk1XYp6zAPBD-qjF",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH9SA-NTuO7l6FUG54-N3JsuZdgKAaTPp6ew1oVp2IMzl3Hb4lZkEWYG5iYglvpRPi_ZP5ocknETZt3NXApxCCcevT-UODXfiw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112640599163821426207\">Lucas Cumsille</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:09.904116Z",
                                    "updated_at": "2024-06-21T12:01:09.904160Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "10109f04-75e4-4cbb-aed7-b7899ac498e3",
                                    "google_photo_id": "AUc7tXXgWwWulwPevCBJws3MlH8bqFEUEJuWZWkbOLXpGPO9QSY-Ur6V-0H0M7t65DyKzDpqicZmNFvYgW5JT8kM3uYoiL4XvD6jcGrcWizyDVKWbkAfZnuIxIY5L1XD7EEe1mFwGgD-byMkjQqdSrKw7CZosRahBiY7NbM4Zo1rfNcjoDka",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqE6PEmyW2r588qRowpPO-tIvuosvMnPaGCREuzQs9sjdHSVgimHe3GS5FgBJir1JNTTJ1mGFVFt52krLNjZKXWD5dMQyPJuBhw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106423388702238385504\">Heinz A</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.949208Z",
                                    "updated_at": "2024-06-21T12:01:10.949261Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Adventure"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "Phillip Island, Victoria, Australia",
                                "phone": null,
                                "latitude": -38.4898703,
                                "longitude": 145.2038278,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - Phillip Island"
                            },
                            "min_time_spend": "6.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:17.332031Z",
                        "updated_at": "2024-06-21T12:01:17.332082Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "2b921fb8-51b7-487b-bbe9-148633fd0def",
                        "rating": null,
                        "activity": {
                            "id": "78e21112-38d7-4753-9845-c9c00007b3a1",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Wilsons Promontory National Park",
                            "description": "An area of untouched wilderness featuring vast granite mountains, open forests, rainforest, beaches, and abundant wildlife, it’s a great location for outdoor hiking and camping. You might spot kangaroos, wombats, emus, and even some rare species.",
                            "website": null,
                            "images": [
                                {
                                    "id": "321f7fb2-2cd6-4d22-8640-fba5df4f7017",
                                    "google_photo_id": "AUc7tXUe7oXfZm0sFARkc0Fvh8Zkp_6v0vcRfBC2Pj24HO3Gx1jeaEgaHviWGad3ieU8K4rg7T868SGTKy6gB8_3SJ4PaiYliWv71lwN5eNi56FX50MfDUtFUN2ym7s7JdqP5cBoeiesISbZ4gFv8VP4W2V1JgnP0gzwZO-Uip4aznsLiOY4",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH2sZz912Cm8oUXCRYGtRm-1oJL8YoxIFjIIX5ggJZANWDi3VETFOFJg0wXKdJzQgGWlx15urRtBcKRw7gCuh2QT_HEXkwJz9k=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108477431713680891723\">Taze1100 GSXR1100</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.581394Z",
                                    "updated_at": "2024-06-21T12:01:11.581446Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "65861d6b-0eb0-4c37-a456-ed36e327f4d2",
                                    "google_photo_id": "AUc7tXV6CIPRZazrIbVTDgvOLY_o88DPuiuIaWcHeVjv_80J416Alki0cJ8HOfep34EsIYkG0eiwWVnUIbiG7TexStn0LQWWyHYITuIQJlkqc8WumWHcAk-kEp_LaevYhrl2xQcpCycnMD_z9yCm2a3UC0Dnfse_taDoeUU9xjF1iB4uslQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGppfVd80Mo10XLgy9Bivrnc11bAjX_ZcB74SDTwcd6k6ICoawoYcFA9bOfS88cwgM5Vfiaii_yt_6Le2K9PsSXoeBNKZ39iQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/105541536930594365032\">Asela Weerasinghe</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:13.055603Z",
                                    "updated_at": "2024-06-21T12:01:13.055625Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "bf110f4c-00d6-4edf-a100-fb6e6525c45e",
                                    "google_photo_id": "AUc7tXUM82IX2X5wo3TRo_xtV4OrH0fN11NbXXREJT2vYvIvWhsGPPDtOQFkYtRJPfGYmlMerZMo0CjnRbLQh_7F4RPjd_jTyHXxxtuYrAlBjTWj2QOB_f7PGKkcTM-vfrSoiNeTjpNOQi5RpAnwUPxZZSK6qsH5SoAMO1K0_4D6mJStUDPi",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFjGEz_1GZV--tCbHYeQFvzoWLM5whEz5fGDXZtkqE4VoeDN358CVHu8zAbRWC1mIyJVDwzU4YbivbkSpWAarkXd2sD-O45TM8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108408039188306688811\">Kevin Stuart Marshall</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.632753Z",
                                    "updated_at": "2024-06-21T12:01:14.632802Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "8212a7ca-56f6-45a9-8510-5151c64f3d8c",
                                    "google_photo_id": "AUc7tXVZz9JX_J85nHZFELAiL1qRL0XgGQrPn7Y4feb2XvZTmkT1ahAvkVdTy5Qj6ke1r4NRO0LlH5BG6UZp_J3M3wYgPrqzwHduveCISzrDbRPtVwx6yeQXH8IfkexQUZYrkXhk7bic1T36j3u3cmyQQoorfiJ9sZILK3Q12uvvzFU53NeA",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFn-zMLuT7Bx78GUEUFcU9I2POWu3de67QMexmstF8M27JkQCQjh9xblxhErjd9UV2COhGBhxYJG0ThoYFFRxc3polvqltBAo8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110755031809324153955\">Aditya Reddy Janga</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.619570Z",
                                    "updated_at": "2024-06-21T12:01:15.619609Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "93408546-3159-4edb-aa0d-a2deb1cc7eef",
                                    "google_photo_id": "AUc7tXVqtQ8-Zc_52RPywg8_3FvhW0aA4TdGpzVtOBKIDPmJorqByzKG689KxqT7DHqVMxy9aTd79HRBQAGjFNJHuQeSJu2wD2-_4EDZoMo0VD1k5dUhtH2mVIQXqP9sej7vPU4vfyhbK1_uLzGsMte7PaJe5r5TIIXThGJCpt8fQfpYowDF",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG_ueP0TJO9qbBHIFAVYScRYORtNoHAfh9KB-0MEK36Skt6WGIGI-Qp884B2GCqo8Bh7wzuDzONthkK5tVfeJf5YK1ZJCL2LZM=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117585085419020049259\">Felix Eve</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.698004Z",
                                    "updated_at": "2024-06-21T12:01:16.698031Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "01866a4a-f6d2-4f8b-8979-981ef526d0f3",
                                    "google_photo_id": "AUc7tXWcMPbK1EjZMAOPl-mmaYgsrwVfNMOorwPM4kcECgMWj3hz884s0rDXw8v2ISKvS-VxBi0tNAewfGHwG3tEJAqlkDJGSSNzze4E1vxtnXFs6cSlAlWgvuIhZTOeLz-xunX0o4CcDFdjF2ZAi8tCk7DkkuoGbGVTg5i8VtKJJlIlGods",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGF-DYxpKY881kiBuhk-oUvkFkFHl1219yQysYxmpfecDSb1C-8IWgHq0uLKa2rQ0eGa15mrJqDCtP1VY8dDoDA8trisa5DPww=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117172493908045468776\">Don Tadixz</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:17.514237Z",
                                    "updated_at": "2024-06-21T12:01:17.514253Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "71a6ee29-c02d-4c25-a77c-e9c2c0c0096a",
                                    "google_photo_id": "AUc7tXWGoUJ1bVhLVlQoCFoCCeaFroyUS73WzBe-2CnvLX1cQgL3vY8VXfNncws-sMUmoXsS3w5Md-iof0LCKoxhTxmF4iZO41sc5V6himljsI_kQbbaG90PAY1Y8ATTYLJOaJwuS7FDwhCNckREC0i5w0B2oc_ri3JEufPbimz57WNq7bBN",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEnrbFUd-Ye-8azku8kOf_sdT1M3OlwrPsOypMxoFuGr_AM6Nujv8YD8so0l37pHto__PVkDceFwatgDRFKDq4GGfjsaojlxeo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115193159902278859234\">Tristan Heinig</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:06.566503Z",
                                    "updated_at": "2024-06-21T12:01:06.566565Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "c957ceb1-7f9b-4a0b-babc-1a8ebedb54d6",
                                    "google_photo_id": "AUc7tXWBe-sA4kzX0Rl6tt72OjNOZp2cWnLe9vuxa28VdSZ3YKksae-OZqGB5jQroP5koVQlc2zWV0CFW8GRlPzgXXydIFrCQLIycAKkpX4wma2z_uSAtajAINgaZEfsiJdxapUoOB4LOwdgg0Jk8akxVxBcj3htTnRJpShXUP0s11NH_uDy",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH6Hr1TNIoC8g189cWpamaHaAxGksVHJ-CS_5OOsXpvTlh0Gpd9EU1ra_nGqzslwBkQefH14AuhAP9K0WbH-o0u6gTv93JHCQc=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112945076486467993319\">Martin B.</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.498323Z",
                                    "updated_at": "2024-06-21T12:01:07.498378Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "86c374df-445b-4dda-91ba-ae4e593c1068",
                                    "google_photo_id": "AUc7tXVEmNbNjA-y3DgONQAejGkiCyX3dVBmzXTP8gQ_ib3Dk53z7LmHo4zrLe9qpwbsmFxyAhRWAzqn8T4ZOaEyfU4RAl5wJMTqnH-jlCJ8b0pETecbRbCFddkrZem59VP186FyECnZE1W197A0zFpYaBss9g0ORxk_9dDHBy26hnRr0nVY",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHgowHdTfIE36tyujo-oRTGjnhV9ZYd6PVe8NmLzMUgY0EWh9gWVb41y_obOQMk_fnLQ7dMy9Py06c9XcoBkYE46H8CVYdgeVg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112851493017659730910\">Jason Parry</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:08.473128Z",
                                    "updated_at": "2024-06-21T12:01:08.473190Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "839b3e64-773b-4b50-9d40-b9076e22f075",
                                    "google_photo_id": "AUc7tXUYOTgUVgX5cRCN6Q4TDhscNbtoc0E8Iaffagso-XpjwcsVNlzd0pRitGFRtHRMcqwjWkyePJZ_BfQA0RIzlytXc7r240476UVogbIQd1aFqXRKajxOLTGGpl2A4w-W7EdahW5SpkuBUrXd6Ah5uzOrifBER6Y1qg6hvCNeXRmVpD4H",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFRE88nFgYDXdgrrFiozYPkGxOivonFFZDjw3hxb1sQQf7pb6v9tx87OjMWP6U54lkmh6bbtI45dHCPeL-aVH2vwGoY1OUTz9I=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113658289992535467021\">thieu ma</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.041004Z",
                                    "updated_at": "2024-06-21T12:01:10.041058Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Hiking"
                                }
                            ],
                            "meta": {
                                "website": "https://www.parks.vic.gov.au/places-to-see/parks/wilsons-promontory-national-park?utm_source=google&utm_medium=maps&utm_campaign=GMB-2020",
                                "address": "Gippsland VIC 3960, Australia",
                                "phone": "13 19 63",
                                "latitude": -38.9804,
                                "longitude": 146.3717221,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - Wilsons Promontory National Park"
                            },
                            "min_time_spend": "6.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:17.526249Z",
                        "updated_at": "2024-06-21T12:01:17.526258Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "a2b14210-de86-41f9-a3ac-93d72c2137a4",
                        "rating": null,
                        "activity": {
                            "id": "7e7cbf19-4476-4754-a732-c50b70bdeb1b",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Melbourne Cricket Ground",
                            "description": "The birthplace of Test cricket and the Australian Rules football are now the country's largest sporting venue, with a capacity of over 100,000. It offers a chance to take in the magnitude of Australian's love for sports.",
                            "website": null,
                            "images": [
                                {
                                    "id": "aee551fb-993a-4f3d-a69e-36ab2fbea862",
                                    "google_photo_id": "AUc7tXXGr_-rCBuIcheaLNn8yRrRhNnybA3ooJKlH5A0NzEdOZXG5vYuaD-096qe1d5ka4WmzB0mzobtxHGz1f3SECFQ7hOpWtZt2J70XJc-gz43SpTMKuFbEZteyOzkZSMnTjsE_E1NUaTN4woaKjfXwMQQdnulTjtzNIQw6qpAIyE-_vsz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGRXx6E-YXY9kYn6R9hKUOgptiKxYohZJx4QQHQUsKr7th7y6zWd2xQlw6tcpW8zD7uEJTniAzdXsowuTca2RLMUkWnS-ydr4o=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/109585313555722332617\">Sajith Ekanayaka</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.657290Z",
                                    "updated_at": "2024-06-21T12:01:12.657343Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "3c334a4a-9c88-44b9-8702-a7e860fbf4df",
                                    "google_photo_id": "AUc7tXUyR0lLw-ApzW1m9v99wViqz5dswnQdxBfigF_-xYZy4JHqavloVHsIQxAro9mS41fZjTEme_8brCgtNJVr5vX_WQ1NKC-81etJ8DRk9g_bb5N2oiZOlehOI_iOgEEdnxPYGVCrf2_5V1L4co6TNTmSyWQX_45o_UjwdoVJqqCXuTgB",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF65d0O3Bm_wkqTwz1YxXE0fO_zmDkVA8XUdRCzehMU4ZRrMZABux2XLxHEjffIl0Fu01IppOfFcD-pkqmO1NExZGpYbBfU2cw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108133620119895542367\">JANAHAN THAVENDRAN</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:13.625212Z",
                                    "updated_at": "2024-06-21T12:01:13.625230Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "f1a68c0d-4f2a-4456-ae3c-614ba2831d74",
                                    "google_photo_id": "AUc7tXUfpWuZO15Byz1FlqtsPYZY-oflyAA7tgrTroUxMbhtBtbTvAmGiJHUCZHzx86Y_CZFSkjJF4o0sOL89JSMLBcQ0DVFjRTzOR9LsaQKGvrr8m_Zf8lgq5-g48UQAhHDUmc5pxxxtYxNuLwlYgYXJn23hae3Q84MjQyhDLxkSilDoWE",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG6uJs-2l1XyNy3EKM1HNu-1IrKMPKH47wxymtlcH3AEhzaCZSlobkU7buILLSZU2gnur8-ph_O1imv4HYdxjl0BxRCzeMLzA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114074237381918383377\">Emile El-azar</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.691676Z",
                                    "updated_at": "2024-06-21T12:01:14.691723Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "aa61b32e-eea7-4940-bbc9-4603a214b087",
                                    "google_photo_id": "AUc7tXVLbIBPaSkgyTlbTg0GD1J6z1mKxG1C2VatJQqxI5vMJYAkH7sFSIwu_7vENfrVxNBkQRG-vzUynumoLMqTXTHRApAoXHhW9A-KPlRrmJM3zOPH9RIv9WpHDQ7RZWHhKIRA22s5NxFsUNGrMBaoHwwFjCToQ79pk_T1v1_LuFajZLWn",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGrxh8FtWvrJWEiL9fFbN_PHryq4BYwGH4v8FlbeH0p8tx_pPeIZTu77DA5cyugcsEjOTNt0HgDuQ3-hf5qF117cNcpDMlmOY0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106030202930963323410\">Jordan Verbeek</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.497877Z",
                                    "updated_at": "2024-06-21T12:01:15.497889Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "dae6f868-1f3d-42d5-b852-192f8502a26e",
                                    "google_photo_id": "AUc7tXUPaKaq4Z3A7rQf-bat_VZvVouxoNpWaIXZfsVtIdxfjVsDscENab_lUOMMzurt64K6hv2kD_OfYHIUzhdR42AbpnD5mmfjimiip-4x_A_BPkGdhYpKC8FC7f_vVixZRXvEHeoAS0Ug8je9Hizp3RAya_dlNsgXLrSfVUSA_tqTQtQu",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEQNCps3iVIYslfJPggAUcEkwZSnips0_oxWHKH5pqYlY1nFvDi1rrFfuUZdUs5KTAsUBxaKHeOYUFd37j1mPwUIkUASq2nNEI=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/103137058424422996557\">Anuj H</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.455335Z",
                                    "updated_at": "2024-06-21T12:01:16.455347Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "e56efe0c-8c52-421c-9fa5-456d6abf6b3d",
                                    "google_photo_id": "AUc7tXUZvI2eFclCmRFCKBbo87jEdEw7apl5e0FtSw7sMA35wT0vznNriObqpW_fDGB2ttKgpoOWY1H_5pOml3b9zWcoTSFJykg9d_JW5LJ55o0FjHXRMILYYUuU_Frw8HkdoMAhSG4i_PKXrximwomdAlj2HXUNuxd1V3WZyle5RFf8O5Ul",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFMKVZl_TJ7wp48oLjZJrM5UTBen_WxKS6oZJxahLAgKAp_8DhweKZyji8KVFCkCrnXnXS1VYvcdUBbLa2DKCHPDRoTUknLZvQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100460985845294690249\">Vignesh Ekanathan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:17.594196Z",
                                    "updated_at": "2024-06-21T12:01:17.594208Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "46547734-d961-4843-b3fc-c4c4d29ec9fe",
                                    "google_photo_id": "AUc7tXXjSSuoMF9s1wQMZ4jmJw8JqLr2E4i8W1eDZCgcFZCi8e63YCUskoc_FCOfuxJHcN460hsVnPjCHWoVfzqZDMU0qIpTVCP06h8FpMuK58_3NeN1_M0XY39nkGPAc6KUWeyFMSV4HS2RKWGUX9sr5ZUg7nsmViH2MTh9FEkSlI-N6Xh1",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHpX5t4zklu1bus7CECZpJ9Eig79rAUxwI6TEx1h897AKQ6SzMhT79YJKf91UqW9wCUtm02VCROFW-mGzWv5hv55RULUeU0Ehw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110678867753466976780\">Rod Edwards</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:08.299825Z",
                                    "updated_at": "2024-06-21T12:01:08.299885Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "06de4abb-c463-4509-9fc4-74f7a1dc078f",
                                    "google_photo_id": "AUc7tXXy6_aZHlP3n3wsF6mde-JG37Y3dxNjgGRqvpQcp8igtyXrAIpQlqh1Oz6nAI_YP4Frs-kwWNiwWS5gYzAZoaSJlEXFafqz0Wj1jqKbtZ36TQK0FE-Feyxnc5GFs8zSIenPPeKn8kKJbNTKVnUj6L5lIjaUKYQRBFwWhv59e1Q1nHOe",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGDMg_gXerNTN2NC1qz_Jcah6yuArpL6kW2gb5NA2UatRghTAxMp1pqp3Svc0C9NuzH54C3ofi0w4VqJp1LmQj3tjE4bJh6Xk0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/111353958648543476054\">Ashok Kumar Viswanathan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:09.404786Z",
                                    "updated_at": "2024-06-21T12:01:09.404823Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "c10b9b5a-5d07-4477-a5d4-fe2a694aebf8",
                                    "google_photo_id": "AUc7tXWBhICYspJ1WYOr-7NMXl5Lqtg5RGqk9aS1Vw0LQe-Z1mWvLcvCMywLcFg6P_5OiUmCvGw0TrzsfPz6GGOMZCioQ3QozGA60_aTLEJXhSvmL0YDKeB6Xl_mxPEx_4BZY1esuYVp_BSAHqmg5FxQfBVY3_Z-6qax5b6O-2MQEo7GXG1g",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHvjezA-xfgFknc-ySMl762BiFvbBr-PT-9-FkSQ6KWfBdKhnlqcgYaXs-oTaYeQGem8j2jqrVXGJlmgFTRIykTulYtxi4sFdg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108610446934986207776\">Anku Chapagain</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.301196Z",
                                    "updated_at": "2024-06-21T12:01:10.301211Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "588a9063-fab4-47c7-b1b2-d3a5f5f2912d",
                                    "google_photo_id": "AUc7tXV91_392M6Nwji19ZvkLttl9hLBNe3IMokR0lfKNOnprzpWHnqtEpe2qo2zJFbtX1LgPLxQRPqK1jPLSlRHZlWsOfQBo79Sq9r7xJKrcae23FLa3eabO0-JxE_EwME8Zh2HYGALjicg5K8o-yLkgNO4wcBp5AVW1H89jjMwqEFVS6tX",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEuXaByL-N7FHq9uCmoHthwN4hy7MUe5jOSZhn9eM9W1LFBS_-tji5PUfymXbun3tf3T49irV0bFqjLxz3qFA_OBmGlODyUOeY=s1600-w1011",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/107153973353174560682\">Souvik Dutta</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.042969Z",
                                    "updated_at": "2024-06-21T12:01:11.042984Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Iconic"
                                },
                                {
                                    "name": "Adventure"
                                }
                            ],
                            "meta": {
                                "website": "http://www.mcg.org.au/",
                                "address": "Brunton Ave, Richmond VIC 3002, Australia",
                                "phone": "(03) 9657 8888",
                                "latitude": -37.8199669,
                                "longitude": 144.9834493,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0000"
                                            }
                                        }
                                    ],
                                    "open_now": true,
                                    "weekday_text": [
                                        "Monday: Open 24 hours",
                                        "Tuesday: Open 24 hours",
                                        "Wednesday: Open 24 hours",
                                        "Thursday: Open 24 hours",
                                        "Friday: Open 24 hours",
                                        "Saturday: Open 24 hours",
                                        "Sunday: Open 24 hours"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Melbourne Cricket Ground"
                            },
                            "min_time_spend": "2.00",
                            "max_time_spend": "4.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:17.613572Z",
                        "updated_at": "2024-06-21T12:01:17.613582Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "106e27a7-7ddf-47f9-ad12-0dd1a424678e",
                        "rating": null,
                        "activity": {
                            "id": "e198626f-3ee7-4d78-be73-91c1bd9c4fcf",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Port Campbell National Park",
                            "description": "This rugged, seaside gem hosts some of Australia's most famous and dramatic coastline including The Twelve Apostles, a series of towering limestone stacks. Don’t miss the breathtaking views from the viewing deck.",
                            "website": null,
                            "images": [
                                {
                                    "id": "09204b17-535c-467e-9e48-71a02a621234",
                                    "google_photo_id": "AUc7tXWR-g5-4eorBgs7BieR0fEfS45cOE4AZ_pbACVGxM7zcHXnn8wzp2A1f7vFINKg0sW1qrBaKw4Y75q_xz7_E20CutcfpZVJralehrXUkLFKs5GmxnWX5cwCkgRfs9hB2YKe-7PRr3w_ydEzKjwVgpHieiIXXuJw1ZIYPJHjwnlodOYz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHMIzBHzQSpMtoFix7QS-Kti0JmRRMMLOnUyLuYNqDSmEdrzSLePlbtZfAi2bVQ0zerRpqj2zN30AIRP07YL0dNc_YCrm6ZR5s=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/118222184205323490123\">tania biswas</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.256777Z",
                                    "updated_at": "2024-06-21T12:01:11.256789Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "f9fdef48-7a74-43b9-ad75-ba945db2e3a6",
                                    "google_photo_id": "AUc7tXWVOE1ZWiDKkWxqspN5BASLN7Lr7GRtzjZFXEfDJESPOmM_2nGvCwm5hZiDRRjcQPRLOfYi8QlNVGpJU0t8JUOtp6XO9v2e9rmldMPu6xfwuwdWelJ0UZuvTv_pQu_fLkotMEWp-hbniXfsUmG7MowX4jYGXJRuoQ4Bb95nND5WZ6TS",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHylTeJOe9IEFzQieA-Y2wEXUXKecS6kTSMYSimrjrghbhYBVRygDEWe_aojGhUMyJDpSZKnr0X7C-KDelI_okyg-sjXts1Ej8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101641002085926767958\">John Pure</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.603599Z",
                                    "updated_at": "2024-06-21T12:01:12.603652Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "baca0a7c-341e-4e69-88c8-269041bb53da",
                                    "google_photo_id": "AUc7tXUmbYBdtID_UgDcaHebUyzNnWSw_nFVgIuzZT-JvReU89mXYWylFql-EnKs6HemGVbqFf_K2eOXxggm8Z_EUhYiJe3t3Rh5VGzTGvxEfEwii4HwJ4_355Csy8EN7V3_ZrmENMvvbIH1Lk7Vq4-hgULuL4-TZZuso6gJ2cU1HVvDpko0",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHFckrbNBJFIMnPt59czdJAVl6Pk8WI4LnFzrQ5OeNbTjjvnZdSZRgCoxMSbKkXWbEZ4yuVatZXBg-XrSpDZa7vrHwF6Q4DKSw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/105934112101653295885\">冯志坚</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.474371Z",
                                    "updated_at": "2024-06-21T12:01:15.474385Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "b44e5328-2b3f-45a6-9ab6-5daacd85459f",
                                    "google_photo_id": "AUc7tXV0bY6idlGgPX9wss7_H6DkJD-P6hVDI5P_cgd4TWHzzD3pn_Z-scmcuMUdaBwob0eaLqWtYvEyS4SiO0jsPUAvtP5cfISXdMQ0Pzpju1H2DauWDfou1wIbdwmTT1I-C5oszUrwA9Fh82tDR0PMMEI03hD0UnOxHXyw2m5xDDHBjgaC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHAmuF4MZeYXYTj39sj1W2A7tEjV7e4OFb-Cmn_lF6Z-k5xnAM0bYZ-Mo4ht_20W1vIEJp6jiznuQ5oKmox13qFGEpkdF4Viu0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/104856952460086182884\">Greig Taylor</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.905821Z",
                                    "updated_at": "2024-06-21T12:01:16.905857Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "faf795d5-bc3d-441f-96f8-ed5f7c14a8c9",
                                    "google_photo_id": "AUc7tXWuI3MtmgXKjOQYgB7ZJgTaHqYBdmwLqadfltGyYOCJGtXem8tfnAVrvAUZO-FxlZYheIVq79IBZjqN7EOp0FH0It1-1ybD-2e5je6DglmN4kBD2WRxuw77DPr-pndDHjFg5sOYewYndWrDc1LRVl-YaBpmlHaRbnM_jSL_ZGntkt1v",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqErEcWSx-SmebwHUxWyCsA36zFRdIP8OGnmp64HhLDSap8TZpkdedO0JTVXyn8s5QuCBbDpp80GniXjS6X_RoIaFnh1MSKQd1I=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/107559583361667944452\">Hans Haist</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:18.597711Z",
                                    "updated_at": "2024-06-21T12:01:18.597753Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "d99a46a6-7a63-47f0-bc28-03f57de021ea",
                                    "google_photo_id": "AUc7tXXVXqiMaaYAoINRZ9s6qacx9Vw7BHzJiTKhw9Lme0b94W0ovLuXG3fl860lGVSbO7p39CfHcldWdPwGqZJ0BHElpN0BAed10GBEeFNRR5w-Qy-1lYNkjXBO3PEuBPm_xttVuo-cyuZ_0IY0SbAsrj6rPBg9PoEclDgeCTlzy9Z7u7ET",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGoIvAabLjZ3dDXQw7e_Lj4J3EkTHQ5NZsupglcRTehN0ZVqAr-H4WP_4_1QO1Z1n0Su8Pbvqaw8G8lcg71pgjWeJ2tmakXc28=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110969954720451426123\">Craig O&#39;Keefe</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:19.963391Z",
                                    "updated_at": "2024-06-21T12:01:19.963474Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "e5fa4031-6a60-4a5b-82b0-3ac483ddd982",
                                    "google_photo_id": "AUc7tXU9LDDhyyz2MpgCVGoFwbO6khUMsf5RziXrFLC1CzwPdE5Tzp5vUlsPcqHIzZMllIHCUaG2v_l6z_8aHNfun6O4wDIwBYtzdGePH1StqRRZTn6pxPjREHXFJE6UdZ7kMp_AkmR_XRStj29ts32W7eHryeof5vDjSWcEQuxlq0GpIulk",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGN8KtO358DkokAp2wtDiuF3vfKRWBjufRvmZ0cUSz7dWDYckA2i0zM2DZp5Gkpc_GlmaWVdhNz-z0aSyZeSjPV7QONAsukcBw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/109240572640698745312\">Port Campbell National Park</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:06.539516Z",
                                    "updated_at": "2024-06-21T12:01:06.539567Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "6829d2aa-e3a3-47d3-8a0a-8c1a3d905a1d",
                                    "google_photo_id": "AUc7tXVr-Gqanj7X3srgcloun1rXYhgGKVlSrAvvhlTM34JbDLgeIMcCfKfC8NKK-yrdtRFtEPyFg8QQ-p43IDGTHEqW70rZH_8gM8ouoaaxkaaHfnYo_GYc6grbbUaNBOWptotnbemeGIUZSn3PCIAqs3E7Ht1VCsZ5EyYoze92STX7Y-7W",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGTPHQpD0cYUjxoCXqT6LumGPZp8MD4r6fQD4KCmDTRFL_R11iu9M_Y2oBVITh4ikYEcEoHhw3dI1Py1gfurSPt-vEqIVJ0U98=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108900661979460289583\">Ekramul Islam Tuhin</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.601130Z",
                                    "updated_at": "2024-06-21T12:01:07.601146Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "44e4535f-e743-4063-be0c-0a561049720a",
                                    "google_photo_id": "AUc7tXUQNoD7R1qRu7Fg1eJpAT_rEsU-CckCeqBTKj6mx0i9vxPMp1K4V28SHJluREx59sOzlbC5d-20zCV8myqXXNLMSzcsd-ItygjG5qJEaK7tHacA98vNIfn6sEyChkfCkbMgW3j4OR4Mh4NEnEKUsMlmgINQGGa-KeE5j-aBO2FO9VyA",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqE6tidAr6Ra44JjHnGOVJEzF6XbSxQiJmeupDUlzLwDbkcqBMSsrXdE-Trb8P4FU5LNvJ-uuYCMiKSA8MbMdiATh0WdNLv8LFs=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112831475068636997516\">Robert Day</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:08.848808Z",
                                    "updated_at": "2024-06-21T12:01:08.848871Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "dcf76ebe-fa6e-4a46-b82b-f8a367bee093",
                                    "google_photo_id": "AUc7tXVJrjC3YCidO2NOXVjmQXmzClFuRSmufs-lvZ4lKGNGVA3xMFPpSYdGY-PwY1gVfZo3He2xitdMA4ohBuvq3gsSKk8we0NVtWYM0KKlqJQGIybWLPe9-bB4nz-SwkeR7HakrTgO8whH69pj-2ouVJgT-bEhmQV2WTAyY7q3z4AjUxYE",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHwPMu1m3QMApZ-92KRFG4Bi9qFY8v2na6Xj8PUDBpvXKoT6k64x7Nx1WcGa1rggq6wp6_6mlJ3PMmgureUSqwwbAlmtp6g9VQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108921529119856628116\">John Zhang</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.150544Z",
                                    "updated_at": "2024-06-21T12:01:10.150560Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Picturesque"
                                }
                            ],
                            "meta": {
                                "website": "https://www.parks.vic.gov.au/places-to-see/parks/port-campbell-national-park?utm_source=google&utm_medium=maps&utm_campaign=GMB-2020",
                                "address": "Great Ocean Rd, Port Campbell VIC 3269, Australia",
                                "phone": "13 19 63",
                                "latitude": -38.66209229999999,
                                "longitude": 143.1050308,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0000"
                                            }
                                        }
                                    ],
                                    "open_now": true,
                                    "weekday_text": [
                                        "Monday: Open 24 hours",
                                        "Tuesday: Open 24 hours",
                                        "Wednesday: Open 24 hours",
                                        "Thursday: Open 24 hours",
                                        "Friday: Open 24 hours",
                                        "Saturday: Open 24 hours",
                                        "Sunday: Open 24 hours"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Port Campbell National Park"
                            },
                            "min_time_spend": "3.00",
                            "max_time_spend": "5.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:20.003873Z",
                        "updated_at": "2024-06-21T12:01:20.003920Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    }
                ]
            )
        )
    }),

    rest.put('http://localhost:8000/api/activity/:activityId/rate/binary', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({}),
        )
    }),

    rest.get('http://localhost:8000/api/trip-activities', (req, res, ctx) => {
        const page = req.url.searchParams.get('page')
        const trip = req.url.searchParams.get('trip')
        return res(
            ctx.status(200),
            ctx.json({
                "count": 10,
                "next": null,
                "previous": null,
                "results": [
                    {
                        "id": "aaaaaaaa-7dee-4152-8d5b-e95503960190",
                        "rating": null,
                        "activity": {
                            "id": "5de2eb4f-a08a-4fab-9a09-53ce0862476e",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Phillip Island",
                            "description": "Famous for its Penguin Parade, Phillip Island is an excellent outdoor destination for wildlife lovers. Visit the Seal Rocks, Churchill Island and the Koala Conservation Centre, or take a leisurely stroll along its beautiful beaches.",
                            "website": null,
                            "images": [
                                {
                                    "id": "ae7dbea9-f386-49b1-87c5-8530b47046ce",
                                    "google_photo_id": "AUc7tXXFA35qAlFNbLMfZ_Y2nJw-e3LDOBkgrNupZpseI-Z4JuyQ46FD5Z1h4-igaY8NsT4ni-myDyPmAovL41mzNa6puquYnvmvrUjHys3aPqUaU_BA4HtUr7tuWdAUYxzv4hcsMDRDd6iipJ6FbS03FM0191-EN9x9-QjhE3-rSz2v6iNR",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHfarNLr1cp33eZLqZ2zRUHWihvgvahCC77C6Ob6j4xktRqqjn1qcELcs76h5KWHZWn_XsahaqM2DqT1eTWmp8p6yBOPLM5I74=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102926393754522234777\">PO Vignal</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.933075Z",
                                    "updated_at": "2024-06-21T12:01:11.933099Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "327f99f5-5a7d-404b-bdb2-0b852c33607d",
                                    "google_photo_id": "AUc7tXW2ZwjVyOBIvzNck8Zh3z4Qyiusri6IE0YwkjsID4pvAIZpxpu8biSLuvh8BUZVN6UG_-oKF_RGYHOWfGGHEwFvQblyhr2-tKo-QvnquCfB6VFlJBBSn0DatFnUIL9Il4IOmp1l28YgANLyFtuO4dzyVgJySQOteRoebylyvul9jAVv",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF4rqBruOnJg9dXR1zNNgM2NI1iZI62RK5xDQqzJBp-2JtMZJoHJ8CnjAz767udWPNkjLh7MiODLcNoWB24G1p5SbgIg12ghQQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112004520561690399735\">Pranav Malaviya</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.989751Z",
                                    "updated_at": "2024-06-21T12:01:12.989793Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "160db6dd-786d-4fd3-88a0-fea6578e3853",
                                    "google_photo_id": "AUc7tXUqejkAXMccXpN0k4zqDRAyMwVDXyG0gcfLKyKvpybaz4oONMs1ZhguDQPU3IbWZ1jWiXbNvhWOIoUns8_cDM8m0hAj5v6-pwihVmCODEHYbmpsti04x43Y9buBHCzQm4i3_GfJfD9m8aub-2vSQgRzKT44Yliqyc7uibKRjiO3Y5MX",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHRehSoCGC7qoGiKbX13NSw15bRf1ajDXyUgJ6jfsVB1foOWu4SzsT0GTY_dU4iKWVYOcSqHSywfnWsvBaC2i7eA7GqmUl-V0o=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112640599163821426207\">Lucas Cumsille</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.006895Z",
                                    "updated_at": "2024-06-21T12:01:14.006909Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "16c82653-3561-4a4b-a0fa-75b8cee9bd94",
                                    "google_photo_id": "AUc7tXVOOgtalG8trNjE6c6WJSXA6ADVXIJRNn9JAzKEa92SBUQ8aIiclOhcJHgzHTYMHBF19g0NArgs0N4xtYT2kL2yks8mYjhhkgWPAuTVAeN6oZj72XprdPRc58pRL5aZIZIGbAs85xPnM7nIBjRvGU-aUk1nCKsTE_lUdSQ2I520VKJ1",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHn2pl6R-vtq_7O0YFFdlxYD0Y_5ezokiBG-bCWKJdPt5Qd9-Fd6hzaqjUk4MdCu_CF9jJinZ3kL8WgkSbF0Gtfw0I7She6a5g=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106229234894671324773\">WD K</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.921308Z",
                                    "updated_at": "2024-06-21T12:01:14.921343Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "c0012c59-5585-4fb3-9c52-f45b42de920b",
                                    "google_photo_id": "AUc7tXU45XWKAaayIhp2tA2El1aWj_EfMXlr03ic1k5LNhFkOaVsUDIm0KybzwbqHHykGmIra4r-QI-FtJxaKhhiam-HTHx_MzuGkkW0tGzzBMxQb1a1OWCpUVJ5MHxWozjjacwzBZDo_6GfYgB-7cPkVJTd93nzMBUuR6fmhQMDV-kYDZyz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHy-Y5_lzBocw4rlkT3f4Sg-w1Rg3dEDk1iBcePufJUj_0aOZ-YsXuEr8HYOteyRa0ONX7ChwqNrqHx5MQ-QU1T3tJBSZyw6n0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112640599163821426207\">Lucas Cumsille</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.278747Z",
                                    "updated_at": "2024-06-21T12:01:16.278791Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "7bdc9a06-7ebc-4972-8286-014b190afb53",
                                    "google_photo_id": "AUc7tXUpkoCkvhM2xJat7tHBXc98kKb2nq8ogRE19BjNLlixoP_bYm3JMN_0kJWMWIl-5rfUFwbaeitF0CtJKa520lavC8z-s04rBOPEa3bk-ljFGzNcVPCrUgD9SYefU3or2lJXJ7cphsYLBV_Tq1BdxU0AKYnB8dSH3eMlkYYOxf81PMuj",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGk3sqo6S_1wZC5MSqV6ozQ60vFbMXQobzCUsPhtzwnfZMdWn9px8vHZdR8MbYz7pYinVHoMS4q_CUruap9IUMPRREt50w-4v4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106717742720312975081\">Jean_Pascal Grenier</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:17.293834Z",
                                    "updated_at": "2024-06-21T12:01:17.293872Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "303f9edd-338a-4d80-9761-052148444009",
                                    "google_photo_id": "AUc7tXWoikcy3wWKqrcueMeUTRLvwQPcXufAtJwC0Gzr0cNibXLsv2gtTTId59uwzGr0hA3xTDP3jLibpGlSuMRfPVdnVx3YtFhzCTO6Sphbu3bnWEmqBn5iybsA7bDuTMnDQ6gh3iFGikP1Ad-hnX9-CDEY2bPXLejUFk1XYp6zAPBD-qjF",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH9SA-NTuO7l6FUG54-N3JsuZdgKAaTPp6ew1oVp2IMzl3Hb4lZkEWYG5iYglvpRPi_ZP5ocknETZt3NXApxCCcevT-UODXfiw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112640599163821426207\">Lucas Cumsille</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:09.904116Z",
                                    "updated_at": "2024-06-21T12:01:09.904160Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                },
                                {
                                    "id": "10109f04-75e4-4cbb-aed7-b7899ac498e3",
                                    "google_photo_id": "AUc7tXXgWwWulwPevCBJws3MlH8bqFEUEJuWZWkbOLXpGPO9QSY-Ur6V-0H0M7t65DyKzDpqicZmNFvYgW5JT8kM3uYoiL4XvD6jcGrcWizyDVKWbkAfZnuIxIY5L1XD7EEe1mFwGgD-byMkjQqdSrKw7CZosRahBiY7NbM4Zo1rfNcjoDka",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqE6PEmyW2r588qRowpPO-tIvuosvMnPaGCREuzQs9sjdHSVgimHe3GS5FgBJir1JNTTJ1mGFVFt52krLNjZKXWD5dMQyPJuBhw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106423388702238385504\">Heinz A</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.949208Z",
                                    "updated_at": "2024-06-21T12:01:10.949261Z",
                                    "google_place": "5c090598-f214-4358-a617-6f721b720675"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Adventure"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "Phillip Island, Victoria, Australia",
                                "phone": null,
                                "latitude": -38.4898703,
                                "longitude": 145.2038278,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - Phillip Island"
                            },
                            "min_time_spend": "6.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:17.332031Z",
                        "updated_at": "2024-06-21T12:01:17.332082Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-51b7-487b-bbe9-148633fd0def",
                        "rating": null,
                        "activity": {
                            "id": "78e21112-38d7-4753-9845-c9c00007b3a1",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Wilsons Promontory National Park",
                            "description": "An area of untouched wilderness featuring vast granite mountains, open forests, rainforest, beaches, and abundant wildlife, it’s a great location for outdoor hiking and camping. You might spot kangaroos, wombats, emus, and even some rare species.",
                            "website": null,
                            "images": [
                                {
                                    "id": "321f7fb2-2cd6-4d22-8640-fba5df4f7017",
                                    "google_photo_id": "AUc7tXUe7oXfZm0sFARkc0Fvh8Zkp_6v0vcRfBC2Pj24HO3Gx1jeaEgaHviWGad3ieU8K4rg7T868SGTKy6gB8_3SJ4PaiYliWv71lwN5eNi56FX50MfDUtFUN2ym7s7JdqP5cBoeiesISbZ4gFv8VP4W2V1JgnP0gzwZO-Uip4aznsLiOY4",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH2sZz912Cm8oUXCRYGtRm-1oJL8YoxIFjIIX5ggJZANWDi3VETFOFJg0wXKdJzQgGWlx15urRtBcKRw7gCuh2QT_HEXkwJz9k=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108477431713680891723\">Taze1100 GSXR1100</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.581394Z",
                                    "updated_at": "2024-06-21T12:01:11.581446Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "65861d6b-0eb0-4c37-a456-ed36e327f4d2",
                                    "google_photo_id": "AUc7tXV6CIPRZazrIbVTDgvOLY_o88DPuiuIaWcHeVjv_80J416Alki0cJ8HOfep34EsIYkG0eiwWVnUIbiG7TexStn0LQWWyHYITuIQJlkqc8WumWHcAk-kEp_LaevYhrl2xQcpCycnMD_z9yCm2a3UC0Dnfse_taDoeUU9xjF1iB4uslQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGppfVd80Mo10XLgy9Bivrnc11bAjX_ZcB74SDTwcd6k6ICoawoYcFA9bOfS88cwgM5Vfiaii_yt_6Le2K9PsSXoeBNKZ39iQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/105541536930594365032\">Asela Weerasinghe</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:13.055603Z",
                                    "updated_at": "2024-06-21T12:01:13.055625Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "bf110f4c-00d6-4edf-a100-fb6e6525c45e",
                                    "google_photo_id": "AUc7tXUM82IX2X5wo3TRo_xtV4OrH0fN11NbXXREJT2vYvIvWhsGPPDtOQFkYtRJPfGYmlMerZMo0CjnRbLQh_7F4RPjd_jTyHXxxtuYrAlBjTWj2QOB_f7PGKkcTM-vfrSoiNeTjpNOQi5RpAnwUPxZZSK6qsH5SoAMO1K0_4D6mJStUDPi",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFjGEz_1GZV--tCbHYeQFvzoWLM5whEz5fGDXZtkqE4VoeDN358CVHu8zAbRWC1mIyJVDwzU4YbivbkSpWAarkXd2sD-O45TM8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108408039188306688811\">Kevin Stuart Marshall</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.632753Z",
                                    "updated_at": "2024-06-21T12:01:14.632802Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "8212a7ca-56f6-45a9-8510-5151c64f3d8c",
                                    "google_photo_id": "AUc7tXVZz9JX_J85nHZFELAiL1qRL0XgGQrPn7Y4feb2XvZTmkT1ahAvkVdTy5Qj6ke1r4NRO0LlH5BG6UZp_J3M3wYgPrqzwHduveCISzrDbRPtVwx6yeQXH8IfkexQUZYrkXhk7bic1T36j3u3cmyQQoorfiJ9sZILK3Q12uvvzFU53NeA",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFn-zMLuT7Bx78GUEUFcU9I2POWu3de67QMexmstF8M27JkQCQjh9xblxhErjd9UV2COhGBhxYJG0ThoYFFRxc3polvqltBAo8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110755031809324153955\">Aditya Reddy Janga</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.619570Z",
                                    "updated_at": "2024-06-21T12:01:15.619609Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "93408546-3159-4edb-aa0d-a2deb1cc7eef",
                                    "google_photo_id": "AUc7tXVqtQ8-Zc_52RPywg8_3FvhW0aA4TdGpzVtOBKIDPmJorqByzKG689KxqT7DHqVMxy9aTd79HRBQAGjFNJHuQeSJu2wD2-_4EDZoMo0VD1k5dUhtH2mVIQXqP9sej7vPU4vfyhbK1_uLzGsMte7PaJe5r5TIIXThGJCpt8fQfpYowDF",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG_ueP0TJO9qbBHIFAVYScRYORtNoHAfh9KB-0MEK36Skt6WGIGI-Qp884B2GCqo8Bh7wzuDzONthkK5tVfeJf5YK1ZJCL2LZM=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117585085419020049259\">Felix Eve</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.698004Z",
                                    "updated_at": "2024-06-21T12:01:16.698031Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "01866a4a-f6d2-4f8b-8979-981ef526d0f3",
                                    "google_photo_id": "AUc7tXWcMPbK1EjZMAOPl-mmaYgsrwVfNMOorwPM4kcECgMWj3hz884s0rDXw8v2ISKvS-VxBi0tNAewfGHwG3tEJAqlkDJGSSNzze4E1vxtnXFs6cSlAlWgvuIhZTOeLz-xunX0o4CcDFdjF2ZAi8tCk7DkkuoGbGVTg5i8VtKJJlIlGods",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGF-DYxpKY881kiBuhk-oUvkFkFHl1219yQysYxmpfecDSb1C-8IWgHq0uLKa2rQ0eGa15mrJqDCtP1VY8dDoDA8trisa5DPww=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117172493908045468776\">Don Tadixz</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:17.514237Z",
                                    "updated_at": "2024-06-21T12:01:17.514253Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "71a6ee29-c02d-4c25-a77c-e9c2c0c0096a",
                                    "google_photo_id": "AUc7tXWGoUJ1bVhLVlQoCFoCCeaFroyUS73WzBe-2CnvLX1cQgL3vY8VXfNncws-sMUmoXsS3w5Md-iof0LCKoxhTxmF4iZO41sc5V6himljsI_kQbbaG90PAY1Y8ATTYLJOaJwuS7FDwhCNckREC0i5w0B2oc_ri3JEufPbimz57WNq7bBN",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEnrbFUd-Ye-8azku8kOf_sdT1M3OlwrPsOypMxoFuGr_AM6Nujv8YD8so0l37pHto__PVkDceFwatgDRFKDq4GGfjsaojlxeo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115193159902278859234\">Tristan Heinig</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:06.566503Z",
                                    "updated_at": "2024-06-21T12:01:06.566565Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "c957ceb1-7f9b-4a0b-babc-1a8ebedb54d6",
                                    "google_photo_id": "AUc7tXWBe-sA4kzX0Rl6tt72OjNOZp2cWnLe9vuxa28VdSZ3YKksae-OZqGB5jQroP5koVQlc2zWV0CFW8GRlPzgXXydIFrCQLIycAKkpX4wma2z_uSAtajAINgaZEfsiJdxapUoOB4LOwdgg0Jk8akxVxBcj3htTnRJpShXUP0s11NH_uDy",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH6Hr1TNIoC8g189cWpamaHaAxGksVHJ-CS_5OOsXpvTlh0Gpd9EU1ra_nGqzslwBkQefH14AuhAP9K0WbH-o0u6gTv93JHCQc=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112945076486467993319\">Martin B.</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.498323Z",
                                    "updated_at": "2024-06-21T12:01:07.498378Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "86c374df-445b-4dda-91ba-ae4e593c1068",
                                    "google_photo_id": "AUc7tXVEmNbNjA-y3DgONQAejGkiCyX3dVBmzXTP8gQ_ib3Dk53z7LmHo4zrLe9qpwbsmFxyAhRWAzqn8T4ZOaEyfU4RAl5wJMTqnH-jlCJ8b0pETecbRbCFddkrZem59VP186FyECnZE1W197A0zFpYaBss9g0ORxk_9dDHBy26hnRr0nVY",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHgowHdTfIE36tyujo-oRTGjnhV9ZYd6PVe8NmLzMUgY0EWh9gWVb41y_obOQMk_fnLQ7dMy9Py06c9XcoBkYE46H8CVYdgeVg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112851493017659730910\">Jason Parry</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:08.473128Z",
                                    "updated_at": "2024-06-21T12:01:08.473190Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                },
                                {
                                    "id": "839b3e64-773b-4b50-9d40-b9076e22f075",
                                    "google_photo_id": "AUc7tXUYOTgUVgX5cRCN6Q4TDhscNbtoc0E8Iaffagso-XpjwcsVNlzd0pRitGFRtHRMcqwjWkyePJZ_BfQA0RIzlytXc7r240476UVogbIQd1aFqXRKajxOLTGGpl2A4w-W7EdahW5SpkuBUrXd6Ah5uzOrifBER6Y1qg6hvCNeXRmVpD4H",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFRE88nFgYDXdgrrFiozYPkGxOivonFFZDjw3hxb1sQQf7pb6v9tx87OjMWP6U54lkmh6bbtI45dHCPeL-aVH2vwGoY1OUTz9I=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113658289992535467021\">thieu ma</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.041004Z",
                                    "updated_at": "2024-06-21T12:01:10.041058Z",
                                    "google_place": "2e7f4726-d339-4690-9368-4f7afd7a89be"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Hiking"
                                }
                            ],
                            "meta": {
                                "website": "https://www.parks.vic.gov.au/places-to-see/parks/wilsons-promontory-national-park?utm_source=google&utm_medium=maps&utm_campaign=GMB-2020",
                                "address": "Gippsland VIC 3960, Australia",
                                "phone": "13 19 63",
                                "latitude": -38.9804,
                                "longitude": 146.3717221,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - Wilsons Promontory National Park"
                            },
                            "min_time_spend": "6.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:17.526249Z",
                        "updated_at": "2024-06-21T12:01:17.526258Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-eaf6-4d26-bba6-c01b3c86db21",
                        "rating": null,
                        "activity": {
                            "id": "8c4e6c41-2d4b-4569-bcf4-8e6440b9c838",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Royal Botanic Gardens Victoria",
                            "description": "Located at the heart of green parkland extending south of the Yarra River, the Royal Botanic Gardens Victoria is a treasure trove of horticultural biodiversity with a collection of 8,500 plant species, beautifully landscaped gardens and sanctuaries, and the beautifully restored 19th-century Garden House.",
                            "website": null,
                            "images": [
                                {
                                    "id": "f05b28d8-6475-4191-a880-1e2242b3a186",
                                    "google_photo_id": "AUc7tXXxUNqCqZ0Zlu9IeN_Ggdsi7FxJIfpPUsk6j2Wd2Sc5kEcw3WRs9s5v4gGZ41fKTsSBNW41D_7_XbeXIHjEhOCIo3DkUG11QYVdQY_V2oGI6CrrtN6gQEwzNnRxRJ22K-NCcsQCkq3P_paJh26iMjhIsIuU3neSfENdCHCS2V5fL8RC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHWuVuHKV__qClsssB_N75JxsDRpUCHpU-KQh2Pi1NeE9OLDsjGn3qCIe4N2x0PaExGKToLrU42bSytsSiuNzXbkG-dpLK2j6o=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110773365454945438434\">Luke Baker</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.398743Z",
                                    "updated_at": "2024-06-21T12:00:54.398757Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "cafdc0d9-b40d-4fea-b536-b36398629c35",
                                    "google_photo_id": "AUc7tXU4kvdnrWIqAlm3dxvJop-SREK1yw9y6KlV5-_GpP96y3aB88xbzTRa8aCgnf6wcpvYrBGj1UpfgF5bGtLmEEA-Gf6wpYPM3japo_MZOM0Rj4yvOKwb06HsaSnQNTt1v2Gy0VysG5ZLSDHVxag13v6C4mHAbgAc6qGz5jQ6qqQu2S-K",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEvjs5p2C35wRgDBFKNpErUIZlnicEIl8_LI80roTFMlvMScmWqtoo3e1tIDCSc-FIsGTVkW4EbqywIZaaqEVTp-EtGtw4m-P0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108164069666858808077\">Simon Labone</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.158414Z",
                                    "updated_at": "2024-06-21T12:00:55.158426Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "d92f7362-5daf-448b-bcb8-47ea489ab7ed",
                                    "google_photo_id": "AUc7tXX1FBJY4ro9oh3F9Sro6sTUF2ImvgjWQomQFT5rG6rzhsXs2bKWJz1t3X_Vqv3I5kn88QQCKwHPSoFMWRee3fVtQvo1UAH6SpxWQQbxFAjH1pSuNp73uo8akSDksysrvGLEd0Bqglgnm8navD5oimZkMjbeWdnaPlxy2AAgewNAdHF6",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHIqQTov1YMUjwlmKzM0-gICXJKCVM3wenZ1kabamagEoH7i0DJOV0Pby-OHvheptatkpiC1vol1S2EXB6DadAjYcHGWeqRxjw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101855308381690838780\">Ivonna</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.199516Z",
                                    "updated_at": "2024-06-21T12:00:56.199547Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "0fb8da3c-9757-4348-a01b-05eb5a143386",
                                    "google_photo_id": "AUc7tXVWd8OJOLzum312S3U1gMpEdnOMcPShab8BB0aP2osaP19OLsw0wNG2IFF_8FWB-b_c6Xrtu1H03JUwSR6ZGInlVOZg59ORGxDBgKpm1e9sLei6dDJvKJMJU6am2rc-VyOfzgZ8nEG2ayeJiJfhyHkJOp7r_DsJXvrJ01fin5zvtotw",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG2m3kNOqYpzkXmI_jgCL3_1muhtoUneQFV9J3ZZi_7UOEw-m8Hbx4v_BSsBz0aHw0mO9p4lLNgUFSuwEr9aPCGpxnaNqEAG50=s1600-w736",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113482343908983147932\">Randolfo Santos</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.942274Z",
                                    "updated_at": "2024-06-21T12:00:56.942286Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "9d7ca694-104a-41ea-8729-6998ed46408c",
                                    "google_photo_id": "AUc7tXXKC-jgyoAuTZBVP0kIYkw7HHOxcxqmucDHVLgc1crxE6ETcFIvXQ_FjArqBAMxC38McWEk0Z4BxGNr72LImORSCNUJVBl6zzfWQwaK7rAm3wJWisMQ7vzzczsX8wqU3txWy5WeyzHzyodGzZ3lDLw0uGPh-Dc3G_I8VqJ8moepekLY",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHUC835qyx3-ALSIvD2RsfX1T8bi5NftytK86A3ZHydRn11Fqxh03oz5ECS-u9PX-IhiIFUAR4OpiA04zBHXdCMYaSzJqTbYkw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110035741806686125626\">The Girl Who Flies</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.678987Z",
                                    "updated_at": "2024-06-21T12:00:57.679030Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "20664347-b252-4885-b67a-b8a6d03e878a",
                                    "google_photo_id": "AUc7tXWQOFB448rVbN-3LQH0Ri2KA-3KFmIv5EBScXkxvlp83mHIMfJlBoCStvzQnpwV_mCLFw3SVE5V9c6hkx-rF2jnab3dHyrNE1t5wUlUnNq50j8Yr-qF58l2Rp_bQSlo_weiuTDTjCLgrH-vX7Fz5bKV72Q-qgF9iXBFgfICN1vunZSG",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGfBvQyvi_qs-umW8kzegM6oktVlsUkml512t0Kgvva6tFgRDF-TSJHoajPe92mT6iNUlPwlO5tPh34S5zYKLxsuWZIPCyohjI=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/111306359296328700061\">Winston Lee</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.527895Z",
                                    "updated_at": "2024-06-21T12:00:58.527906Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "2948ffba-fdb4-40da-b093-4287e77a303c",
                                    "google_photo_id": "AUc7tXWCFplgvWMS84bZTdp08_bv-5JMaDmHg0F10XveZKycxiCqim5_PYhcaNexfs4ZyhTyOSz_vx_UU4UbYs6jvUTiqS5qBDM1hb1l9XGK19wez6M98zyJPWgt7_TZc9KWdRf-qG7H5LfMERa-00npcl7ox5qBLeyVznyT0rtUBq0rA9Z3",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHVBwcypaDxvy-eNHbBylxcBC3jrZm-Gwp6w1WZ2rb4QtbUabfF24A8bmsz0ZnBY0Eu87itUVmr-HvWwIJwCRK2nCgfXmOBp9s=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114836440654289524479\">Migo. C</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.463866Z",
                                    "updated_at": "2024-06-21T12:00:59.463911Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "06de854b-58a2-4bc5-811a-984a81aea167",
                                    "google_photo_id": "AUc7tXXMPLTvb_v9_n47NQ-TtpEkxK5VGAvziZoJ7CfaWoq4GVaQnCNv_3BTGib6B-X9ADsut0MfZ3wnVYe1w0lFcQbIWinO9wo1FNtvLpDpfGcH0vQ0axqaki_Jj6H0-gPt9X6ggfo14WodJR7fqD3rgidgIrHOOqGZhoy-PckQuaVCSBR6",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGXGuNouPqR_mRQhq0AfNKgj-ywIsPIxnk5o66xdtj-tjJHFuyFJEJErYwCIfh9Gp5wxG-h5Ie_qmxaBjUeyTn_BrftxtkGnyo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117280566143560612924\">arpan panchal</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.571008Z",
                                    "updated_at": "2024-06-21T12:01:00.571022Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "5483264c-246e-4cc8-bc07-035aa6a92ee7",
                                    "google_photo_id": "AUc7tXVXwOzI-H1ESkZzQlBGFhmlL3FM1Iof_Re1o4LW2uet-q3lHk7re3vlN26iw88WEW-pDcQFSFMLA-IdIGU9Ogurvu-F-_k26FBW6syX6CrMtSyqJIu8_cPummpMOiCGtPiGopAE0dIT4pts6iI1AyChSnoVxX-hdDF_H4drTU2BlSEN",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH9u1mDXgd0rwkbJEsqnq8PY7kTPIzb5pQc-eAZ9XbMi__47OiYjH6BU5IyWWKrsFVxv5weDZrOEfesy04fUCNFXp5gHcLf-eU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/103967285565048036172\">Leisurewithtees</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.709756Z",
                                    "updated_at": "2024-06-21T12:01:01.709796Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                },
                                {
                                    "id": "f7056816-1cfb-4518-a1f1-148267ec4627",
                                    "google_photo_id": "AUc7tXUJpxOt1607ApmfeRFQNtZf-qUK3wDemod-V98Xvi0Y9GTi9o0j7OzSuYeBI8v-h8wCT9-hjuCG3wm333AwvDloKy9z-ix7K7TVa0mYBFAeefWBtYvJHpATQlpiqqSwu2opHWUX3auDb6FkpaC847n1szT4M08mXYtFQyYtatanmKqB",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGZF43bpRXqjCO5_GVpZpgB9bjKYZYWxgjLJRfBtP4-yxyebY0jTqA7wjWUzF-5GeuncOcHOnEpiFopv5_H4IK705nEpMG4thU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115966924984810708712\">Gaius Aurelius Valerius Diocletianus</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.800349Z",
                                    "updated_at": "2024-06-21T12:01:02.800362Z",
                                    "google_place": "fe23089e-c5aa-4b3a-b70a-c5935d2aae6f"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Historical"
                                }
                            ],
                            "meta": {
                                "website": "https://www.rbg.vic.gov.au/",
                                "address": "Melbourne VIC 3004, Australia",
                                "phone": "(03) 9252 2300",
                                "latitude": -37.8302443,
                                "longitude": 144.9801496,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 0,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 1,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 1,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 2,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 2,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 3,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 3,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 4,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 4,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 5,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 5,
                                                "time": "1730"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 6,
                                                "time": "0730"
                                            },
                                            "close": {
                                                "day": 6,
                                                "time": "1730"
                                            }
                                        }
                                    ],
                                    "open_now": false,
                                    "weekday_text": [
                                        "Monday: 7:30 AM – 5:30 PM",
                                        "Tuesday: 7:30 AM – 5:30 PM",
                                        "Wednesday: 7:30 AM – 5:30 PM",
                                        "Thursday: 7:30 AM – 5:30 PM",
                                        "Friday: 7:30 AM – 5:30 PM",
                                        "Saturday: 7:30 AM – 5:30 PM",
                                        "Sunday: 7:30 AM – 5:30 PM"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Royal Botanic Gardens Victoria"
                            },
                            "min_time_spend": "2.00",
                            "max_time_spend": "4.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:02.831005Z",
                        "updated_at": "2024-06-21T12:01:02.831016Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-2c28-4219-a53a-3966b8583e88",
                        "rating": null,
                        "activity": {
                            "id": "c31a3e9f-7d47-4abb-aa2a-97c64925a5ad",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "The Great Ocean Road",
                            "description": "As one of the world's most scenic coastal drives, The Great Ocean Road offers an experience of a lifetime with panoramic views of the Southern Ocean, Otway Ranges' rainforests, towering limestone cliffs, and beachside villages. This Australian National Heritage stretches over 243 km and is the world’s largest war memorial, dedicated to soldiers killed during World War I.",
                            "website": null,
                            "images": [
                                {
                                    "id": "f2a26f7d-bad8-4f16-a147-281a72a82c45",
                                    "google_photo_id": "AUc7tXWYpH56qB9DKaxLqKxqPgRFbPBHv6UPM6kYtl-mTpNyov1UPe8PsD0AjjlETg8Z_Cx-SOiOO_Ax7aqTSqdaV0EvSGpKc2O40iZVPIHOSlA3ncrzv6Um5r6pT78ac2EdrGPW5KnOJ1_57M42MN3eIvBsuoq7uuRPE6hvV0TwJipaIil8",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHCxxmBJRen3X2p-CH_XAb0NcNwlb2YB6_EgyIYlUgK4tSv4TOQhv_y2ft43MGB0J8Dig92VDsVAju-b7z2Cn0Du8UcLv8GFK8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.758920Z",
                                    "updated_at": "2024-06-21T12:00:54.758932Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "a8cc525b-6582-4c06-ada6-fe270b04e4cf",
                                    "google_photo_id": "AUc7tXXKSbsULCMucojfMqN6niAHVILgzPPmdm7sZAfkNjANdUAVYKVLEuHtEeQw51kQv7ULjdoTm4YLxHDf4zyGxy771i_KWnt_a8ACRAMjCmwMkSR9zeAWZXhDSyYBGnHk1LpvLdGWPjsRDnkHMVP6lCFwtgaCBDVA-xLwdrkNxDs9KsMz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGxcHja58HcmrmtfTAtO8Mt0z8_nwNMVXwsGrRAwRsAMprkqXGioW_0-gv85mRIokXRpLfghpAtok-NCM-k46DLwDRpai6xm0w=s1600-w612",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117233789177808345940\">AKSHAT RAJ</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.552757Z",
                                    "updated_at": "2024-06-21T12:00:55.552806Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "a67d9ed9-b34f-402b-87d5-744cb196a012",
                                    "google_photo_id": "AUc7tXUrX4uUK1laGuLeqwVIFpkZmC_XekJw82woCspWE0ffrhIFwcvx-rotwYT4sMKl4RCv0LFXRs1mXFnvsaeDphwG2tw3LwIa0ntJGjYDRO3NonqVX3svW08UuHBNCZ00fDBgs_CqzuZiITSQmYDUiIkw8i00b0qD3xpH2m5ruElU6xhg",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHiv8Rjv5YRVlsPz6HvAzBygxmoZgi0rg8U4bOTDqYqQc85XjbUQoDJkdSdhxmFyQ-pCDxomz3VST8LLUA6j4T7bwYDgz80dmw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.407608Z",
                                    "updated_at": "2024-06-21T12:00:56.407619Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "1f3601f0-8af3-4394-bbdd-ca366d40644b",
                                    "google_photo_id": "AUc7tXUi9QtYqocr7h23ya6mgTGX0F6T22ps9ns2105r_nEYBEdAIKw0EkJMCh2tlzFbqc1xb8kcYsdfBff8vYheiFwwv9CUQFKxa59jg9wy6w9mIlHXsqLvfnkbO_gYTYK5Jh_jCig6G5YQiZJcgEs-eBhdaDJIklDZps3LhjFOPKn63U6T",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFtZw_rhfx0NuN6Hk3e92rIE1XV5PiXLbw7uJ83bjkmrYZ6aXLl8yZR9zTCjGthu2fHoWhLHimIXY7-Hg_NkHaWiL2MNNOC2Bo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113142087721512063500\">Philipp Mauerhofer</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.332220Z",
                                    "updated_at": "2024-06-21T12:00:57.332283Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "4456361f-a172-4629-83a8-5dfb1e0c4484",
                                    "google_photo_id": "AUc7tXVuDaNKBOs_kM9Iy3JabEyKZ62MV-njvd8igS9Lp1HYT-KG_OddD_SRNLf5pY2Ekfw3ROxKHtaYGPBzWjILJdYIMLazsBIwvNziIM_3x2pbLC6ITJMtAwW2BVDCR9gFN5Mr0WyeyxvmCJhGYtKkOpYP74G6dpL6jfDIHb4Ne8E5tnoC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHiF0_4pNKZGM92Kwf23yOGV01GKkOjux-X3jG4Ggkd6YVHbHSiJKcLgWVs_BbazjhbCVlf1Hyvl659p3KP1XqfOm0NGDJ5ckg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117992426843348195394\">Abid Ali</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.271438Z",
                                    "updated_at": "2024-06-21T12:00:58.271485Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "ff4cf7f1-5a5c-4e25-a725-e23de9258d8e",
                                    "google_photo_id": "AUc7tXVotsq1cUj9SVPB2f3HLR2ZpKVvmItbjOitttucyIrkwk-1xZRTZsZ00LayydG68oJ67dNceNScJpO0MgU7k4ui7I2GQVIdX4NZxWAqugw05Q5_xXifMwDqAgrbIW-FpuZxpNMY8TU7Aj6BplE2-bV_fhzjlEG8GbFyoYhAY_xR5FMT",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEg9ShKxTdk7LQMDqlwAq4a6Y-li_-J5rqTkrMw2diZnpMEcOWRQ4RiAP4WgwBjEdZ48BFVJsuDCsqHKcsCA09D8u5f-fK_7n4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/107520450214732015131\">Sandra Giebeler</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.146565Z",
                                    "updated_at": "2024-06-21T12:00:59.146620Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "8b475719-c8f0-41ae-b4ca-a26f62e9a55a",
                                    "google_photo_id": "AUc7tXXg6K9xkynE-t6X18lYM8wwB0gIlvqKU7jF0ECCgnEGmM4QMxWqOvWD9XfWPKqxT7CGPyn25mimQ6ZjYHDYYyYwGMepqSXrtkFiwr2o0rVJJAJQvfbmR8UUr0IwoC8ehVMiTCKTXMgcpPUi0FfW2dYm2KbPLsyBa7xPrS_Co9IB3F14",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF5Yn-jsgNHqkB3M6yo6mzRiAey_UNxCofli42paP2mkShqYpkIP0kXH8Sfra_PUCOpAaUOjn_F2ICU65mcTPW3iOGtsRWKCLg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114625849255864946925\">Roger- Philipp Rickli</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.315285Z",
                                    "updated_at": "2024-06-21T12:01:00.315347Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "ee67f883-1018-42e3-931d-f20aa92b357e",
                                    "google_photo_id": "AUc7tXU0vKScl8ZdynNW4JHIHypZNmjmt4Ujdms1jMa2C8EZ3zV7tBP2njANPWIDbR0gxZZVxPBD-coa5FRe7gmUrPZl_DJa4qEwR_yYXSKt0pgqd_i45RO14Z3M16cbg3JItdgx2soOgD2RE1qQrxzJHlpfdd2h8pEVN_Zg4xCG4OGXU68M",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHYImg1riQW6rXJJmBP-_EeJ13G9GCxXvIWd1o4R2FGuxqpGljGJ9rxj-03TUKDW8gT7ybsujkFwSCUFT-qkGR5xGVwxqeMgsQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.243356Z",
                                    "updated_at": "2024-06-21T12:01:01.243384Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "af1db06d-0d35-4513-8a48-6781106e4199",
                                    "google_photo_id": "AUc7tXWoVig2uM_Ujz41EGozVrwyaISDmALAQetkgYBRutYdiTqbYsrF__QeqM8jdP7NtFAZch6Offk-6QjIjZFGThnJpOo0EpZru6sJJg8cm6t6fd_vNZjRjaTj360daeToBy5KJZ4mA7PqVtNLAwEo42-NSxzuAXBSR-sv5ggTFRgSJaH4",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEMBQ4ZwvCX_dvzmF9VvpT_Twp0cMwmirrMbpvCcieWk8-uVutmgwCTps5oEXogWyC_JTQG0qw0IAUE0uSvtinwFiTgklkumlM=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/105913246834688042259\">Jason Buhaguar</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.097837Z",
                                    "updated_at": "2024-06-21T12:01:02.097891Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                },
                                {
                                    "id": "f9a9bc36-36c5-48ef-af86-ea8e1f7a6ac2",
                                    "google_photo_id": "AUc7tXUsBM52YZF_5nTZErFIrc-x2J7hDA3IALS2zvtaqh2tJ_TkjaWfd1EBjli_3K3GAU2CmwcRg0aD3fpB9m1ZPtUtYJniYUneYZrZCfT2S4T5M7uf-mGl7b5MILr3rO1G682LcTxuNPrNQ-68qRBML1Sjg3_T3Aerq8GDvNaXqZk1Mn_P",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHMFSoiBl9hpp_nbffZoZwG_w0trgrDWUx0Wgvo-Ofg9Nf5FpeUVFWuMLA1J1HEOZBqfCInQp70tprpsDK_lm2oPZz3JqYUbcY=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102938466735646365189\">Edward Liu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:03.099989Z",
                                    "updated_at": "2024-06-21T12:01:03.100037Z",
                                    "google_place": "6d70ee88-e276-4a78-af65-e72b940e0b25"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Picturesque"
                                },
                                {
                                    "name": "Historical"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "Great Ocean Rd, Victoria, Australia",
                                "phone": null,
                                "latitude": -38.6804339,
                                "longitude": 143.3915596,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - The Great Ocean Road"
                            },
                            "min_time_spend": "9.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:03.122032Z",
                        "updated_at": "2024-06-21T12:01:03.122070Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-229d-4394-b915-d6bf71f2cb4d",
                        "rating": null,
                        "activity": {
                            "id": "d7412cad-39e4-429d-8cbd-0d31ec7cb60d",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Melbourne Zoo",
                            "description": "Home to more than 320 animal species from Australia and around the world, Melbourne Zoo provides a great day of outdoor fun. You can see everything from elephants and lions to kangaroos and koalas, all in beautifully landscaped settings.",
                            "website": null,
                            "images": [
                                {
                                    "id": "57841744-1f7a-41bb-8589-0a2b2ba43fa3",
                                    "google_photo_id": "AUc7tXWGkff9nrrH6jpFg40F7hL5mNnX0Y9NBdOivUo6kI-0YGwztBfKEn8Nt84HeREOd9FBnc2X3YGgNmmKPoIdjtK8mtWBf-xDmYd5L_NabUXhX1Q0kwDcgQnvrCndf86e0t-GcEvJXWGf7O807lGjidhxcCO4qxOYdQ74kdw2sZ712y8z",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGabXjBEROCgh5eMZmD77fWB4etcw6rajneAWtNa0GgUmurYy-Su_sQ5PAKusFO2WToLOwdnfC5xwwgydxY3EfZUPc6ngkB1b4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112229653183258276103\">Epic Walrus</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.292145Z",
                                    "updated_at": "2024-06-21T12:00:55.292161Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "4fc772a9-9df3-472d-a77a-25c670e9e0bb",
                                    "google_photo_id": "AUc7tXWD5Z0a_awkDA3PwF5iBd_8FwLgVXPDzaeaUQUlymlkvO1pWPAPyHgg3uKOW1RrJqlWl0dCJLOT8HK9jrQY7k1cZMEh-pWICrCsnzssv5IzcZXzi0zG3guIHWoRbtctYb1fZ8-RRPETuHya03qQbXEm7ovN-9csZIa0DzjCN7MFkHXm",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGjERkILD4Lgn8DmSa2C8T2nbWwENhNaV9xyXMMENeCwxpA7tbjH_jgeq7XTrZndu83jzN0KSfX4pdPp89-V_sHf3qTWOSo42U=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102087088896800693616\">Deborah Woodberry</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.292573Z",
                                    "updated_at": "2024-06-21T12:00:56.292586Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "36934364-54e7-4e47-8a5b-7fcb74c16b04",
                                    "google_photo_id": "AUc7tXWi1mKSomBYM1XyffT55HWLwnR4PNMHUnsmOT4eGK4AaR3ouEfypUsQmmNJH0HhSiKNzR_38H7t9OIAqwbANvfsBCa6S14xQAQZT-kdTVjouIg3kiPxDrvxV0srz6L3sT_YeQuTI9REWclQ1FAD5kbgwi4Z7m3_ZxC4uwgfHrB2tAYN",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEuy6f6x6pdRtUY1lb9Tr6uLJsbPF6GOitVhXBGFjV0F-nJaeWCBzdp3moPYzAf7kdq_iGH6frNbDhM-EILgbIgjzyLLlaWFew=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/102089377006409655709\">Edward Wrightson</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.050491Z",
                                    "updated_at": "2024-06-21T12:00:57.050521Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "4746e87f-6f50-497e-8d95-b0167c2fa18e",
                                    "google_photo_id": "AUc7tXVbtjgYDuUByQ0PIbaklbWVYfTScn0-2B7OBbncMzG-Q5veJtTU9m2m6JjtxheIDRvOfr2s0uhY0O6poaV46Nr9GPNmu7vaPFupD59zBOXM8wFQ9QlyN7_-Qj4O3qWTTq0vkr2mcfq_NOsnRBRzU_h_DONUEz4OqbLTrmwZbyVUn2E1",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHCmIN6PJFFamWRznzYz5dq0JcEue_kZz4Qb9d9bNRkxOoCyhgjPHaRCSMzLaMht_3geYh3kZTrL2r4AXmNqf8q_dbCf0h1gjo=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113182075871475519618\">Amelia Macca</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.199239Z",
                                    "updated_at": "2024-06-21T12:00:58.199287Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "a27b10cd-ec2d-4c75-8c8a-b9dfa51eea38",
                                    "google_photo_id": "AUc7tXVNLL72zdUNQr8uTsYD_XMCsNto4v-oUjcA3tABMKTjWvgoF1fLZGR-9akJ8OOTblpMUJUKDebNMR50RcxMjHjw4ux0J4IH4VnKJERNngNiaUViUNhR2kTLjQD9vFswtXcq4XQvpN0A5cv-aV0OKYfYdQlj8NwmP055iPSlN6DP1ACa",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGds2PdUMO2z3_Ijs_QlEhPb7-7wB_-TXpvVIpSufGu-fpKWAeOgq-q1L5HDra-MB3AZqDSVr1v7A1uVecvsYiZ2KeqUq11enA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115266825001406401620\">Huyen Duong</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.180839Z",
                                    "updated_at": "2024-06-21T12:00:59.180888Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "b4866b9e-6c01-4f8c-b862-e54a1e07dc52",
                                    "google_photo_id": "AUc7tXXnSQR4jY4qmWzU5r5vdW1KPrZO8yHoENFzerb2QrRbBIQgi31d1mrY9nK9xPPwYN9TNLLak3beV93BlxehwUqssZ5xhkfypbjtihg2QcQDu3c-GndD7mVf69AzoGIfdSpmZTTx7uaw1nQWy_pU5XrvvsNgsD_INb2BoIf3sKMCfGT3",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHhvOWhPNPVytPAHo0fUJalXHKQzgM9f3w90T2m7adamwKdCuR4equakyFPtAbM1NoJBJYzYtaBSJSBSx8DKYmb0h4rn7ZgMBQ=s1600-w650",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100682561788400770828\">Krouse Dorrisag</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.806873Z",
                                    "updated_at": "2024-06-21T12:00:59.806895Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "3966b895-498a-43dd-92f7-334cee4060b6",
                                    "google_photo_id": "AUc7tXXR5JHmDwvWmE9N9NlHCMWU3lY33fJ-roHTggoQ39JQESqhrX4tujA5Hn1Uz3Q4tBtBigMt73v4ydt78tID_XKDMPRWNPcra1FDV43O7wT-c7umXEp40HfNqdBzYdF6ZVaolFZClxoalZ9DHm23zHW6JXrWMfZF89O6vWcu84atQjZ0",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHELtQ8KtEO-hZKhoIg1yRbqiY246Qt2WgNTOUJOICp36Vq5vdh4yK9Tgf0pXlBPd1gM8hCOVbQcFJtI-GzPCL-PZlku9uYQ-U=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/103440315744658822029\">Sarah Archibald</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.559637Z",
                                    "updated_at": "2024-06-21T12:01:00.559728Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "935bcef8-d769-4000-b328-c682e5512c15",
                                    "google_photo_id": "AUc7tXXuJ2fPPGPq2Q1fFhDkz2Z5jl9iVbTxQn7vm9kYL_nIR92f_871OtEfyZtee7irlZmf61qcvu5ei9VDB5fUcbAY47b50O0noIX2Tdue2Ek_fCNbwUR21I8stfJSPxAQx_-me0WxcC1lqpfF7M7yr4PmP_1ZeOh9IZ1RibY7jyOfl0nL",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEoqoDpDocEUU9mhBI88kRDv1320lD-xvoMlPFkNsibGR0bPUdn_2gMZwE9gur-kFqZNJ8VD8dDTIc7lpjcjMBsqO1DLmZL1RI=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113084928441512030101\">Kerbray</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.960924Z",
                                    "updated_at": "2024-06-21T12:01:01.960949Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "d09e5ee3-cb34-4824-9c9b-e82adc533144",
                                    "google_photo_id": "AUc7tXUr06ak7n8j0QblhQnUzPQ70EDJu0wXHTek-GOOQglwJZKBerR-BgiBUUZAyw1RY1CnNtw0K5TXHvZgXDx2dhBDJ1LDqrfKj-nX5c7DdkOQw3SiFwvT-Y3_hMSWldyDB8j9relgs9GIs1C0BaV58TkdQ2SF1KGhcaHdOZ1CbeD5mwYS",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGOD1-9P7Cb0xUSgrcH_iJBJosGzHu_VK2wYvDLwKmaorTDbVmcYrsc5bX2C3hjWIYo41FRSWvjVz9VMChVXg_hakq2nXLFFro=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/104143051584790495708\">Andrew Thompson</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.930902Z",
                                    "updated_at": "2024-06-21T12:01:02.930918Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                },
                                {
                                    "id": "fd1c71fe-b93f-44c9-a482-b28c78af2bc3",
                                    "google_photo_id": "AUc7tXWLy8ZH7SF7pQmkgoFAMwHFeQNs03SxxxzfCyzxcEQHnW_vkCOoap8bQtWkljgLg9R34xu-6PS2j7uZUHjSrX6nZI0mVboH6A2DZZAyaSmZnadxVgTwXFqmDmprjmaKDjQZuCi4pX2VvGwp7dwbY_2X1xZJuA5QCwdHs3EpORc_DSpi",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGhSwXaTrO3pgTXJDmyMA1wWEDZXhGNS-90Q9YnEEZx4ZZeSrYm4_ihVM5WJ9jpW_JpWq7y8Tz7jeJ0wgc95DK2T2jjnsxcPZU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/111392597415527891953\">Binh Tran</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:03.736221Z",
                                    "updated_at": "2024-06-21T12:01:03.736270Z",
                                    "google_place": "15900219-2a51-4f28-9510-f90d0b4932cc"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Adventure"
                                },
                                {
                                    "name": "Educational"
                                }
                            ],
                            "meta": {
                                "website": "https://www.zoo.org.au/melbourne",
                                "address": "Elliott Ave, Parkville VIC 3052, Australia",
                                "phone": "1300 966 784",
                                "latitude": -37.7841346,
                                "longitude": 144.9515473,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 0,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 1,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 1,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 2,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 2,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 3,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 3,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 4,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 4,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 5,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 5,
                                                "time": "1700"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 6,
                                                "time": "0900"
                                            },
                                            "close": {
                                                "day": 6,
                                                "time": "1700"
                                            }
                                        }
                                    ],
                                    "open_now": false,
                                    "weekday_text": [
                                        "Monday: 9:00 AM – 5:00 PM",
                                        "Tuesday: 9:00 AM – 5:00 PM",
                                        "Wednesday: 9:00 AM – 5:00 PM",
                                        "Thursday: 9:00 AM – 5:00 PM",
                                        "Friday: 9:00 AM – 5:00 PM",
                                        "Saturday: 9:00 AM – 5:00 PM",
                                        "Sunday: 9:00 AM – 5:00 PM"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Melbourne Zoo"
                            },
                            "min_time_spend": "3.00",
                            "max_time_spend": "5.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:03.784658Z",
                        "updated_at": "2024-06-21T12:01:03.785362Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-22bc-4bcb-9488-852ff3a143f3",
                        "rating": null,
                        "activity": {
                            "id": "56b6b676-79cc-4ff7-8dc0-eefc71022eea",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "St. Kilda Beach",
                            "description": "Known for its coastline, St. Kilda Beach is a great place to relax, watch the sunset, and go for a swim. With its long stretch of sand, it also offers a wide range of outdoor activities like windsurfing, sailing, rollerblading and kite flying, making it the perfect destination for anyone who loves the outdoors.",
                            "website": null,
                            "images": [
                                {
                                    "id": "98f84833-372c-479b-9137-e602ce28338e",
                                    "google_photo_id": "AUc7tXW5PyEXJMQosnzNVnd3hNJ0QLNf-U66T-SXSTSQBY3rdDUfqPoXflQqqjp1rEKEgidin5r4mz2a3vsnWgnfRjaM_-fEMLrGh6JxkXKvI-qZRERSJm-Cd8YMcDLEFwQDVQ0-R69rZqeWdAp6G6oCklxt6gTcIAF5UhZ3TC2TaWrJEymb",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGtyYZwtEuYVleYpgzV2lNszEbr84pPBKxlo4zqubq5kyKxD6_XenLb6hAjmd08r2wI1fZdKhRHc_dT_FFONQcX50r5GLmF_1c=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115853956143232834796\">TW Picachu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.281665Z",
                                    "updated_at": "2024-06-21T12:00:54.281675Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "7890c01e-0a15-4527-9e7e-09d942fcfcaa",
                                    "google_photo_id": "AUc7tXXs7rP_GB-vlCwsICVCioCBc0HyNJBoEx4cega_GB3sFDZC9HNQPNjuU3M_eb4y7avRw8M_hVqNifr8tBGpJom6OQ-SwYB5poUmiYZDI8QhBpVdu3OMH_jceCaQvLRc0nSqjrC5Y-mYrATII2n9kiusryA3aniWe6bOsoGXaUUy2FNQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG_srBVp-P4pjQFv9Y-X6C7Sjt44zN6wzFOFtvt4xUmZ8kP7HG_xADUKEOrjs9oJ9ry0VWtJm_gvyK21IeDkl67q8qnJys_Xpw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114269478183296619728\">Raghu Bobby</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:55.237244Z",
                                    "updated_at": "2024-06-21T12:00:55.237258Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "0d999110-e3e5-496f-aaeb-d7d8182fe6b2",
                                    "google_photo_id": "AUc7tXUOz769-mqa_T0uMBp5joOKCPciVRJZ2noI1VNjUfYlvMSXITGt7v_-8J6rnmiyb-M8lGFLdwwnFRjRcm3ta8JbQ4W-w_egZ_z8yP75SvT_louPa4obaY-ijgCkkdagFL1fIIRxGgXzPdTRzixxmKZvqUV7-co9XLhPm11_xB1cWSTQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEz9mFAoXzAZoA9BwDDHLrCAPwgpdZL9tlKvbNLIpE5va1qOlOJYz3E9jZHSiCMLKFa2cEtz5WsKAfgoZ92KgK6xY-LJHXq92g=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115433816130106971976\">Arysanti</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.621937Z",
                                    "updated_at": "2024-06-21T12:00:57.621964Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "1048e46a-3922-4e2a-a8bb-f3aba2172a8e",
                                    "google_photo_id": "AUc7tXWUR6QDf1qIEG2LByBTZWaGSLYu8F5WXEcT9UM-2QfsyqmjpOs63iTLZgw0Y6gL9_td7snbXDIrZmJPLPZigTJWBirz8qDwpxiwtBMEAxwZNJ4LAC8QzK2Dk1V3PFOz92erncBRmeDWTYeFJkjCcFUb0W8Itv9GHveecdgNYcRwZAQr",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqH7MD_CA3OjyIPW0WBEcOYofpk5WoeACFc5Ffje9tR_HHiLyhsoTe7eq4kaHRXUaNSm2YQvhbnW9tUql3GYsnoaE3dIIzpQDys=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/116120903960213642188\">Vaman Gungaram</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.653878Z",
                                    "updated_at": "2024-06-21T12:00:58.653895Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "47d9d17f-9178-46ad-8296-587344285b40",
                                    "google_photo_id": "AUc7tXWipoT32gOZGqAOotsGs1o9E6c9AGIcJCTx2z8uxfEOM1P5SN3kgsSjwA5QasPX7dinDHBSL6FPzLyIopX92OFW_h2z3f33pSiVhWyUEu-8DrIFHj8dlhd2jw851jiwcvciwrYRhrdJpQPGoo9rmNNj6t4bg0qkGCJE59MGJz8Wpz0S",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGcerFUNByYcmL5CJJ_wV27dhSmtCEORHTBmlNI4bUIRGP1vXZxrhOeKNDcxPXVcGMannFH4LZhaQNPaS9XcmgY4_CJRj6_Mw0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117245483495021826249\">Azman Ansari</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:59.614132Z",
                                    "updated_at": "2024-06-21T12:00:59.614168Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "03f15371-9c7b-45eb-ae4f-e7eeb81772c7",
                                    "google_photo_id": "AUc7tXWf4Dp4jV4gc1wR-7cUrYRQIZYnnqAjohQsCuiZtUhnwa4BPc9zPkpAYaVdrrTDCxETO1qeUMCdDHNHoeHPi0g_rwWLbCbe6lYIDTqNX0AQ6ouy2eVEdrLnn32wwTHTqRfdYYJIkPJJaLfekNfb4KytnHTb_T0RZAoz20TWXkpVkESs",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEIqmpIBqbIALko9fluf2ixk-9BPjBFR7UmzQjMqyUuurdPqg0HDe5iP_-MQ6JgQO9LsWbNGF3tkm4LnNQx-COg1Kl2815AP9I=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/118258442269396034066\">Khánh Tùng</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.432161Z",
                                    "updated_at": "2024-06-21T12:01:00.432225Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "f8cd30f2-3e34-41fa-be7c-511f62fced00",
                                    "google_photo_id": "AUc7tXVzQSRkEpa-x35_PUUooyDTPagZNSS2qxKetT-2rmsxHDtz11zhdnn10DQEukCY1XKa5ADhW5SRcNv3_uqwCg92_17P72aPFz6AJfBEqsvb74O99LG49qZK_uMwnaeCEfUGq7Yd8JbWh1T9ZXpt0UKx31bEMxNOwt3aAio214Uulx2t",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFM1tZO0HdvxR0DdCCm6ibbMH3lF9R0Gz00qfKKMLevUTU5_xy0T0nA0SSodvhjjSAjvCi4ESTAfW08vXnZSvgVzgFvyzxbGzU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106036189036000429674\">javr fotos</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.584442Z",
                                    "updated_at": "2024-06-21T12:01:01.584503Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "69a13d22-d988-45d9-aacc-1588809dd5c5",
                                    "google_photo_id": "AUc7tXUVqG-ichkk2Gn5_qPsoD2pV1MZyKda8gbyq96_bAGA0-MgFc_IPORSerESqhFr_BeGEOkbvhHqeTU-a4uT95q59kNwm7fpsEnsYWRS8I_GhTZgXsZ1_RAJZk4NpY9W-lduWWSTBIZ7s8s5nUUDqnOHANBLDWHlqR20XFb_IrBho-_N",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHGr-BtxEeOXESI8d1gDZhX52IC-XmWNhzyxBw-vnJ9x3c4MZ9AvrRMeh1P4DNjpNqYjAt_zGHe-rGyi2QptfzqRofy_OGSbVQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115433816130106971976\">Arysanti</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.546649Z",
                                    "updated_at": "2024-06-21T12:01:02.546706Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "7c05f797-f696-4d63-bc67-65fa7accb85e",
                                    "google_photo_id": "AUc7tXVP8_Y-aZyYnBJdCeJxTnDPaZ4ujNZUvv79uFy5rwWHKos8Ebhy21DVzRB1OK-C0y2AtP3a2GmW651b3Kv9MaghOZpBanNY5hDVwYg4LidwH1d9H94ChgvOiSdIM6uSbq-EwmyfEaO8QSf62nE7eFyUASejMgwTP3DUMm225KFwCcJe",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGbFKnC9NT2jXq3I9OJ7lAxDGhW8Ow8SLpFmptGCXMOFlzfZU3DTbxQGxGdtMctpMZl4bVMmlTN734QvmMThoFQTKrJW9-ylq0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110783594486394383401\">aman saluja</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:03.373940Z",
                                    "updated_at": "2024-06-21T12:01:03.373985Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                },
                                {
                                    "id": "1b255314-4bd6-46d6-a834-14814b2f69b2",
                                    "google_photo_id": "AUc7tXW5s0bbYoT-t7KGbm6pAmvxfjONm81nNS5kRHMt6zA_nfsV6v30pp6lgyoWlkHgysU1ezPMdMcjaQZeKxj7TZvA6ivoOOpqEVFsqoNWhvyiMiXobvvgOqrF1ZsRkIta6aetMzJAaMxNCSlwUwuwt_LxywKVuG01WbHqAUmlAgvYd1OA",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHur1sltOZAj0xkbkQomKI-caE7OY4BDDUppUKIC-g45IU0NbsPv8J-MvCKXAkYNg4ga00WLS604Q-GmoJyHDFJ9MOcPvnXajc=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110723671093101482452\">TINA</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:04.293998Z",
                                    "updated_at": "2024-06-21T12:01:04.294052Z",
                                    "google_place": "021a4cd1-3637-4b20-b936-711e726b9673"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Relaxation"
                                },
                                {
                                    "name": "Adventure"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "St Kilda beach, Victoria, Australia",
                                "phone": null,
                                "latitude": -37.8678765,
                                "longitude": 144.9740049,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - St. Kilda Beach"
                            },
                            "min_time_spend": "2.00",
                            "max_time_spend": "5.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:04.330558Z",
                        "updated_at": "2024-06-21T12:01:04.330606Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-ec78-468c-8462-0ec23f3a335b",
                        "rating": null,
                        "activity": {
                            "id": "0f0906c7-0360-4e80-9589-1be05ec3a5f7",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "The Dandenong Ranges",
                            "description": "A set of low mountain ranges, The Dandenong Ranges offers a mix of pretty villages, rolling hills, and natural scenery. You can enjoy a plethora of outdoor activities such as hiking, cycling, or simply relaxing in the lush landscape.",
                            "website": null,
                            "images": [
                                {
                                    "id": "d3ca2bde-be4c-427e-9a5a-7dc435221b1a",
                                    "google_photo_id": "AUc7tXXJqhxYVKuFBuVPBjlcaHT-mLWEkfBgYboLUXbDnFoGS0U8JjGuqW2OlHNurFKQWzYch-SFJ4pjx7qBLVB2c52V-N_VcQ8JEWg9OJaTH-7kvAYISJ7Edc5chXzkH8L5t74ikHBXJLYH80I-3fxXalkIkt9I1yM12dOxiISUhSogyyju",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEyaB3lt1ZELhTbwln681u4TrtnBaOInjYwnu0heDcptHhdAMiR_TWnky71OFuPf8TB_BzQLhbNK_xvDeGIYve663TukNy2-to=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:54.235365Z",
                                    "updated_at": "2024-06-21T12:00:54.235371Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "648bf713-a2e7-47a4-baae-a91c070916bd",
                                    "google_photo_id": "AUc7tXU0OJOG0MWRuNqsZHpElqx0jacKo15VQZwuTDEvz9lAPVoG9P3fPBJK7CtUl_28HQ8og4ZntjEk5uBGg4ct4F_UDVS412MtynmJsBJHFRoo4ZjRMU_RReHe_tHg0nT4XWFhHGJ0Bh05936oD5HYe00MooA9DR0PLHHg3g330wAIXMJC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEABMc2B8f9h3f_3yyyvCyAsRqmtxwf0sOkt1s71vR1NUzJd33OOREeZmr11Cn2nYqkM_fJhccBBV_1rXa6ZpACZoAyLRYtj6w=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101033523556726757641\">Paul Mac</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:56.242637Z",
                                    "updated_at": "2024-06-21T12:00:56.242654Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "866e159f-4108-41bb-9ec8-cc46614c17e3",
                                    "google_photo_id": "AUc7tXU3viUHJ82-3iV9ylztSp2hwQ7PGxKP2tqWZBhvZks0JNvHArpBEL1dItEr_s1OYOeDLYTeu02NEgN7N_o53CH2eDBgscYvakSuEKU6IAoTVxpgC4Nidt2vb-SUoVnmJ-TxbhxqoAFqSiUprDGqW8W0lRBe8QSgeRyHB33Zsa3AtjFb",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGOcvZiWKiNqNXmp_rehAbrGdlh6XMeHnGnNU0qeL5q6RjTzI_JwuXbijwoWaR0LLQDfW4hCBU82YXqwiClCI2UiwcsrqtsQvs=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:57.103409Z",
                                    "updated_at": "2024-06-21T12:00:57.103453Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "2cc72df5-dde8-4f35-98b9-6651a359b25e",
                                    "google_photo_id": "AUc7tXUSGZoYwSmUlsTiaI4vBZawn2m2q3gzPJYH3h4Yzpmw_EvkzYCrWtV60koyS9BctuLrI8XZ8MSt52dWjka_2vd2E2uZ5jXmzz4oKCooO2-eiSsMll6PvSfcsTt0HJKCZoZeFuM4Pc_9pjcnjb8p_LiwXtmBMiy2ifGkFCDwfpHJek4G",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqERo-WQgo_NSXsrZbLIdu82Gl1j5PuUVhq0YT77MK4LsAWkBXvmTdpAWHqjG3e2oVw8P46Q8tSdoUOPRSvfCfHkRggAI047etU=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115364935453021434381\">Aditya Sudarshan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:00:58.816790Z",
                                    "updated_at": "2024-06-21T12:00:58.816810Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "0d016452-b46a-4989-89f2-160bd4e4342c",
                                    "google_photo_id": "AUc7tXWzg1EMSsSK6alw1n-kAeoP6SbHvoeCh_-OhnS6fYetCeySeYxEJlfaX5IgMAEEgCqLbzWXZ9WXHH5CNHhGiKaPtkMpe7flVlyvGWDbimEOD3WWnMYrHZGSPsGhm8X3hjjtI-nWQfs0RyEI5ogZ2zg_9eA_V1RcbfmjkmUbuqJnvY5m",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGEDLQvOKRMKF8E11tAogZbYLydR6NEImq87gWCjAjFdd8pYRJPCnz122wXzePyPKdelqmmF2Jiwjc8ZX_S4I5JMBuzNsSE9hk=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/115364935453021434381\">Aditya Sudarshan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:00.135780Z",
                                    "updated_at": "2024-06-21T12:01:00.135812Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "525ea220-6a9c-4a2a-b5ed-e216894ef7b8",
                                    "google_photo_id": "AUc7tXVrcreQM6g8twLPChbXSPF2sdWLcckzNBw5hStdG7JrBljLFXy6w3tffPiLk5X1XYO-UPViPgH6WjFY-phBt-ihNKc-_I3FhWmlwm9zzNYROeMwCc1kmCJCmoBwFJAMkhA-FwqLZNYGyH9IeVD6XNrvdalFQQYjEJ-0Ia3cnRmJy3nn",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHRo3bCZ5GFNKD_9AcAU7SYsiyzOQYLJeNJbDGdgPzWybFUKtuC2php8S_uI9YrlVqFrjNoagOEQfzsW0y1Gge5b5lkoHVE7ds=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101249622435067301550\">Neha Sandhu</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:01.616103Z",
                                    "updated_at": "2024-06-21T12:01:01.616118Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "8f5859d1-4c0e-4527-8d51-902eec919287",
                                    "google_photo_id": "AUc7tXUJBrdQQLijJfpoVPefDy3xq6sntR8vI6bIfNmGtPMKNHtaUJKxwzomizwxEEhniZ2Rigz7PJXBBkEctpyvCBo6NYRR5z_hPJzY-YSpt45qbtqUK6s6ThCzayGSGUwcA4qElnitng4yCFfETo7D14fS893ZntbD-Yj9CfILjnqD0Ppe",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEokepFuwFPXfuDcF1fcBkw2oFBXO07VjL4ctxRsrx-_jeg-cI42p7gM7nqkpHukj94dHAX2WS1PP6NaCGTwbA0a49xrfC2I24=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/117861488121494647937\">Geoff</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:02.939229Z",
                                    "updated_at": "2024-06-21T12:01:02.939238Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "ac13c88c-71fe-406a-bba6-ba2b115e9a20",
                                    "google_photo_id": "AUc7tXUhvho3VrAooHQWGsG7lbZzyrSwKnr6uClra5bFgwnBFIYIV8dk9j4tXeT0d0-OVKQrpDSVQct6OYRMmvoxMSIeEPXZJisq-PFdwACKD4CSTH3doYG2NCuIfhNQryHva4hxHYUJU8W4YxJKjuWXC7bI_FwU1H1qMPn7HCwUt4HCwwrs",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGifA3rwxceINtPg2AquFgmzaaVQouWkrN6QY9Rjukc2OaumMhLRV4FdFxgUYHK5vRM33XJlWdUa7MSKQ8TsQWzgI1Lpz0_8Y4=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/104636890233270502260\">Rajeev Poduval</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:04.271044Z",
                                    "updated_at": "2024-06-21T12:01:04.271107Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "4a6b95cf-99b8-4b80-be84-f35d4c1b6617",
                                    "google_photo_id": "AUc7tXWhwimYm4vYc37NnKGOV0eV8qhGs8G7hedi4kiydCqa0mfxyButQoOq7KJKixOqpvEsow5ZjWyJ8JvcgCDllhTfc2G4EsU0Rft3HgrYsp6t-t_WfX0dQVXwu08oLF1sBq-pseQ-tF6xpZ7Sj65Ec8YvofvscpN60RPjpXUnUnC7xnqz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFdzwNUeMKL5MHqD4IsN2O_4YFT8Kt49vNKBx9uhh7Z5eaYcuRNKpUmLyHQwORgv9OMUeRBJQj6OUvrQhmVl7G3Mrlxp2SucpA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:05.472187Z",
                                    "updated_at": "2024-06-21T12:01:05.472236Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                },
                                {
                                    "id": "4b0555d0-b8a2-4420-9cec-18a70fece495",
                                    "google_photo_id": "AUc7tXUGrb6qapP70fK7tFLn9uTb12e2kXTYe0R9-hIM75Pvo4yxvrrAMiflLuB46PujaMhE9XLaX6lUxMxVOJhN35-QBmg-b8FT40XvbuKXUktrBVF_RtgGXxTN0uR9tN8c1XXZsaL22hNG8KSN5V9WyONsfT0Bxw2RdDKYuDHOo3jjzbV4",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEnPBUrP-WA1HTXPqKjySiYew1z9CKNpyMbK-4fZTwMxUp4zU9TmtYsbZpoRSNVXhx_DJZdlerRIrgLZn40NkU5GMqy6jVxVEA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100494926729889019895\">deepak</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:06.764390Z",
                                    "updated_at": "2024-06-21T12:01:06.764444Z",
                                    "google_place": "6a824305-a822-4662-a64f-561a274babec"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Hiking"
                                }
                            ],
                            "meta": {
                                "website": null,
                                "address": "Dandenong Ranges, Mount Dandenong VIC 3767, Australia",
                                "phone": null,
                                "latitude": -37.8334732,
                                "longitude": 145.3501044,
                                "opening_hours": null,
                                "activity": "Australia, Melbourne - The Dandenong Ranges"
                            },
                            "min_time_spend": "4.00",
                            "max_time_spend": "48.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:06.786316Z",
                        "updated_at": "2024-06-21T12:01:06.786353Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-7028-4438-8ebd-547273807782",
                        "rating": null,
                        "activity": {
                            "id": "63946b7d-dde8-4906-8de6-d140ca05420c",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Yarra Valley",
                            "description": "Known for being one of Australia's leading wine-making regions with a history dating back over 180 years. You can enjoy a scenic drive, outdoor picnic, hot air balloon ride, or wildlife spotting.",
                            "website": null,
                            "images": [
                                {
                                    "id": "93b57a60-4af5-4d6c-8f95-a2fe07a456e5",
                                    "google_photo_id": "AUc7tXUOOmp9Us2g0710HHyB2Adt4CSztsddhVBiXC0VzEaHKFn5TXPshrJknYDRfYd81XC6BhX1holPd4hg1HzPu1wWm9ADi9kZUoevg1PuC27qFleXNVMCAXV_HVsfyIUHVjKbLkg9qIVY-jCcNkoQoEq-vQQP_pQAQrV_Ui0Vx-nMsMp7",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFE1hrcf3cNZ_HHfmhCn3w3PWLrrz7JbGK87MhozCh00Im8aP8eLYIySmQmaCx_ZbHFCb1qKbGQiYjiwmWq1ymrNvoztnk0JAg=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.436497Z",
                                    "updated_at": "2024-06-21T12:01:11.436519Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "96ad4082-3d11-4f45-9046-8a17cc748ba1",
                                    "google_photo_id": "AUc7tXUpIRTeud-6JYHtdrcbsXczNwxMSHUFxx3DiRgSyIUuMXySxT63gVV0LnsuRBTASYtesJZE31ViGTxyLcujE4OWh5upbT5AgpTOOd70RpfpmQaD7EjP7ugLfpu5cDYjX0h7BTdo2H-auu44VHehig5LBix_Hp-_OGi4Dy9nW1CZO3vk",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqE3NEF52nyNCMFXc8VkRxiqSbIhfBDMJcK6wT6X0vUc-PBuoeq7eoh2ZB4Q2762Wfzoa3eCSP_0Btk-3LFaPvpv3p5RH9ipL14=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.333283Z",
                                    "updated_at": "2024-06-21T12:01:12.333322Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "a9bf8bc2-d740-4838-91d0-69989b69c143",
                                    "google_photo_id": "AUc7tXVNPZBp3daJhlvMMs2if5LAE9WKe8Z4izseAPSyOWygOOZ9sZoY2VfgGZdWzqGUiCsri1aplpoIheVCoVRDDsCgdjneUeCstKfrH_3Y3Yl8HxCpM4YoJcq2pfP13Sr4-vaxk-OW9gP6nstCa5oNsaLQtK1TSRYB-RZ-mqcJKEA-na--",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGJaLUgjYh3hmaDpOK_xn7uh9GBgcB2MpY1zRGNcuro133ZQNgttcM9joDegXfnl1HpMI9Ad58zPfoWZfdXNbqiO-0eq9QUDrc=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:13.222230Z",
                                    "updated_at": "2024-06-21T12:01:13.222242Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "df25322b-c7a1-40d2-8b0b-9ae48c224a43",
                                    "google_photo_id": "AUc7tXX1lsL7lVr1dCXpmmzAED8nLrIP5NWYFji3wYMUNWKJAiQf-q_nt95IyQ8T72m7pjoFANAw88cwMRCO1ARBwgiOKmtc0PlcF2VtC865smFCPhC8TX-en3MRrYZgbI65bJk9PCF210gIiHJG7JD6QgwvTRkgitqe47Ne0rczBS255ufZ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEBdlGar_Md2mJ0qyazhHvwMgDp1pBypeOnpnQwbcUEY5snDy-YqpbOA2-OCN9D-3M_0UQQKy_WE02Q2_KDHr98k-V4dwVdvm0=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.166464Z",
                                    "updated_at": "2024-06-21T12:01:14.166503Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "e2796a58-949b-48ac-abf0-e64d86fdf857",
                                    "google_photo_id": "AUc7tXX3vnL_-UC1WJqnoxXgfqBWmmA8p9BqCF_d1y9SYaKjQGW18kCwwL1wJ2DKjLBiPdwvFTIVwwl48YxSq7uD187VieBgtZhpVS9AxMAPAhzFXTZnhbky9tyGityoG659BOX5cGY4rdSwEDuCcdTEQqRXy0gIRWXtcetO5AFVbBU2UsQ-",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHe-sBQX4oqhl-EzOTFL2CrfO0HrfQkPwUHGoK0kP4xmzBNFUZpFNGGC0pjl8VSnX-Bof5LFpQ-KXzHH6a-7DHhh8flTTvppS0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.170029Z",
                                    "updated_at": "2024-06-21T12:01:15.170067Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "9b6c5e61-49e3-4cbe-9cf8-5508e058df85",
                                    "google_photo_id": "AUc7tXWhONV6WVrENqL1UrhKqdEY7v_WxBadBRoHC5BYyqj6GK-e2QIN-kCLfK0lQj4Zxn1A4REA1JXzVfWyQNtJa9eWWs-3gNRbdXzQVObzfatd8QCPWU0WtBRh4e0hpgM4tg6e_rEqtns3-arHWUVxKc1AzXgv2DjIimWuayKkGm6iURXQ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF3rSHjaIe6cXlhLhmlelSKtb26nGo6-B5YhQO09cSPPJNBhANO62j8QLcKe2DiFjQw4tbOvXxCZfXPqD-QKlpcXWx0hRifXgA=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.227252Z",
                                    "updated_at": "2024-06-21T12:01:16.227307Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "46d3b071-4414-4956-93fb-146bc72d218a",
                                    "google_photo_id": "AUc7tXUoFHUKg1Mar3M8u_KwPW2K0mDHmiGHJbR63wXq1OD3vQUsueRN98FbvclkNbMDxjWL9D6mYHOrgzZldJxW_1amGGPz_gidhaoRNUXn0Vu7LnEzhQ1GIvzd7qkTA7DIvHxYXoyfEhyujaBznRfip1TYE5F8KqW1gs8XMVOW3XVohkwJ",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEcYMsO_Ii07ErnLT3DmlR3VqwqbrMxSUnJDLxwiJBazgthJvhA8ogcIQSPL7x7LyoiJKPJ7_695BAaKk5VshPeRKxoOvC7y5w=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.261260Z",
                                    "updated_at": "2024-06-21T12:01:07.261322Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "cea4cb49-ee2e-4c75-b1a3-25bc159d7248",
                                    "google_photo_id": "AUc7tXWDBlY4ql92h0hUHIzVL9NhB5RNhh58eMfGX319y9VYpJBZHBg3M0NmIxxGV883OKkVvNprWH2SosFP87dvQFBpFMX0Lvvyi05F_vbp_IBeDB3W4H3k7V1CZEP5THIUpzFZZTpkuKzvbY6D78SBtuqMDK05BwXYgoP-EriBaZTm72P7",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFUVeBTgiWYsaybxX9jOpNuvBDT1KDq_Jvg7gf1_PqIQz4YDLjFjUn-VjkO_J-4UtfwdzLjsVXUa_0nbyCIAyhPVSjXZmgT8uE=s1600-w720",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.909035Z",
                                    "updated_at": "2024-06-21T12:01:07.909089Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "d8510741-c239-451e-b9ac-91c53c3c5ca1",
                                    "google_photo_id": "AUc7tXXOc7kMZ2BgHr64UjuNfmu5pX5gpU76OrIbHOkBZQFiZafDlYipgjwEpGJ-ikVkuJNhiQStlztLCcpmzwYMPXL2TjRQ_Zt_3H-FdbMdr9pwpL4p58W_f65lLnEZbFJJWbFaaRjy1pcJLd3epOTBDNeE6zcGBEvp9_mSRQLkufeVkvu2",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEmSgES80vhVjJUTWJXzZBhxy3iYn7opwqVWIkbx2sFv7qVOqQnhCR9yzlZxgKKaILMcjBJLhoANpWajY4Z4EQzVjmrbwFx8Cc=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:09.154881Z",
                                    "updated_at": "2024-06-21T12:01:09.154897Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                },
                                {
                                    "id": "b6b14f74-645a-4e46-8a46-d703172ef9b8",
                                    "google_photo_id": "AUc7tXX_wgkbnAIDsOLk4BPseRNvbvvw9lQD3EY_CWggS4NrJdgG_ysHzZ1ScTcAnVCr2_mnP_h5f9weIhgGlbpbfKkx7TaSH1x79CtG5HF1GBHmttcGGzW5IEju1dDk2B_KJ2wJr6K5uzUMMs76SUsn2P6N-eVao7XkErl7lCFt_Pbz-83N",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFoF6Iop8G6u4n51haEl2vpepfh_p5meC1_hKI4FzoKC1qZZOGxvnLmxVDEj1_GtotXgBrxyX4-AGh_sAV9fES7sn3uhHTmkFA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/113198436431202449432\">Winery Tour Yarra Valley</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.579417Z",
                                    "updated_at": "2024-06-21T12:01:10.579428Z",
                                    "google_place": "759fe28e-8753-462b-9124-a82fc1e40b59"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Adventure"
                                },
                                {
                                    "name": "Romantic"
                                }
                            ],
                            "meta": {
                                "website": "https://winerytouryarravalley.com.au/",
                                "address": "Unit 201/98 River Esplanade, Docklands VIC 3008, Australia",
                                "phone": "(03) 7042 3201",
                                "latitude": -37.8243796,
                                "longitude": 144.9439772,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 0,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 1,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 1,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 2,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 2,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 3,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 3,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 4,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 4,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 5,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 5,
                                                "time": "2000"
                                            }
                                        },
                                        {
                                            "open": {
                                                "day": 6,
                                                "time": "0800"
                                            },
                                            "close": {
                                                "day": 6,
                                                "time": "2000"
                                            }
                                        }
                                    ],
                                    "open_now": false,
                                    "weekday_text": [
                                        "Monday: 8:00 AM – 8:00 PM",
                                        "Tuesday: 8:00 AM – 8:00 PM",
                                        "Wednesday: 8:00 AM – 8:00 PM",
                                        "Thursday: 8:00 AM – 8:00 PM",
                                        "Friday: 8:00 AM – 8:00 PM",
                                        "Saturday: 8:00 AM – 8:00 PM",
                                        "Sunday: 8:00 AM – 8:00 PM"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Yarra Valley"
                            },
                            "min_time_spend": "5.00",
                            "max_time_spend": "8.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:16.257804Z",
                        "updated_at": "2024-06-21T12:01:16.257832Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-de86-41f9-a3ac-93d72c2137a4",
                        "rating": null,
                        "activity": {
                            "id": "aaaaaaaa-4476-4754-a732-c50b70bdeb1b",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "Melbourne Cricket Ground",
                            "description": "The birthplace of Test cricket and the Australian Rules football are now the country's largest sporting venue, with a capacity of over 100,000. It offers a chance to take in the magnitude of Australian's love for sports.",
                            "website": null,
                            "images": [
                                {
                                    "id": "aee551fb-993a-4f3d-a69e-36ab2fbea862",
                                    "google_photo_id": "AUc7tXXGr_-rCBuIcheaLNn8yRrRhNnybA3ooJKlH5A0NzEdOZXG5vYuaD-096qe1d5ka4WmzB0mzobtxHGz1f3SECFQ7hOpWtZt2J70XJc-gz43SpTMKuFbEZteyOzkZSMnTjsE_E1NUaTN4woaKjfXwMQQdnulTjtzNIQw6qpAIyE-_vsz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGRXx6E-YXY9kYn6R9hKUOgptiKxYohZJx4QQHQUsKr7th7y6zWd2xQlw6tcpW8zD7uEJTniAzdXsowuTca2RLMUkWnS-ydr4o=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/109585313555722332617\">Sajith Ekanayaka</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.657290Z",
                                    "updated_at": "2024-06-21T12:01:12.657343Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "3c334a4a-9c88-44b9-8702-a7e860fbf4df",
                                    "google_photo_id": "AUc7tXUyR0lLw-ApzW1m9v99wViqz5dswnQdxBfigF_-xYZy4JHqavloVHsIQxAro9mS41fZjTEme_8brCgtNJVr5vX_WQ1NKC-81etJ8DRk9g_bb5N2oiZOlehOI_iOgEEdnxPYGVCrf2_5V1L4co6TNTmSyWQX_45o_UjwdoVJqqCXuTgB",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqF65d0O3Bm_wkqTwz1YxXE0fO_zmDkVA8XUdRCzehMU4ZRrMZABux2XLxHEjffIl0Fu01IppOfFcD-pkqmO1NExZGpYbBfU2cw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108133620119895542367\">JANAHAN THAVENDRAN</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:13.625212Z",
                                    "updated_at": "2024-06-21T12:01:13.625230Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "f1a68c0d-4f2a-4456-ae3c-614ba2831d74",
                                    "google_photo_id": "AUc7tXUfpWuZO15Byz1FlqtsPYZY-oflyAA7tgrTroUxMbhtBtbTvAmGiJHUCZHzx86Y_CZFSkjJF4o0sOL89JSMLBcQ0DVFjRTzOR9LsaQKGvrr8m_Zf8lgq5-g48UQAhHDUmc5pxxxtYxNuLwlYgYXJn23hae3Q84MjQyhDLxkSilDoWE",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqG6uJs-2l1XyNy3EKM1HNu-1IrKMPKH47wxymtlcH3AEhzaCZSlobkU7buILLSZU2gnur8-ph_O1imv4HYdxjl0BxRCzeMLzA=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/114074237381918383377\">Emile El-azar</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:14.691676Z",
                                    "updated_at": "2024-06-21T12:01:14.691723Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "aa61b32e-eea7-4940-bbc9-4603a214b087",
                                    "google_photo_id": "AUc7tXVLbIBPaSkgyTlbTg0GD1J6z1mKxG1C2VatJQqxI5vMJYAkH7sFSIwu_7vENfrVxNBkQRG-vzUynumoLMqTXTHRApAoXHhW9A-KPlRrmJM3zOPH9RIv9WpHDQ7RZWHhKIRA22s5NxFsUNGrMBaoHwwFjCToQ79pk_T1v1_LuFajZLWn",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGrxh8FtWvrJWEiL9fFbN_PHryq4BYwGH4v8FlbeH0p8tx_pPeIZTu77DA5cyugcsEjOTNt0HgDuQ3-hf5qF117cNcpDMlmOY0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/106030202930963323410\">Jordan Verbeek</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.497877Z",
                                    "updated_at": "2024-06-21T12:01:15.497889Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "dae6f868-1f3d-42d5-b852-192f8502a26e",
                                    "google_photo_id": "AUc7tXUPaKaq4Z3A7rQf-bat_VZvVouxoNpWaIXZfsVtIdxfjVsDscENab_lUOMMzurt64K6hv2kD_OfYHIUzhdR42AbpnD5mmfjimiip-4x_A_BPkGdhYpKC8FC7f_vVixZRXvEHeoAS0Ug8je9Hizp3RAya_dlNsgXLrSfVUSA_tqTQtQu",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEQNCps3iVIYslfJPggAUcEkwZSnips0_oxWHKH5pqYlY1nFvDi1rrFfuUZdUs5KTAsUBxaKHeOYUFd37j1mPwUIkUASq2nNEI=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/103137058424422996557\">Anuj H</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.455335Z",
                                    "updated_at": "2024-06-21T12:01:16.455347Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "e56efe0c-8c52-421c-9fa5-456d6abf6b3d",
                                    "google_photo_id": "AUc7tXUZvI2eFclCmRFCKBbo87jEdEw7apl5e0FtSw7sMA35wT0vznNriObqpW_fDGB2ttKgpoOWY1H_5pOml3b9zWcoTSFJykg9d_JW5LJ55o0FjHXRMILYYUuU_Frw8HkdoMAhSG4i_PKXrximwomdAlj2HXUNuxd1V3WZyle5RFf8O5Ul",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqFMKVZl_TJ7wp48oLjZJrM5UTBen_WxKS6oZJxahLAgKAp_8DhweKZyji8KVFCkCrnXnXS1VYvcdUBbLa2DKCHPDRoTUknLZvQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/100460985845294690249\">Vignesh Ekanathan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:17.594196Z",
                                    "updated_at": "2024-06-21T12:01:17.594208Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "46547734-d961-4843-b3fc-c4c4d29ec9fe",
                                    "google_photo_id": "AUc7tXXjSSuoMF9s1wQMZ4jmJw8JqLr2E4i8W1eDZCgcFZCi8e63YCUskoc_FCOfuxJHcN460hsVnPjCHWoVfzqZDMU0qIpTVCP06h8FpMuK58_3NeN1_M0XY39nkGPAc6KUWeyFMSV4HS2RKWGUX9sr5ZUg7nsmViH2MTh9FEkSlI-N6Xh1",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHpX5t4zklu1bus7CECZpJ9Eig79rAUxwI6TEx1h897AKQ6SzMhT79YJKf91UqW9wCUtm02VCROFW-mGzWv5hv55RULUeU0Ehw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110678867753466976780\">Rod Edwards</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:08.299825Z",
                                    "updated_at": "2024-06-21T12:01:08.299885Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "06de4abb-c463-4509-9fc4-74f7a1dc078f",
                                    "google_photo_id": "AUc7tXXy6_aZHlP3n3wsF6mde-JG37Y3dxNjgGRqvpQcp8igtyXrAIpQlqh1Oz6nAI_YP4Frs-kwWNiwWS5gYzAZoaSJlEXFafqz0Wj1jqKbtZ36TQK0FE-Feyxnc5GFs8zSIenPPeKn8kKJbNTKVnUj6L5lIjaUKYQRBFwWhv59e1Q1nHOe",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGDMg_gXerNTN2NC1qz_Jcah6yuArpL6kW2gb5NA2UatRghTAxMp1pqp3Svc0C9NuzH54C3ofi0w4VqJp1LmQj3tjE4bJh6Xk0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/111353958648543476054\">Ashok Kumar Viswanathan</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:09.404786Z",
                                    "updated_at": "2024-06-21T12:01:09.404823Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "c10b9b5a-5d07-4477-a5d4-fe2a694aebf8",
                                    "google_photo_id": "AUc7tXWBhICYspJ1WYOr-7NMXl5Lqtg5RGqk9aS1Vw0LQe-Z1mWvLcvCMywLcFg6P_5OiUmCvGw0TrzsfPz6GGOMZCioQ3QozGA60_aTLEJXhSvmL0YDKeB6Xl_mxPEx_4BZY1esuYVp_BSAHqmg5FxQfBVY3_Z-6qax5b6O-2MQEo7GXG1g",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHvjezA-xfgFknc-ySMl762BiFvbBr-PT-9-FkSQ6KWfBdKhnlqcgYaXs-oTaYeQGem8j2jqrVXGJlmgFTRIykTulYtxi4sFdg=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108610446934986207776\">Anku Chapagain</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.301196Z",
                                    "updated_at": "2024-06-21T12:01:10.301211Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                },
                                {
                                    "id": "588a9063-fab4-47c7-b1b2-d3a5f5f2912d",
                                    "google_photo_id": "AUc7tXV91_392M6Nwji19ZvkLttl9hLBNe3IMokR0lfKNOnprzpWHnqtEpe2qo2zJFbtX1LgPLxQRPqK1jPLSlRHZlWsOfQBo79Sq9r7xJKrcae23FLa3eabO0-JxE_EwME8Zh2HYGALjicg5K8o-yLkgNO4wcBp5AVW1H89jjMwqEFVS6tX",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqEuXaByL-N7FHq9uCmoHthwN4hy7MUe5jOSZhn9eM9W1LFBS_-tji5PUfymXbun3tf3T49irV0bFqjLxz3qFA_OBmGlODyUOeY=s1600-w1011",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/107153973353174560682\">Souvik Dutta</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.042969Z",
                                    "updated_at": "2024-06-21T12:01:11.042984Z",
                                    "google_place": "a437eb23-ef7a-41e8-a0f3-df8fe3307c5b"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Iconic"
                                },
                                {
                                    "name": "Adventure"
                                }
                            ],
                            "meta": {
                                "website": "http://www.mcg.org.au/",
                                "address": "Brunton Ave, Richmond VIC 3002, Australia",
                                "phone": "(03) 9657 8888",
                                "latitude": -37.8199669,
                                "longitude": 144.9834493,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0000"
                                            }
                                        }
                                    ],
                                    "open_now": true,
                                    "weekday_text": [
                                        "Monday: Open 24 hours",
                                        "Tuesday: Open 24 hours",
                                        "Wednesday: Open 24 hours",
                                        "Thursday: Open 24 hours",
                                        "Friday: Open 24 hours",
                                        "Saturday: Open 24 hours",
                                        "Sunday: Open 24 hours"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Melbourne Cricket Ground"
                            },
                            "min_time_spend": "2.00",
                            "max_time_spend": "4.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:17.613572Z",
                        "updated_at": "2024-06-21T12:01:17.613582Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    },
                    {
                        "id": "aaaaaaaa-7ddf-47f9-ad12-0dd1a424678e",
                        "rating": null,
                        "activity": {
                            "id": "aaaaaaaa-3ee7-4d78-be73-91c1bd9c4fcf",
                            "city": "Melbourne",
                            "country": "Australia",
                            "title": "2 Port Campbell National Park",
                            "description": "This rugged, seaside gem hosts some of Australia's most famous and dramatic coastline including The Twelve Apostles, a series of towering limestone stacks. Don’t miss the breathtaking views from the viewing deck.",
                            "website": null,
                            "images": [
                                {
                                    "id": "09204b17-535c-467e-9e48-71a02a621234",
                                    "google_photo_id": "AUc7tXWR-g5-4eorBgs7BieR0fEfS45cOE4AZ_pbACVGxM7zcHXnn8wzp2A1f7vFINKg0sW1qrBaKw4Y75q_xz7_E20CutcfpZVJralehrXUkLFKs5GmxnWX5cwCkgRfs9hB2YKe-7PRr3w_ydEzKjwVgpHieiIXXuJw1ZIYPJHjwnlodOYz",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHMIzBHzQSpMtoFix7QS-Kti0JmRRMMLOnUyLuYNqDSmEdrzSLePlbtZfAi2bVQ0zerRpqj2zN30AIRP07YL0dNc_YCrm6ZR5s=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/118222184205323490123\">tania biswas</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:11.256777Z",
                                    "updated_at": "2024-06-21T12:01:11.256789Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "f9fdef48-7a74-43b9-ad75-ba945db2e3a6",
                                    "google_photo_id": "AUc7tXWVOE1ZWiDKkWxqspN5BASLN7Lr7GRtzjZFXEfDJESPOmM_2nGvCwm5hZiDRRjcQPRLOfYi8QlNVGpJU0t8JUOtp6XO9v2e9rmldMPu6xfwuwdWelJ0UZuvTv_pQu_fLkotMEWp-hbniXfsUmG7MowX4jYGXJRuoQ4Bb95nND5WZ6TS",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHylTeJOe9IEFzQieA-Y2wEXUXKecS6kTSMYSimrjrghbhYBVRygDEWe_aojGhUMyJDpSZKnr0X7C-KDelI_okyg-sjXts1Ej8=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/101641002085926767958\">John Pure</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:12.603599Z",
                                    "updated_at": "2024-06-21T12:01:12.603652Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "baca0a7c-341e-4e69-88c8-269041bb53da",
                                    "google_photo_id": "AUc7tXUmbYBdtID_UgDcaHebUyzNnWSw_nFVgIuzZT-JvReU89mXYWylFql-EnKs6HemGVbqFf_K2eOXxggm8Z_EUhYiJe3t3Rh5VGzTGvxEfEwii4HwJ4_355Csy8EN7V3_ZrmENMvvbIH1Lk7Vq4-hgULuL4-TZZuso6gJ2cU1HVvDpko0",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHFckrbNBJFIMnPt59czdJAVl6Pk8WI4LnFzrQ5OeNbTjjvnZdSZRgCoxMSbKkXWbEZ4yuVatZXBg-XrSpDZa7vrHwF6Q4DKSw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/105934112101653295885\">冯志坚</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:15.474371Z",
                                    "updated_at": "2024-06-21T12:01:15.474385Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "b44e5328-2b3f-45a6-9ab6-5daacd85459f",
                                    "google_photo_id": "AUc7tXV0bY6idlGgPX9wss7_H6DkJD-P6hVDI5P_cgd4TWHzzD3pn_Z-scmcuMUdaBwob0eaLqWtYvEyS4SiO0jsPUAvtP5cfISXdMQ0Pzpju1H2DauWDfou1wIbdwmTT1I-C5oszUrwA9Fh82tDR0PMMEI03hD0UnOxHXyw2m5xDDHBjgaC",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHAmuF4MZeYXYTj39sj1W2A7tEjV7e4OFb-Cmn_lF6Z-k5xnAM0bYZ-Mo4ht_20W1vIEJp6jiznuQ5oKmox13qFGEpkdF4Viu0=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/104856952460086182884\">Greig Taylor</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:16.905821Z",
                                    "updated_at": "2024-06-21T12:01:16.905857Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "faf795d5-bc3d-441f-96f8-ed5f7c14a8c9",
                                    "google_photo_id": "AUc7tXWuI3MtmgXKjOQYgB7ZJgTaHqYBdmwLqadfltGyYOCJGtXem8tfnAVrvAUZO-FxlZYheIVq79IBZjqN7EOp0FH0It1-1ybD-2e5je6DglmN4kBD2WRxuw77DPr-pndDHjFg5sOYewYndWrDc1LRVl-YaBpmlHaRbnM_jSL_ZGntkt1v",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqErEcWSx-SmebwHUxWyCsA36zFRdIP8OGnmp64HhLDSap8TZpkdedO0JTVXyn8s5QuCBbDpp80GniXjS6X_RoIaFnh1MSKQd1I=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/107559583361667944452\">Hans Haist</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:18.597711Z",
                                    "updated_at": "2024-06-21T12:01:18.597753Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "d99a46a6-7a63-47f0-bc28-03f57de021ea",
                                    "google_photo_id": "AUc7tXXVXqiMaaYAoINRZ9s6qacx9Vw7BHzJiTKhw9Lme0b94W0ovLuXG3fl860lGVSbO7p39CfHcldWdPwGqZJ0BHElpN0BAed10GBEeFNRR5w-Qy-1lYNkjXBO3PEuBPm_xttVuo-cyuZ_0IY0SbAsrj6rPBg9PoEclDgeCTlzy9Z7u7ET",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGoIvAabLjZ3dDXQw7e_Lj4J3EkTHQ5NZsupglcRTehN0ZVqAr-H4WP_4_1QO1Z1n0Su8Pbvqaw8G8lcg71pgjWeJ2tmakXc28=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/110969954720451426123\">Craig O&#39;Keefe</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:19.963391Z",
                                    "updated_at": "2024-06-21T12:01:19.963474Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "e5fa4031-6a60-4a5b-82b0-3ac483ddd982",
                                    "google_photo_id": "AUc7tXU9LDDhyyz2MpgCVGoFwbO6khUMsf5RziXrFLC1CzwPdE5Tzp5vUlsPcqHIzZMllIHCUaG2v_l6z_8aHNfun6O4wDIwBYtzdGePH1StqRRZTn6pxPjREHXFJE6UdZ7kMp_AkmR_XRStj29ts32W7eHryeof5vDjSWcEQuxlq0GpIulk",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGN8KtO358DkokAp2wtDiuF3vfKRWBjufRvmZ0cUSz7dWDYckA2i0zM2DZp5Gkpc_GlmaWVdhNz-z0aSyZeSjPV7QONAsukcBw=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/109240572640698745312\">Port Campbell National Park</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:06.539516Z",
                                    "updated_at": "2024-06-21T12:01:06.539567Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "6829d2aa-e3a3-47d3-8a0a-8c1a3d905a1d",
                                    "google_photo_id": "AUc7tXVr-Gqanj7X3srgcloun1rXYhgGKVlSrAvvhlTM34JbDLgeIMcCfKfC8NKK-yrdtRFtEPyFg8QQ-p43IDGTHEqW70rZH_8gM8ouoaaxkaaHfnYo_GYc6grbbUaNBOWptotnbemeGIUZSn3PCIAqs3E7Ht1VCsZ5EyYoze92STX7Y-7W",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqGTPHQpD0cYUjxoCXqT6LumGPZp8MD4r6fQD4KCmDTRFL_R11iu9M_Y2oBVITh4ikYEcEoHhw3dI1Py1gfurSPt-vEqIVJ0U98=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108900661979460289583\">Ekramul Islam Tuhin</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:07.601130Z",
                                    "updated_at": "2024-06-21T12:01:07.601146Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "44e4535f-e743-4063-be0c-0a561049720a",
                                    "google_photo_id": "AUc7tXUQNoD7R1qRu7Fg1eJpAT_rEsU-CckCeqBTKj6mx0i9vxPMp1K4V28SHJluREx59sOzlbC5d-20zCV8myqXXNLMSzcsd-ItygjG5qJEaK7tHacA98vNIfn6sEyChkfCkbMgW3j4OR4Mh4NEnEKUsMlmgINQGGa-KeE5j-aBO2FO9VyA",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqE6tidAr6Ra44JjHnGOVJEzF6XbSxQiJmeupDUlzLwDbkcqBMSsrXdE-Trb8P4FU5LNvJ-uuYCMiKSA8MbMdiATh0WdNLv8LFs=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/112831475068636997516\">Robert Day</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:08.848808Z",
                                    "updated_at": "2024-06-21T12:01:08.848871Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                },
                                {
                                    "id": "dcf76ebe-fa6e-4a46-b82b-f8a367bee093",
                                    "google_photo_id": "AUc7tXVJrjC3YCidO2NOXVjmQXmzClFuRSmufs-lvZ4lKGNGVA3xMFPpSYdGY-PwY1gVfZo3He2xitdMA4ohBuvq3gsSKk8we0NVtWYM0KKlqJQGIybWLPe9-bB4nz-SwkeR7HakrTgO8whH69pj-2ouVJgT-bEhmQV2WTAyY7q3z4AjUxYE",
                                    "photo_url": "https://lh3.googleusercontent.com/places/ANXAkqHwPMu1m3QMApZ-92KRFG4Bi9qFY8v2na6Xj8PUDBpvXKoT6k64x7Nx1WcGa1rggq6wp6_6mlJ3PMmgureUSqwwbAlmtp6g9VQ=s1600-w1024",
                                    "photo_attribution": [
                                        "<a href=\"https://maps.google.com/maps/contrib/108921529119856628116\">John Zhang</a>"
                                    ],
                                    "created_at": "2024-06-21T12:01:10.150544Z",
                                    "updated_at": "2024-06-21T12:01:10.150560Z",
                                    "google_place": "b22cf741-193f-41a9-8d81-35602d17c648"
                                }
                            ],
                            "tags": [
                                {
                                    "name": "Outdoor"
                                },
                                {
                                    "name": "Nature"
                                },
                                {
                                    "name": "Picturesque"
                                }
                            ],
                            "meta": {
                                "website": "https://www.parks.vic.gov.au/places-to-see/parks/port-campbell-national-park?utm_source=google&utm_medium=maps&utm_campaign=GMB-2020",
                                "address": "Great Ocean Rd, Port Campbell VIC 3269, Australia",
                                "phone": "13 19 63",
                                "latitude": -38.66209229999999,
                                "longitude": 143.1050308,
                                "opening_hours": {
                                    "periods": [
                                        {
                                            "open": {
                                                "day": 0,
                                                "time": "0000"
                                            }
                                        }
                                    ],
                                    "open_now": true,
                                    "weekday_text": [
                                        "Monday: Open 24 hours",
                                        "Tuesday: Open 24 hours",
                                        "Wednesday: Open 24 hours",
                                        "Thursday: Open 24 hours",
                                        "Friday: Open 24 hours",
                                        "Saturday: Open 24 hours",
                                        "Sunday: Open 24 hours"
                                    ]
                                },
                                "activity": "Australia, Melbourne - Port Campbell National Park"
                            },
                            "min_time_spend": "3.00",
                            "max_time_spend": "5.00"
                        },
                        "status": 0,
                        "created_at": "2024-06-21T12:01:20.003873Z",
                        "updated_at": "2024-06-21T12:01:20.003920Z",
                        "trip": "bd9a913d-4ed6-43c7-84d6-3f8047832b60"
                    }
                ]
            })
        )
    }),


    // Unconfirmed if still in use
    rest.get('http://localhost:8000/api/activity/:id', (req, res, ctx) => {
        // Simulate the google places sdk along with other data
        // https://developers.google.com/maps/documentation/places/web-service/details
        return res(
            ctx.status(200),
            ctx.json({
                "id": req.params.id,
                "business_status": "OPERATIONAL",
                "formatted_address": "48 Pirrama Rd, Pyrmont NSW 2009, Australia",
                "geometry": {
                    "location": { "lat": -33.866489, "lng": 151.1958561 },
                },
                "opening_hours":
                {
                    "open_now": false,
                    "periods":
                        [
                        {
                            "close": { "day": 1, "time": "1700" },
                            "open": { "day": 1, "time": "0900" },
                        },
                        {
                            "close": { "day": 2, "time": "1700" },
                            "open": { "day": 2, "time": "0900" },
                        },
                        {
                            "close": { "day": 3, "time": "1700" },
                            "open": { "day": 3, "time": "0900" },
                        },
                        {
                            "close": { "day": 4, "time": "1700" },
                            "open": { "day": 4, "time": "0900" },
                        },
                        {
                            "close": { "day": 5, "time": "1700" },
                            "open": { "day": 5, "time": "0900" },
                        },
                        ],
                    "weekday_text": [
                        "Monday: 9:00 AM – 5:00 PM",
                        "Tuesday: 9:00 AM – 5:00 PM",
                        "Wednesday: 9:00 AM – 5:00 PM",
                        "Thursday: 9:00 AM – 5:00 PM",
                        "Friday: 9:00 AM – 5:00 PM",
                        "Saturday: Closed",
                        "Sunday: Closed",
                    ],
                },
                "google_place_id": "ChIJN1t_tDeuEmsRUsoyG83frY4",
                "website": "http://explra.com/",
            })
        )
    }),

    rest.get('http://localhost:8000/api/trip-intro/*', (req, res, ctx) => {
        const destination = req.url.searchParams.get('destination')
        const { country, city } = destination.split(",")
        const now = new Date().getTime()
        const hourMs = 3600000
        return res(
            ctx.status(200),
            ctx.json({
                "date_from": dayjs(now).format("YYYY-MM-DD"),
                "date_to": dayjs(now + (hourMs*24*7).format("YYYY-MM-DD")),
                "country": "Australia",
                "city": "Melbourne",
                "intro": `Welcome to ${city}, ${country}, a vibrant city pulsating with life and culture that'll leave you wanting more. Renowned for its arresting street art, innovative architecture, and dynamic multiculturalism, Melbourne is a metropolitan gem that offers a unique blend of the old and the new. As Australia's cultural hub, it is packed with galleries, theaters, cinemas, and museums that showcase the country's rich artistic heritage.\n\nMelbourne is also a sensory delight for food and coffee lovers with gastronomic delights that reflect its multicultural fabric. Delve into its hidden laneways, world-class wine regions, or hot balloon rides over the city at sunrise, Melbourne is for dreamers and adventurers alike. Every nook and corner of this city tells a story, waving a tale of its rich history, remarkable present, and an exciting future. Melbourne awaits to enthral you with its charm and entertain you with its quirks.`,
                "hero_image": "https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=912"
            }),
        )
    }),

    rest.post('http://localhost:8000/api/trip-activities/:id/replace', (req, res, ctx) => {
        // const destination = req.url.searchParams.get('destination')
        // const from = req.url.searchParams.get('from')
        // const to = req.url.searchParams.get('to')
        // Replace an activity with something else.
        const id = req.params.id
        return res(
            ctx.status(200),
            ctx.json({
                "activities": [
                    {
                        "id": 11,
                        "heading": "Royal Botanic Gardens",
                        "description": "A beautiful garden with stunning landscapes, serene paths, and exotic plants from around the world. You can enjoy a picnic or take a leisurely stroll while admiring the scenic views."
                    },
                    {
                        "id": 12,
                        "heading": "St. Kilda Beach",
                        "description": "One of the most popular beaches in Melbourne, known for its lively atmosphere, sandy shoreline, and vibrant sunsets. You can relax on the beach, go for a swim, or enjoy a meal at one of the beachfront restaurants."
                    },
                    {
                        "id": 13,
                        "heading": "Fitzroy Gardens",
                        "description": "A historic garden featuring charming walkways, stunning floral displays, and Captain Cook's Cottage. The garden is perfect for a peaceful escape or a picnic with friends."
                    },
                    {
                        "id": 14,
                        "heading": "Melbourne Museum",
                        "description": "A fascinating place to explore Victoria's history, art, and cultural heritage. From ancient artifacts to interactive exhibits, the Melbourne Museum offers something for everyone."
                    },
                    {
                        "id": 15,
                        "heading": "State Library of Victoria",
                        "description": "An architectural masterpiece with millions of books, stunning reading rooms, and exhibitions that showcase Victoria's rich literary history. It's a perfect spot for book lovers and history enthusiasts."
                    },
                    {
                        "id": 16,
                        "heading": "National Sports Museum",
                        "description": "Located at the Melbourne Cricket Ground, this museum celebrates Australia's sporting culture and showcases memorabilia from various sports. It's a must-visit for sports fans."
                    },
                    {
                        "id": 17,
                        "heading": "Yarra River Cruise",
                        "description": "Explore Melbourne from a different perspective by taking a cruise along the scenic Yarra River. Enjoy breathtaking views of the city skyline, lush parks, and iconic landmarks."
                    },
                    {
                        "id": 18,
                        "heading": "Melbourne Star Observation Wheel",
                        "description": "Get a bird's-eye view of Melbourne from this giant Ferris wheel. It offers panoramic vistas of the city and is particularly stunning at night when the city lights up."
                    },
                    {
                        "id": 19,
                        "heading": "Chinatown",
                        "description": "Immerse yourself in the vibrant culture and flavors of Melbourne's Chinatown. Explore the colorful streets, sample delicious Asian cuisine, and browse through unique shops."
                    },
                    {
                        "id": 20,
                        "heading": "National Gallery of Victoria International",
                        "description": ": This gallery houses an impressive collection of international art, including works from renowned artists. It's a haven for art lovers and those seeking inspiration."
                    }
                ]
            })
        )
    }),

    rest.patch('http://localhost:8000/api/itinerary/:id/activity/:activityId/rate', (req, res, ctx) => {
        // Rate an activity in an itinerary
        const activityId = req.params.activityId
        const rating = req.body.rating
        return res(
            ctx.status(200),
            ctx.json({
                "id": activityId,
                "heading": "Patched Activity",
                "description": "A beautiful garden with stunning landscapes, serene paths, and exotic plants from around the world. You can enjoy a picnic or take a leisurely stroll while admiring the scenic views.",
                "rating": rating
            })
        )
    }),

    rest.post('http://localhost:8000/api/trips/:tripId/similar', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    "id": "740d6c80-dd7d-4815-9296-bc8161755b30",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "National Gallery of Victoria",
                    "description": "The oldest and largest art gallery in Australia, showcasing an extensive collection of artworks from around the world, including indigenous and contemporary pieces.",
                    "website": null
                },
                {
                    "id": "e9526c75-f08a-486d-8f93-3490eb71a890",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Melbourne Museum",
                    "description": "A fascinating museum exploring natural history, science, and culture. Highlights include the Bunjilaka Aboriginal Cultural Centre and the IMAX theatre.",
                    "website": null
                },
                {
                    "id": "f7dd1280-288b-4840-9796-cc69c33d5641",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Eureka Skydeck",
                    "description": "Experience breathtaking views of Melbourne from the Eureka Skydeck, located on the 88th floor of the Eureka Tower. Enjoy panoramic views of the city and even step onto \"The Edge\" for a thrilling glass-bottomed experience.",
                    "website": null
                },
                {
                    "id": "21d4acb8-4ea0-4345-8903-046b5a32c8d4",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Melbourne Star Observation Wheel",
                    "description": "The Melbourne Star Observation Wheel offers stunning 360-degree views of the city from spacious cabins. Take a ride at sunset for a magical experience and see Melbourne come alive with twinkling lights.",
                    "website": null
                },
                {
                    "id": "65806b25-e4c0-4613-8ad7-b77735188f24",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "State Library Victoria",
                    "description": "A stunning heritage-listed library housing a vast collection of books, manuscripts, and historic documents. Don't miss the beautiful reading room and exhibitions.",
                    "website": null
                },
                {
                    "id": "7cdd048e-8b9e-4a5c-a99a-c6b232da852e",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "St. Kilda Beach",
                    "description": "Head to St. Kilda Beach for a dose of sun, sand, and sea. You can relax at the beach or explore the vibrant seaside precinct, complete with a lively promenade, trendy cafes, and the iconic Luna Park.",
                    "website": null
                },
                {
                    "id": "f3a48932-3085-4d22-8748-0bca9be8ae89",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Block Arcade",
                    "description": "Step back in time as you stroll through the beautifully preserved Block Arcade, known for its stunning architecture, boutique shops, and cozy cafes.",
                    "website": null
                },
                {
                    "id": "f5bb224a-ca8a-48a7-ac8f-b66d31537297",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Melbourne Street Art",
                    "description": "Discover the vibrant street art scene in neighborhoods like Hosier Lane and Fitzroy, where you'll find amazing murals and graffiti.",
                    "website": null
                },
                {
                    "id": "a331bf30-9472-4c8e-81a0-ad397f0655ed",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Old Melbourne Gaol",
                    "description": "Step back in time at the Old Melbourne Gaol, a former prison that once held some of Australia's most notorious criminals. Take a guided tour to learn about the grim history of the gaol and the stories of its inmates.",
                    "website": null
                },
                {
                    "id": "4bc43cc5-9f43-4f2c-9227-96eb89c88661",
                    "country": "Australia",
                    "city": "Melbourne",
                    "title": "Royal Exhibition Building",
                    "description": "The Royal Exhibition Building is a World Heritage-listed site and a symbol of Melbourne's rich cultural history. Take a guided tour to admire the stunning architecture and learn about the building's significance in hosting international exhibitions.",
                    "website": null
                }
            ])
        )
    }),

    rest.get('http://localhost:8000/api/trip-activities/:id', (req, res, ctx) => {
        const id = req.params.id
        return res(
            ctx.status(200),
            ctx.json({
                "id": id,
                "description": "This is more information on the activity you have requested."
            }),
        )
    }),

    rest.get('http://localhost:8000/api/research', (req, res, ctx) => {
        const topic = req.url.searchParams.get('topic')
        const destination = req.url.searchParams.get('destination')
        // const to = req.url.searchParams.get('to')
        return res(
            ctx.status(200),
            ctx.json({
                "items": [
                    {
                        "id": 1,
                        "title": "My blog",
                        "link": "http://explra.com/blog",
                        "related_to": "something in the blog",
                        "summary": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam nulla amet voluptatum sit rerum, atque, quo culpa ut necessitatibus eius suscipit eum accusamus, aperiam voluptas exercitationem facere aliquid fuga. Sint.",
                        "image": "https://images.unsplash.com/photo-1545044846-351ba102b6d5?auto=format&fit=crop&w=300",
                        "date_published": "2023/02/01",
                    },
                    {
                        "id": 2,
                        "title": "My blog 2",
                        "link": "http://explra.com/blog",
                        "related_to": "something in the blog",
                        "summary": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam nulla amet voluptatum sit rerum, atque, quo culpa ut necessitatibus eius suscipit eum accusamus, aperiam voluptas exercitationem facere aliquid fuga. Sint.",
                        "image": "https://images.unsplash.com/photo-1545044846-351ba102b6d5?auto=format&fit=crop&w=300",
                        "date_published": "2023/02/01",
                    },
                    {
                        "id": 3,
                        "title": "My blog 3",
                        "link": "http://explra.com/blog",
                        "related_to": "something in the blog",
                        "summary": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam nulla amet voluptatum sit rerum, atque, quo culpa ut necessitatibus eius suscipit eum accusamus, aperiam voluptas exercitationem facere aliquid fuga. Sint.",
                        "image": "https://images.unsplash.com/photo-1545044846-351ba102b6d5?auto=format&fit=crop&w=300",
                        "date_published": "2023/02/01",
                    },
                    {
                        "id": 4,
                        "title": "My blog 4",
                        "link": "http://explra.com/blog",
                        "related_to": "something in the blog",
                        "summary": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam nulla amet voluptatum sit rerum, atque, quo culpa ut necessitatibus eius suscipit eum accusamus, aperiam voluptas exercitationem facere aliquid fuga. Sint.",
                        "image": "https://images.unsplash.com/photo-1545044846-351ba102b6d5?auto=format&fit=crop&w=300",
                        "date_published": "2023/02/01",
                    },
                ]
            })
        )
    }),

    rest.get('http://localhost:8000/api/itinerary', (req, res, ctx) => {
        // const destination = req.url.searchParams.get('destination')
        // const from = req.url.searchParams.get('from')
        // const to = req.url.searchParams.get('to')
        return res(
            ctx.status(200),
            ctx.json({
                "blog_posts": [
                    {
                        "id": 1,
                        "title": "My blog",
                        "link": "http://explra.com/blog",
                        "related_to": "something in the blog"
                    }
                ]
            })
        )
    }),

    rest.get('http://localhost:8000/api/trips/:tripId/schedule', (req, res, ctx) => {
        const now = new Date().getTime()
        const hourMs = 3600000
        return res(
            ctx.status(200),
            ctx.json(
                {
                    "id": 1,
                    "start_date": 1702771200.0,
                    "end_date": 1703376000.0,
                    "activities": [
                        {
                            "id": 2,
                            "heading": "Royal Botanic Gardens Melbourne",
                            "suggested_start_date": now,
                            "suggested_end_date": now+(2*hourMs), // 2 hours +
                            "type": "activity",
                            "recommended_time_spent": 1,
                            "description": "Royal Botanic Gardens Victoria are botanic gardens across two sites–Melbourne and Cranbourne. Melbourne Gardens was founded in 1846 when land was reserved on the south side of the Yarra River for a new botanic garden. It extends across 38 hectares that slope to the river with trees, garden beds, lakes and lawns.",
                            "booking_link": "https://explra.com",
                            "cost": 15,
                            "cluster": 1,
                        },
                        {
                            "id": 3,
                            "heading": "Melbourne Cricket Ground (MCG)",
                            "suggested_start_date": now+(3*hourMs), // 24 hours +
                            "suggested_end_date": now+(5*hourMs),
                            "type": "activity",
                            "recommended_time_spent": 3,
                            "description": "The Melbourne Cricket Ground (MCG), also known locally as The 'G,[5] is an Australian sports stadium located in Yarra Park, Melbourne, Victoria.[6] Founded and managed by the Melbourne Cricket Club, it is the largest stadium in the Southern Hemisphere, the 11th largest globally, and the second-largest cricket ground by capacity, after the Narendra Modi Stadium. The MCG is within walking distance of the city centre and is served by Richmond and Jolimont railway stations, as well as the route 70, route 75, and route 48 trams. It is adjacent to Melbourne Park and is part of the Melbourne Sports and Entertainment Precinct.",
                            "booking_link": "https://explra.com",
                            "cost": 0,
                            "cluster": 1,
                        },
                        {
                            "id": 4,
                            "heading": "Travel",
                            "suggested_start_date": now+(30*hourMs), 
                            "suggested_end_date": now+(32*hourMs),
                            "type": "travel",
                            "recommended_time_spent": 2,
                            "description": "Travel time",
                            "booking_link": "https://explra.com",
                            "cost": 55,
                            "cluster": 3,
                        },
                        {
                            "id": 4,
                            "heading": "Day off",
                            "suggested_start_date": now+(36*hourMs), 
                            "suggested_end_date": now+(58*hourMs),
                            "type": "day_off",
                            "recommended_time_spent": 3,
                            "description": "A beautiful garden with stunning landscapes, serene paths, and exotic plants from around the world. You can enjoy a picnic or take a leisurely stroll while admiring the scenic views.",
                            "booking_link": "https://explra.com",
                            "cost": 20,
                            "cluster": 3,
                        },
                    ]
                }
            )
        )
    }),

    //   rest.get('/trip-planner', (req, res, ctx) => {
    //     const destination = req.url.searchParams.get('destination')
    //     const from = req.url.searchParams.get('from')
    //     const to = req.url.searchParams.get('to')
    //     return res(
    //         ctx.status(200),
    //         ctx.json({
    //             destination: `${destination}`,
    //             intro: `Welcome to ${destination} – a vibrant and culturally diverse city nestled on the southeastern coast of the country. Known for its stunning landscapes, thriving arts scene, and world-class cuisine, Melbourne offers an unforgettable experience to both locals and visitors alike. \n As the capital of the state of Victoria, Melbourne has earned its reputation as the cultural heart of Australia. It boasts a bustling city center lined with iconic Victorian-era architecture, complemented by sprawling parks and gardens that provide a breath of fresh air in this cosmopolitan metropolis. \n The city\'s vibrant arts and music scene is evident in its numerous galleries, theaters, and live music venues, making it a haven for artists and creatives. Melbourne is also renowned for its exceptional culinary landscape, featuring restaurants, cafes, and food markets that cater to every palate, offering a gastronomic journey like no other. \nWith its unique blend of modernity and heritage, Melbourne invites you to discover the charm and allure that have made it a top destination for culture, cuisine, and creativity.`,
    //             heroImage: 'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    //             activities: [
    //                 {
    //                     id: 1,
    //                     heading: 'Great Ocean Road',
    //                     content: 'Take a scenic drive along this iconic road and witness stunning views of the coastal landscapes, cliffs, and the famous Twelve Apostles.'
    //                 },
    //                 {
    //                     id: 2,
    //                     heading: 'Something else',
    //                     content: 'Take a scenic drive along this iconic road and witness stunning views of the coastal landscapes, cliffs, and the famous Twelve Apostles.'
    //                 },
    //                 {
    //                     id: 3,
    //                     heading: 'Great Ocean Road',
    //                     content: 'Take a scenic drive along this iconic road and witness stunning views of the coastal landscapes, cliffs, and the famous Twelve Apostles.'
    //                 },
    //             ],
    //             research: [
    //                 {
    //                     id: 1,
    //                     image: 'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    //                     heading: 'Some blog',
    //                     content: 'Something said',
    //                 },
    //                 {
    //                     id: 2,
    //                     image: 'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    //                     heading: 'A new blog',
    //                     content: 'California',
    //                 },
    //                 {
    //                     id: 3,
    //                     image: 'https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318',
    //                     heading: 'Boom town',
    //                     content: 'Melbourne research co',
    //                 },
    //             ]
    //         }),
    //     )
    //   }),
]