import ExplraButton from "components/button"
import ExplraFeatures from "components/explraFeatures"
import { useDocumentTitle } from "services/setDocumentTitle"


export default function ItineraryGenerationPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Explra for Itinerary Generation')
    return (
        <div>
            <div className="">
                <div className="container px-4 mx-auto max-w-4xl">
                    <div className="text-center">
                        <h1 className="text-xl mb-8 font-semibold font-heading text-blue-text">
                        How Explra Uses Artificial Intelligence to Generate Personalized Travel Itineraries
                        </h1>
                        <p className="text-left font-light">
                        <img src="/assets/images/mocks/mockup-home-combined.png" className="m-4 max-w-md float-left"/>
                        Are you planning a trip and don't know where to start? With so many places to visit, it can be overwhelming. Luckily, there is Explra, an app that uses artificial intelligence (AI) to generate personalized travel itineraries based on your interests and preferences. 
                        <br />
                        Explra, the AI-powered travel app, creates curated travel itineraries for users through its unique Itinerary Generation feature.
                        <br /><br />
                        The Itinerary Generation feature works by analyzing user data such as previous travel profile and preferred activities to create customized travel recommendations. Explra's AI algorithm then generates a detailed itinerary that includes all the recommended places to visit and things to do based on your preferences.
                        <br /><br />
                        To start creating an itinerary using Explra, simply open <a href="https://explra.com">Explra.com</a> and enter a destination. You can choose from various categories such as 'Romantic Attractions', 'Outdoor Activities', 'Child Friendly', and more to customize your trip. The app then provides you with a list of recommended places and activities that match your preferences.
                        <br /><br />
                        Explra's Itinerary Generation feature is just one way the app uses AI to enhance travel experiences. The app also features personalized recommendations and offline navigation capabilities. Whether you're planning a solo trip or a group vacation, Explra has got you covered with its innovative AI technology.
                        <br /><br />
                        Overall, the Itinerary Generation feature is an excellent tool for anyone looking to create personalized travel itineraries based on their preferences and interests. With Explra's AI-powered technology, you can rest assured that your trip will be tailored to your needs and preferences, making it a memorable experience.
                        </p>    
                        <div className="mt-6">
                            <ExplraButton
                                href='/trip-planner'
                                text="Generate my itinerary"
                                style="primary"
                                size={4} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-16 pt-12 bg-[#F2EAF5]">
                <div className="container px-4 mx-auto">
                    <ExplraFeatures />    
                </div>
            </div> 
        </div>
    )
}