export default function Header() {
    return (
        <header className="fixed bg-white z-50 opacity-95">
            <section className="relative">
                <nav className="flex w-screen justify-between items-center py-8 px-4 xl:px-10">
                    <ul className="m-auto flex items-center space-x-6 ">
                        <li className="border rounded-xl py-2 shadow-xl bg-white">
                            <a className="hover:underline text-sm font-semibold px-4" href="/">Home</a>
                        </li>
                        <li className="border rounded-xl py-2 bg-white">
                            <a className="hover:underline text-sm font-semibold px-4" href="/itineraries/">Itineraries</a>
                        </li>
                        {/* <li className="hidden sm:inline-block">
                            <div className="">
                                <a className="hover:underline text-sm font-semibold" href="/">
                                <img className="h-7" src="/assets/logo/logo-explra-single.svg" alt="Explra Logo" width="auto" />
                            </a>
                            </div>
                        </li> */}
                        <li className="hidden sm:inline-block border rounded-xl py-2 bg-white">
                            <a className="hover:underline text-sm font-semibold px-4" href="/trip-planner/">Trip Planner</a>
                        </li>
                        <li className="hidden sm:inline-block border rounded-xl py-2 bg-white">
                            <a className="hover:underline text-sm font-semibold px-4" href="/discover/">Discover</a>
                        </li>
                        <li className="border rounded-xl py-2 bg-white">
                            <a className="hover:underline text-sm font-semibold px-4" href="/blog/">Blog</a>
                        </li>
                    </ul>
                </nav>

                <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                    <nav className="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto">
                        <div className="flex items-center mb-8">
                            <a className="mr-auto text-lg font-semibold leading-none" href="#">
                                <img className="h-7" src="/assets/logo/logo-explra-red.svg" alt="" width="auto" />
                            </a>
                            <button className="navbar-close">
                                <svg className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <div>
                            <ul>
                                <li className="mb-1">
                                    <a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="/itineraries/">Itineraries</a>
                                </li>
                                <li className="mb-1">
                                    <a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="/trip-planner/">Trip Planner</a>
                                </li>
                                <li className="mb-1">
                                    <a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="/discover/">Discover</a>
                                </li>
                                <li className="mb-1">
                                    <a className="block p-4 text-sm font-medium text-gray-900 hover:bg-gray-50 rounded" href="/blog/">Blog</a>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-auto">

                            <p className="mt-6 mb-4 text-sm text-center text-gray-500">
                                <span>© 2023 All rights reserved.</span>
                            </p>
                        </div>
                    </nav>
                </div>
            </section>
        </header>
    )
}