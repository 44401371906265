import { MagnifyingGlassIcon, MagnifyingGlassCircleIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/20/solid'

export default function Popular() {
    return (
        <div className="my-10 mx-auto max-w-4xl">
            <div className='text-center mb-8'>
                <h2 className="text-2xl font-regular ">Popular Searches</h2>
                <p className="text-gray-600 text-base leading-7">
                    <MagnifyingGlassPlusIcon className="inline-block h-5 w-5 text-indigo-600" aria-hidden="true" />
                    View popular destinations
                </p>
                {/* <img className="inline w-6 h-6 -mt-4" src='/assets/images/popular-bg.png' /> */}
                {/* <span className="bg-no-repeat bg-right-top bg-contain block w-4 h-4" style={{ backgroundImage: `url('/assets/images/popular-bg.png')` }}></span> */}
                
            </div>
            
            <div className="flex space-x-4 max-w-4xl flex-wrap justify-center">
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/dac66e88-0180-4e3f-a35e-79a60c54db0d/plan/schedule">London</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/921fa0e2-5a3d-4bae-b252-477e11b20786/plan/schedule">Paris</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/b6f5fde8-7dc8-49f4-98fd-2e0e01a9eca4/plan/schedule">Berlin</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/fa59aa56-98d8-4a94-9e76-8112535eb30d/plan/schedule">Sydney</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/7c617470-3a08-49a6-b708-bccea82b968a/plan/schedule">New York</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/19cd25ca-c0d9-4761-9d66-32eae9676db2/plan/schedule">Amsterdam</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/c9a14fbb-44d2-4fa6-9c74-f5f036e69dbe/plan/schedule">Granada</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/1804a83d-dd98-4e11-80a3-847a0f8be626/plan/schedule">Rome</a>
                <a className="my-2 rounded-xl border-coolGray-200 border px-8 py-2 inline-block hover:bg-coolGray-50" 
                    href="https://explra.com/trip-plan/ea37663f-3182-4ca5-817d-a0cb2eb6f849/plan/schedule">Toronto</a>
            </div>
        </div>
    )
}