import { useDocumentTitle } from "services/setDocumentTitle";

export default function PrivacyPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Privacy Policy')
    return (
        <section className="py-20">
            <div className="container px-4 mx-auto max-w-4xl">
                <div className="text-center">
                    <h2 className="text-xl mb-8 font-semibold font-heading text-blue-text">Privacy Policy</h2>
                    <p className="text-left font-light">
                    <b>Last Updated: 10th March 2024</b>
                    <br />

                    This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services. By accessing or using Explra ("Service"), you agree to the terms of this Privacy Policy.<br /><br />

                    1. Information We Collect<br />
                    a. Personal Information: We may collect personal information, such as your name, email address, and other information you voluntarily provide when using our Service.<br />
                    b. Usage Data: We collect information about your usage of the Service, including but not limited to log data, device information, IP address, and browser type.<br />
                    c. Cookies and Similar Technologies: We use cookies and similar tracking technologies to enhance your experience and gather information about how you interact with our Service.<br /><br />

                    2. How We Use Your Information<br />
                    a. Providing and Improving the Service: We use your information to operate, maintain, and improve our Service, including customer support and personalized features.<br />
                    b. Communication: We may use your email address to send you updates, newsletters, and other relevant information about our Service.<br />
                    c. Analytics: We may use third-party analytics services to analyze how users interact with our Service.<br /><br />

                    3. How We Share Your Information<br />
                    a. Service Providers: We may share your information with third-party service providers who assist us in providing the Service.<br />
                    b. Legal Compliance: We may disclose your information in response to legal requests or to comply with applicable laws and regulations.<br /><br />

                    4. Your Choices<br />
                    a. Opt-Out: You can opt-out of receiving promotional emails by following the instructions in those emails.<br />
                    b. Cookies: Most web browsers are set to accept cookies by default. You can usually choose to set your browser to remove or reject browser cookies.<br /><br />

                    5. Data Security<br />
                    We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.<br /><br />
                    
                    7. Changes to This Privacy Policy<br />
                    We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest update will be indicated at the top of the policy.<br /><br />

                    8. Contact Us<br />
                    If you have any questions about this Privacy Policy, please contact us at contact@explra.com.
                    </p>
                </div>
            </div>
        </section>
    );
}