import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from 'pages/home';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from 'pages/login';
import Blog from 'pages/blog';
import ErrorPage from 'pages/error-page';
//import PageCalendar from 'pages/schedule';
import { action as searchDestinationLoader } from 'components/searchDestination'
import PageSchedule from 'pages/schedule';
import { loader as tripProfileLoader, action as tripProfileAction} from 'pages/travelPreferences'
import { loader as rateActivitiesLoader } from 'pages/rateActivities'
import { loader as activitiesListLoader } from 'pages/activitiesList'
import Root from 'pages/root';
import RateActivities from 'pages/rateActivities';
import TravelPreferences from 'pages/travelPreferences';
import ActivitiesList from 'pages/activitiesList';
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga';
import PrivacyPage from 'pages/privacyPolicy';
import TOSPage from 'pages/termsOfService';
import PageMapSchedule from 'pages/map';
import TripSchedule, { loader as scheduleLoader } from 'pages/tripSchedule';
import TripPlanPage from 'pages/tripPlanPage';
import TravelResearchPage from 'pages/use-case/travelResearchPage';
import TripPlanningPage from 'pages/use-case/tripPlanningPage';
import ItineraryGenerationPage from 'pages/use-case/itineraryGeneration';
import TravelAgentsPage from 'pages/use-case/travelAgents';
import Hotjar from '@hotjar/browser';
import DiscoverPage from 'pages/discover';
import AppComingSoonPage from 'pages/appComingSoonPage';


// Analytics
if(process.env.NODE_ENV === 'production') {
  ReactGA.initialize([
    {
      trackingId: `${process.env.REACT_APP_GOOGLE_ANALYTICS}`,
      gaOptions: {
        name: 'analytics_tracker'
      }
    },
    {
      trackingId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_ADS}`,
      gaOptions: {
        name: 'ads_tracker',
      },
      
    }
  ])
}



if (process.env.REACT_APP_MOCK === 'true') {
  const { worker } = require('./mocks/browser')
  worker.start({
    onUnhandledRequest: 'bypass',
  })
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
        action: searchDestinationLoader,
      },
      {
        path: 'trip-planner',
        element: <TripPlanPage />,
        action: searchDestinationLoader
      },
      {
        path: 'trip-plan/:tripId/trip-profile',
        element: <TravelPreferences />,
        loader: tripProfileLoader,
        action: tripProfileAction
      },
      {
        path: 'trip-plan/:tripId/rate-activities',
        element: <RateActivities />,
        loader: rateActivitiesLoader, 
      },
      {
        path: 'trip-plan/:tripId/activities',
        element: <ActivitiesList />,
        loader: activitiesListLoader, 
      },
      // {
      //   path: 'trip-plan/:tripId/refine',
      //   element: <TripPlannerRefine />,
      //   loader: destinationLoader,
      // },
      {
        path: "trip-plan/:tripId/plan",
        element: <TripSchedule />,
        loader: scheduleLoader,
        children: [
          {
            path: "schedule",
            element: <PageSchedule />,
            loader: scheduleLoader,
          },
          {
            path: "map",
            element: <PageMapSchedule />,
            loader: scheduleLoader,
          },
        ]
      },
      {
          path: "travel-research",
          element: <TravelResearchPage />
      },
      {
        path: "trip-planning",
        element: <TripPlanningPage />
      },
      {
        path: "itinerary-generation",
        element: <ItineraryGenerationPage />
      },
      {
        path: "travel-agents",
        element: <TravelAgentsPage />
      },
      {
        path: "discover",
        element: <DiscoverPage />
      },
      {
        path: "app",
        element: <AppComingSoonPage />
      },
     
      // {
      //   path: "trip-plan/:tripId",
      //   element: <TripPlanner />,
      //   loader: destinationLoader,
      // },
      // {
      //   path: "login",
      //   element: <Login />
      // },
      // {
      //   path: "blog",
      //   element: <Blog />
      // },
      {
        path: "privacy-policy",
        element: <PrivacyPage />
      },
      {
        path: "terms-of-service",
        element: <TOSPage />
      },
    ]
  },
]);
{process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: "https://1ad1ee235ab80b8eea38454c910e4e7d@o4506771326435328.ingest.sentry.io/4506805009580032",
    integrations: [
      // Tracing Origins are required so that Google Analytics does not through a Cors error.
      // https://github.com/getsentry/sentry-javascript/issues/5868
      Sentry.browserTracingIntegration({
        tracingOrigins: ['/^https:\/\/explra.com/', '/^https:\/\/api.explra.com/',]
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      // Sentry.feedbackIntegration({
      //   colorScheme: "system",
      //   showBranding: false,
      //   buttonLabel: "Feedback",
      //   submitButtonLabel: "Send feedback",
      //   formTitle: "Submit feedback",
      //   showEmail: false,
      //   messagePlaceholder: "What can we be doing better?",
      //   successMessageText: "Thank you for your feedback."
      // }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Enabling this will trigger CORS issues, adds `baggage` header
    //tracePropagationTargets: ["localhost", /^https:\/\/api\.explra\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.8, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const siteId = 4979481;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
