import { useRef } from "react"
import { useDebouncedCallback } from "use-debounce"

export default function AutoComplete(
    { name, suggestions, onSelectHandler, onChangeHandler }:
        { name: string, suggestions: string[], onSelectHandler: any, onChangeHandler: any }) {
    const input: any = useRef()

    const suggestionSelected = (suggestion: string) => {
        input.current.value=suggestion
        // Bubble up to the caller.
        onSelectHandler(suggestion)
    }
    const debounced = useDebouncedCallback(
        // function
        (value) => {
            onChangeHandler(value);
        },
        // delay in ms
        750
      );
    return (
        <div className="relative">
            <input 
                ref={input}
                name={name}
                type="text" 
                onChange={(e) => debounced(e.target.value)} 
                placeholder="Where To?" 
                className="peer placeholder:italic border-x-transparent border-t-transparent border-coolGray-200 focus:outline-none focus:border-transparent focus:border-b-1 focus:border-b-gray-300 rounded-none md:bg-transparent focus:ring-0 text-gray-900 placeholder:text-gray-400 text-md min-w-full pl-0"/>
            {suggestions.length >= 1 &&
            // TODO: As soon as the input looses focus the ul is hidden, the click event never happens
                <ul 
                    className="peer-focus:visible invisible z-50 absolute bg-white border-gray-100 border-b pb-2 px-2 w-full">
                    {suggestions.map((suggestion) => {
                        return <li 
                                    key={suggestion}
                                    onMouseDown={() => suggestionSelected(suggestion)}
                                    // onClick={() => {suggestionSelected(suggestion)}}
                                    className="cursor-pointer hover:bg-gray-100">{suggestion}</li>
                    })}
                </ul>
            }
        </div>
    )
}