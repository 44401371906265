import ExplraFeatures from "components/explraFeatures"
import FeaturedBlog from "components/featuredBlog"
import FeaturedItinerary from "components/featuredItinerary"
import HomeHeader from "components/homeHeader"
import SearchDestination from "components/searchDestination"
import Feature from "components/feature"
import Popular from "components/popular"



export default function Home() {

    return (
        <>
            <div className="bg-white mx-auto relative container">
                <div className="">
                    <HomeHeader />
                </div>
                <section className="relative -mt-20 z-20">
                    <div className="relative container mx-auto max-w-3xl">
                        <div className="mx-6 bg-white rounded-md shadow-lg py-6">
                            <div className="p-4">
                                <SearchDestination />
                            </div>
                        </div>
                    </div>
                </section>
                <Popular />
                <Feature />

                <FeaturedBlog 
                    heading='Stay up to date'
                    urlLink='/blog/'
                    caption='Travel information and articles to help you navigate your next adventure!'
                />
                <FeaturedItinerary
                    heading='Discover Destinations'
                    urlLink='/itineraries/'
                    caption='Explore pre-built itineraries as a base to build your next journey'
                />
            </div>
            <div className="relative py-16 bg-[#F2EAF5] -mt-16 z-10">
                <div className="container px-4 mx-auto mt-16">
                    <ExplraFeatures />    
                </div>
            </div>
            
        </>
    )
}