import ReactGA from 'react-ga';

const CATEGORY_NAVIGATION = 'Navigation'
const CATEGORY_ACTIVITY_RATING = 'Activity Rating'
const CONVERSION = "conversion"

// Not in use

export default async function trackClick(category: string, action: string, tracker: string = 'analytics_tracker') {
  // Track a custom event
  // gtag('event', 'conversion', {'send_to': 'AW-970665941/xXzSCN-4_IYZENXf7M4D'});
  ReactGA.event({
    category: category,
    action: action,
    //label: 'Product Page',
  }, [tracker]);
}