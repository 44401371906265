import ExplraButton from "components/button"
import ExplraFeatures from "components/explraFeatures"
import { useCallback } from "react"
import { useDocumentTitle } from "services/setDocumentTitle"


export default function TravelResearchPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Explra for Travel Research')
    return (
        <div>
            <div className="">
                <div className="container px-4 mx-auto max-w-4xl">
                    <div className="text-center">
                        <h1 className="text-xl mb-8 font-semibold font-heading text-blue-text">
                            Travel Research Made Easy with Curated Itineraries
                        </h1>
                        <p className="text-left font-light">
                        <img src="/assets/images/mocks/mockup-home-combined.png" className="m-4 max-w-md float-left"/>
                            Welcome to your one-stop shop for planning unforgettable travel experiences. Our app is designed to help you save time and hassle when it comes to creating an itinerary that suits your needs, budget, and interests. With our curated collections of destinations and activities you can rest assured that we've done the hard work for you.
                            <br /><br />
                            
                            Whether you're looking for a romantic getaway or an adventurous trip with friends, our app has something for everyone. Our expertly crafted itineraries feature the best of what each destination has to offer, ensuring that you have a truly memorable experience.
                            <br /><br />
                            Our user-friendly interface makes it easy to browse and customize your travel plans based on your preferences. Simply select your preferred travel dates, destinations, and our app will do the rest. We'll recommend activities and events that match your criteria, provide information about each destination, and even suggest the best routes to see everything on your trip.
                            <br /><br />
                            Don't have any specific plans in mind? No problem! Our collection of curated itineraries covers a wide range of popular travel destinations, from Europe to Asia and beyond. Each itinerary is carefully crafted by our team of experts, who have first-hand knowledge of the destination and can provide insider tips and recommendations.
                            <br /><br />
                            Don't forget to share your completed itinerary with friends and family so that they can join in on the fun! With our app, you'll never miss a chance to create unforgettable memories with those who matter most.
                        </p>    
                        <div className="mt-6">
                            <ExplraButton
                                href='/discover'
                                text="Take me to the discovery page"
                                style="primary"
                                size={4} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-16 pt-12 bg-[#F2EAF5]">
                <div className="container px-4 mx-auto">
                    <ExplraFeatures />    
                </div>
            </div> 
        </div>
    )
}