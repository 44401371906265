import dayjs from 'dayjs'
import ScheduleDay from 'components/scheduleDay'
import { IScheduleActivity } from 'interfaces/interfaces'


/**
 * Create an array of dates that the trip spans.
 * 
 * @param startDate epoc time
 * @param endDate epoc time
 * @returns an Array of dates.
 */
function populateDatesFromStartDate(startDate: Date, endDate: Date) {
    let dates = Array()
    const dayDiff: number = dayjs(endDate).diff(startDate, 'day')
    const startDateDayJs = dayjs(startDate)
    for(let i = 0; i < dayDiff; i ++) {
        // Add new date to the array
        dates.push(startDateDayJs.add(i, 'day'))
    }
    return dates
}


// Users come to this page to select their activities and place them in a schedule. 
export function Schedule({activities, startDate, endDate}: {activities: IScheduleActivity[], startDate: Date, endDate: Date}) {
    const dates = populateDatesFromStartDate(startDate=startDate, endDate=endDate)
    return (
        <div>
            <section className="relative mt-8 pb-16">
                <div className="container px-4 mx-auto max-w-xl">
                    <div className=" ">
                        {dates.map((date, i) => 
                            <ScheduleDay
                                key={date}
                                activities={activities.filter((activity) => dayjs.utc(activity.suggested_start_date).isSame(date, 'day'))}
                                date={date}
                                idx={i+1}
                                weather=""
                                />
                        )}
                    </div>
                </div>
            </section>            
        </div>
    )
}
