import FeaturedBlogSingle from "components/featureBlogSingle";
import { Outlet, Link } from "react-router-dom";
import PlaceCard from "./placeCard";

export default function FeaturedItinerary({heading, urlLink, caption}: {heading: string, urlLink: string, caption: string}) {
    const blogs = [
        {
            id: 0,
            title: "Adelaide, Australia",
            link: "https://explra.com/itineraries/australia/australia-adelaide/",
            related_to: "",
            image: "https://explra.com/images/map-australia-adelaide.png",
            summary: "Adelaide is the capital city of South Australia, with a vibrant food and wine scene, rich cultural heritage, and beautiful beaches nearby. It offers plenty of outdoor activities and festivals throughout the year.",
            date_published: "",
        },
        {
            id: 1,
            title: "Madrid, Spain",
            link: "https://explra.com/itineraries/spain/spain-madrid",
            related_to: "",
            image: "https://explra.com/images/map-spain-madrid.png",
            summary: "Madrid, Spain's capital city, is a vibrant metropolis known for its rich culture, architecture, museums, and gastronomy. It's a perfect destination for art lovers, history buffs, and foodies who want to experience the best of what Spain has to offer.",
            date_published: "",
        },
        {
            id: 2,
            title: "England, London",
            link: "https://explra.com/itineraries/england/england-london",
            related_to: "",
            image: "https://explra.com/images/map-england-london.png",
            summary: "London, the capital of England, is a vibrant city filled with iconic landmarks, world-class museums, and diverse cultures. From Buckingham Palace to Big Ben, there is no shortage of things to do and see in this bustling metropolis.",
            date_published: "",
        },
    ]
    return (
        <section className="relative py-20">
            <div className="container px-4 mx-auto flex flex-wrap -m-3">
                <div className="w-full">
                    <h1 className="inline-block text-2xl font-semibold font-heading">{heading}</h1>
                    <Link 
                        className="font-medium text-sm text-explraRed underline hover:no-underline ml-2"
                        reloadDocument={true}
                        to={urlLink}> See More</Link>
                    {/* <a className="font-medium text-sm text-explraRed underline hover:no-underline" href={urlLink}>View more</a> */}
                    <p className="mb-4 text-gray-500 leading-relaxed">{caption}</p>
                </div>

                {/* Start Loop */}
                <div className="sm:columns-3 space-y-4">
                    <PlaceCard item={blogs[0]}/>
                    <PlaceCard item={blogs[1]} />
                    <PlaceCard item={blogs[2]} />
                </div>
            </div>
        </section>
    )
}