import { useRouteError } from "react-router-dom";
import { useDocumentTitle } from "services/setDocumentTitle";

export default function AppComingSoonPage() {
    const [docTitle, setDocTitle] = useDocumentTitle("App Coming Soon")

    return (
    <section className="py-20">
        <div className="container px-4 mx-auto">
        <div className="max-w-4xl h-64 md:h-112 mb-12 mx-auto">
            <img className="h-full w-full rounded-xl object-cover object-top" src="https://images.unsplash.com/photo-1566622246517-0e59fdb36f0e?ixlib=rb-1.2.1&amp;ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt="" />
        </div>
        <div className="text-center">
            <h2 className="mb-10 text-4xl font-semibold font-heading">Coming Soon</h2>
            <p className="mb-12 text-xl text-gray-500">We are working on something big. Come back soon to download the Explra app.</p>
        </div>
        </div>
    </section>
    );
}