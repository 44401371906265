import { ICard } from 'interfaces/interfaces';

export default function PlaceCard({ item }: { item: ICard }) {
  //"https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
  return (
    <>
      <article className="overflow-hidden rounded-lg shadow transition hover:shadow-lg">
        <img
          alt={item.title}
          src={item.image}
          className="h-56 w-full object-cover"
        />

        <div className="bg-white p-4 sm:p-6">
          <time dateTime={item.date_published} className="block text-xs text-gray-500">
            {item.date_published}
          </time>

          <a href={item.link}>
            <h3 className="mt-0.5 text-lg text-gray-900">
              {item.title}
            </h3>
          </a>

          <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
            {item.summary}
          </p>

          <a href={item.link} className="mt-4 inline-block text-explraRed underline hover:no-underline">
            {item.linkName ? item.linkName : `Read more`}
          </a>
        </div>
      </article>
    </>
  );
}
