class Auth {

    static getToken(): string | null {
        return localStorage.getItem("token")
    }

    static setToken(token: string) {
        localStorage.setItem("token", token)
    }
}

export default Auth