import { IPostTripPlan } from "interfaces/interfaces"
import Auth from "services/auth";
// Monkey patch fetch to intercept the request, adding the auth token
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
    let [headers, config ] = args;

    if (config) {
        // TODO: Add existing headers.
        const token = Auth.getToken()
        //const token = localStorage.getItem("token")
        if (token) {
            config.headers = new Headers({
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            })
        }
    }
    const response = await originalFetch(headers, config);
    return response;
};

export async function exchangeToken(credential: string) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/exchange/`, {method: 'POST', 
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            credential: credential
        })}
    ).then((res) => {return res.json()}));
}

export async function getLocationSearch(query: string) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/location/search/?q=${query}`, {method: 'GET', headers: {"Content-Type": "application/json"}})).json()
}

export async function getTripPlan(tripId: string) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/`, {method: 'GET', headers: {"Content-Type": "application/json"}})).json()
}

export async function postTripPlan(data: IPostTripPlan) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/trips/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) })
}

export async function postEmailShare(data: any) {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/trips/email-trip-plan/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) })
}

export async function getSchedule(tripId: string) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/schedule/`, { method: 'GET' }).then((res) => {return res.json()})
    // const schedule = response.json()
    // return new ISchedule(schedule)
}

// export async function getInto(tripId: string) {
//     return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/trip-intro/`, { method: 'GET' })).json()
// }

export async function getTripActivities({tripId, page = 0, orderBy = '', status = 1} : {tripId: string, page?: number, orderBy?: string, status?: number}) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/trip-activities/?trip=${tripId}&status=${status}&page=${page}&ordering=${orderBy}`, { method: 'GET' })
    return await response.json()
    //return data.results
}

export async function getTripActivitiesOld(tripId: string, page: number = 0, orderBy: string = '') {
    // Not being used
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/trip-activities/?page=${page}&order=${orderBy}`, { method: 'GET' })).json()
}

// export async function getTripResearch(destination: string | null, topic: string) {
//     return (await fetch(`${process.env.REACT_APP_API_URL}/api/research/?destination=${destination}&topic=${topic}`, { method: 'GET' })).json()
// }

// Get similar activities from ratings flow
export async function postSimilarRatings(tripId: string, data: any) {
    //return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/similar/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) })).json()
    return fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/similar-from-ratings/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) }).then((res) => {return res.json()})
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/similar-from-ratings/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) })).json()
}

export async function postSimilar(tripId: string, data: any) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/similar/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) })).json()
}

// Rate an activity.
export async function putRate(activityId: string, tripId: string, rating: number) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/activity/${activityId}/rate/`,{method: 'PUT', headers: {"Content-Type": "application/json"}, body: JSON.stringify({trip: tripId, rating: rating}) })).json()
}

export async function putRateBinary(activityId: string, tripId: string, rating: boolean) {
    //TODO: Could be replaced with updateTripActivity
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/activity/${activityId}/rate/binary/`,{method: 'PUT', headers: {"Content-Type": "application/json"}, body: JSON.stringify({trip: tripId, rating: rating}) })).json()
}

export async function updateTripActivity(activityId: string, data: any) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-activities/${activityId}/`,{method: 'PATCH', headers: {"Content-Type": "application/json"}, body: JSON.stringify(data) })).json()
}

// export async function getTripProfiles() {
//     return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-profile/`, {method: 'GET', headers: {"Content-Type": "application/json"}})).json()
// }

// TODO: Not implemented since we will be creating.
// export async function getTripProfile(tripId: string) {
//     return (await fetch(`${process.env.REACT_APP_API_URL}/api/trips/${tripId}/trip-profiles/`, {method: 'GET', headers: {"Content-Type": "application/json"}})).json()
// }

export async function createTripProfile(updates: any) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-profiles/`, {method: 'POST', headers: {"Content-Type": "application/json"}, body: JSON.stringify(updates) }))
}

export async function updateTripProfile(updates: any, profileId: string) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-profiles/${profileId}/`, {method: 'PUT', headers: {"Content-Type": "application/json"}, body: JSON.stringify(updates) }))
}

export async function getTripStyles() {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-styles/`, {method: 'GET', headers: {"Content-Type": "application/json"}})).json()
}

export async function getTripProfiles(tripId: string) {
    return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-profiles/?trip=${tripId}`, {method: 'GET', headers: {"Content-Type": "application/json"}}))
}

// export async function getTripStyle(tripId: string) {
//     return (await fetch(`${process.env.REACT_APP_API_URL}/api/trip-styles/${tripId}/`, {method: 'GET', headers: {"Content-Type": "application/json"}})).json()
// }
