
export default function TripHeader({city, country, image, tagline}: {city: string, country: string, image: string, tagline: string}) {
    
    return (
        <section className="pt-20 pb-16">
            <div className="container px-4 mx-auto">
                <div className="max-w-4xl mx-auto mb-10 text-center">
                    <h1 className="mb-10 text-5xl font-thin font-heading text-center text-blue-text">Welcome to {city}, {country}</h1>
                    <p className="mb-10 text-xl font-thin text-center text-blue-text">{tagline}</p>
                </div>
                <div className="h-64 sm:h-112 w-full bg-no-repeat bg-cover print:hidden bg-center bg-fixed" style={{ backgroundImage: `url(${image})` }}></div>
            </div>
        </section>
    )
}