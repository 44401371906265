import { ITrip, ITripActivity } from "interfaces/interfaces"
import { useState } from "react"
import { defer, useLoaderData } from "react-router-dom"
import { getTripActivities, getTripPlan, postSimilar, updateTripActivity } from "services/api"
import ExplraButton from "components/button"
import dayjs from "dayjs"
import SingleActivity from "components/singleActivity"
import { useDocumentTitle } from "services/setDocumentTitle"

// TODO: Return values from API are now nested, look at getting results.activity.
export async function loader({ request, params }: {request: any, params: any}) {
    const trip = await getTripPlan(params.tripId)
    const activities = (await getTripActivities({tripId: params.tripId, page: 0, orderBy: '-activity_rating__rating'})).results
    const apiExcludedActivities = (await getTripActivities({tripId: params.tripId, page: 0, orderBy: '-activity_rating__rating', status: 3})).results
    
    // Redirect if empty returns, since this means we have completed this step from the API
    return defer({ trip, activities, apiExcludedActivities })
}

export default function ActivitiesList () {
    const { trip, activities, apiExcludedActivities }  = useLoaderData() as {trip: ITrip, activities: ITripActivity[], apiExcludedActivities: ITripActivity[]}
    const [availableActivities, setAvailableActivities] = useState<ITripActivity[]>(activities)
    const [newAPIActivities, setNewAPIActivities] = useState<ITripActivity[]>([])
    const [excludedActivities, setExcludedActivities] = useState<ITripActivity[]>(apiExcludedActivities)
    const [docTitle, setDocTitle] = useDocumentTitle('Trip Activities')

    /** Replace the current activity with a new one */
    async function replaceActivity(activity: ITripActivity) {
        // Send rating as Rejected
        await updateTripActivity(activity.id, {status: 3})

        // Add activity to excluded list
        setExcludedActivities([...excludedActivities, activity ])

        // If theres no API activities left, get more for the user to scroll through. 
        let toReplace: ITripActivity
        console.log('new api activityes', newAPIActivities)
        if(Object.keys(newAPIActivities).length === 0) {   
            const include: string[] = availableActivities.map(act => act.activity.title)
            const excluded: string[] = excludedActivities.map(act => act.activity.title)
            const data = {
                "activities": include,
                "exclude": excluded,
            }
            const activities = await postSimilar(trip.id, data)
            const newActivities: ITripActivity[] = activities
            // Get the first activity from the API Activities and use it to replace
            toReplace = newActivities[0]
            setNewAPIActivities([...newActivities.filter(act => act.id !== toReplace.id )])
        } else {
            toReplace = newAPIActivities[0]
            setNewAPIActivities([...newAPIActivities.filter(act => act.id !== toReplace.id )])
        }
        // Confirm to the API that we want to use this activity
        await updateTripActivity(toReplace.id, {status: 1})
        
        // replace current activity in array with new one from newAPIActivities
        setAvailableActivities([...availableActivities.map(act => {
            if(act.id === activity.id) {
                return toReplace
            }
            return act
        })])
                
    }
    
    const dateDiffDays = dayjs.utc(trip.date_to).diff(dayjs.utc(trip.date_from), 'day')
    return (
        <div className="">
            <section className="mb-20">
                <div className="container px-4 mx-auto">
                    <div className="max-w-lg mx-auto font-light text-left">
                        <h1 className="text-center font-semibold text-2xl">Final Boarding Call</h1>
                        <p className="text-center">
                            <span className="font-bold">Your curated activities has been generated. </span><br />
                            Based on your preferences we have generated {availableActivities.length} activities for your {dateDiffDays} day trip. <br />
                            Before we generate your schedule, this is your chance to refine your activities. 
                        </p>
                    {availableActivities.map((tripActivity) => 
                        <SingleActivity 
                            key={tripActivity.id}
                            tripActivity={tripActivity}
                            handleClick={replaceActivity}
                        />
                        
                    )}
                    </div>
                </div>
            </section>
            <div className="fixed bottom-0 w-full bg-white h-20">
                <div className="relative container p-4 mx-auto">
                    <div className="max-w-lg mx-auto font-light text-center">
                        <ExplraButton 
                            href={`/trip-plan/${trip.id}/plan/schedule`}
                            text="Continue"
                            style="primary"
                         />
                    </div>
                </div>
            </div>
        </div>
    )
}