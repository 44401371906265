import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import { ITripActivity, ITrip, IActivityImage } from "interfaces/interfaces";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getTripActivities, postSimilarRatings, putRateBinary } from "services/api";
import parse from 'html-react-parser';
import { ProfileLoader } from "pages/rateActivities";


const MINIMUM_NUMBER_OF_ACTIVITIES = 10

export function RateBinary({trip, activitiesToRate}: {trip: ITrip, activitiesToRate: number}) {
    const [includedActivities, setIncludedActivities] = useState<ITripActivity[]>([])
    const [excludedActivities, setExcludedActivities] = useState<ITripActivity[]>([])
    const [availableActivities, setAvailableActivities] = useState<ITripActivity[]>([])
    const [currentActivity, setCurrentActivity] = useState<ITripActivity>()
    const navigate = useNavigate()
    const [inFlight, setInflight] = useState(false)
    const [counted, setCounted] = useState(0)
    console.log(availableActivities)
      
    /**
     * Return similar activities to the ones already selected
     */
    async function getSimilarActivities() {
        console.log("getSimilarActivities")
        const include: string[] = includedActivities.map(act => act.activity.title)
        const excluded: string[] = excludedActivities.map(act => act.activity.title)
        const data = {
            "activities": include,
            "exclude": excluded,
        }

        postSimilarRatings(trip.id, data).finally(() =>{
            // This response takes a long time, so we have a state to make sure the component doesnt keep requesting
            // data from this endpoint.
            setInflight(false)
        })
        
        //return results
    }
    
    // Poll the activities endpoint to get updated activities
    useEffect(() => {
        console.log("getActivities")
        const intervalId = setInterval(async () => {
            console.log("Timer getting activities")
            const newActivities: ITripActivity[] = (await getTripActivities({tripId: trip.id, status: 0})).results
            console.log(newActivities)
            //[...availableActivities, ...activities.results].filter((act: ITripActivity) => act.activity.id)
            if (newActivities) {
                const duplicatesRemoved = [...availableActivities, ...newActivities].filter((obj1, i, arr) => 
                    arr.findIndex(obj2 => (obj2.activity.id === obj1.activity.id)) === i
                )
                // Filter before returning. 
                //const duplicatesRemoved = [...availableActivities, ...activities.results].filter((act: ITripActivity) => act in activitiesSet)
                console.log(duplicatesRemoved)
                setAvailableActivities(duplicatesRemoved)
            }
        }, 5000); // 5 seconds
        return () => clearInterval(intervalId);
    })
    
    async function handleVote(tripActivity: ITripActivity, vote: boolean) {
        putRateBinary(tripActivity.activity.id, trip.id, vote)
        
        if (vote === true) {
            // Add tripActivity to the selected activities
            setIncludedActivities([...includedActivities, tripActivity ])
        } else {
            setExcludedActivities([...excludedActivities, tripActivity ])
        }
        const activitiesLessCurrentVotedFor = availableActivities.filter((act) => act.activity.id !== tripActivity.activity.id)
        setAvailableActivities(activitiesLessCurrentVotedFor)
        setCurrentActivity(activitiesLessCurrentVotedFor[0])
    }

    if ((availableActivities.length <= MINIMUM_NUMBER_OF_ACTIVITIES) && inFlight === false) {
        console.log("Getting more activities")
        setInflight(true)
        // Generate activities in background
        getSimilarActivities()
        // Start timer to poll for new activities
        //getActivities()
        console.log("Finished the function for getting more activities.")
    }

    function isCurrentActivity() {
        if (currentActivity === null || currentActivity === undefined) {
            return false
        }
        return true
    }

    if (!isCurrentActivity()) {
        // No activities left and API request already in flight. 
        if (availableActivities.length <= 0) {
            return (
                <ProfileLoader />
            )
        }
        //currentActivity = availableActivities[0]
        setCurrentActivity(availableActivities[0])
        return (
            <ProfileLoader />
        )
        
    }

    // Needed to prevent typechecking
    if (currentActivity === null || currentActivity === undefined) {
        return (
            <ProfileLoader />
        )
    }

    // TODO: Move this logic to the constructor for the class
    let image: IActivityImage 
    try {
        image = currentActivity.activity.images[0]    
    } catch (error) {
        image = {
            id: "999",
            photo_url: "/assets/logo/logo-explra-single-grey-512.png",
            photo_attribution: [""],

        }
    }
    
    return (
        <div className="p-2 relative" key={currentActivity.activity.id}>
            {(Object.keys(includedActivities).length >= activitiesToRate) && <Navigate to={`/trip-plan/${trip.id}/activities`} /> }
            {/* {counted}<br />
            {`Included Activities ${includedActivities.length} out of ${activitiesToRate}`} */}
            {includedActivities.map((act) => `${act.activity.title},`)}
            <div className="overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                <img
                    alt={`Image of ${currentActivity.activity.title}`}
                    src={`${image.photo_url}`}
                    className="h-96 object-cover min-w-full"
                    />
                {image.photo_attribution && <span className="text-sm font-thin italic text-left block pl-2">Photo attribution: {parse(`${image.photo_attribution}`)}</span>}
                <div className="bg-white p-4 sm:p-6">
                    <h3 className="mt-0.5 text-lg text-gray-900">
                        {currentActivity.activity.title}
                    </h3>
                    <ul className="list-disc mt-2 text-sm/relaxed text-gray-700">
                        {currentActivity.activity.tags.map((tag, i) => 
                            <li className="inline-block mx-1">{tag.name}{currentActivity.activity.tags.length - 1 != i && ','} </li>
                        )}
                    </ul>
                    <p className="mt-2 text-sm/relaxed text-gray-500">
                        {currentActivity.activity.description}
                    </p>
                    

                    <div className="stroke-1 text-gray-300 grid grid-cols-2 border-t mt-4 pt-4">
                        <div className="">
                            <span
                                onClick={() => handleVote(currentActivity, false)} 
                                className="text-gray-300 hover:text-gray-400 hover:cursor-pointer inline-block w-12">
                                <HandThumbDownIcon/>
                            </span>
                        </div>
                        <div className="">
                            <span 
                                onClick={() => handleVote(currentActivity, true)}
                                className="text-gray-300 hover:text-gray-400 hover:cursor-pointer inline-block w-12">
                                <HandThumbUpIcon/>
                            </span>
                        </div>
                    </div>
                </div>
                {currentActivity.activity.website ? <a  href={currentActivity.activity.website} target="_blank">Official Website</a> : ''}
            </div>
        </div>
    )
}