import { useDocumentTitle } from "services/setDocumentTitle";

export default function TOSPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Terms of Service')
    return (
        <section className="py-20">
            <div className="container px-4 mx-auto max-w-4xl">
                <div className="text-center">
                    <h2 className="text-xl mb-8 font-semibold font-heading text-blue-text">Terms of Service</h2>
                    <p className="text-left font-light">
                        <b>Last Updated: 10th March 2024</b>
                        <br />

                        These terms of service ("Terms") govern your use of our SaaS application and related services. Please read these Terms carefully before using the application.
                        <br /><br />
                        1. Acceptance of Terms<br />

                        By accessing or using Explra ("Service"), you agree to be bound by these Terms. If you do not agree to all the terms and conditions of this agreement, then you may not access the Service.<br /><br />

                        2. Access and Use of the Service<br />

                        a. You are granted a non-exclusive, non-transferable, revocable license to use the Service for its intended purpose.<br />

                        b. You are responsible for maintaining the confidentiality of your account credentials.<br />

                        c. You agree not to access the Service by any means other than through the interfaces provided by Explra.<br /><br />

                        3. Subscription Plans and Payments<br />

                        a. The use of certain features within the Service may require a subscription plan. Payment terms and details can be found on our pricing page.<br />

                        b. Subscription fees are non-refundable, and your subscription will automatically renew unless you cancel it before the end of the current subscription period.<br /><br />

                        4. Data Security and Privacy<br />

                        a. Your data is important to us. Please refer to our <a href="/privacy-policy">Privacy Policy</a> for details on how we collect, use, and protect your information.<br />

                        b. Explra employs industry-standard security measures to protect the confidentiality and integrity of your data.<br /><br />

                        5. Data accuracy<br />
                        a. Explra is a recommendation service for travel itineraries. While all best efforts have been made to ensure all information is up to date and accurate we can not guarantee this information. <br />
                        b. The information and services provided on this website are for informational purposes only. Explra does not warrant or guarantee the accuracy, completeness, or usefulness of any information or services provided on this website. Any reliance you place on such information is strictly at your own risk. <br />
                        Explra disclaims all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the website, or by anyone who may be informed of any of its contents.<br /><br />

                        6. Support and Maintenance<br />

                        a. Explra reserves the right to perform routine maintenance and updates to ensure the continued functionality and security of the Service.<br /><br />

                        7. Termination<br />

                        Explra reserves the right to terminate or suspend access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.<br /><br />

                        8. Intellectual Property<br />

                        a. Explra retains ownership of all intellectual property rights in the Service.<br />

                        b. You may not reverse engineer, decompile, or disassemble any part of the Service.<br /><br />

                        9. Limitation of Liability<br />

                        Explra shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.<br /><br />

                        10. Governing Law<br />
                        a. this Agreement is governed by the laws of Victoria, Australia; and<br />
                        b. each party submits to the exclusive jurisdiction of the courts of Victoria, Australia, in relation to any proceedings connected with this Agreement.<br /><br />

                        11. Affiliate Links<br />
                        Some of the links on this website are affiliate links, which means that Explra may earn a commission if you click on the link or make a purchase using the link. When you make a purchase, the price you pay will be the same whether you use the affiliate link or go directly to the vendor's website using a non-affiliate link. By using the affiliate links, you are helping support our website, and we genuinely appreciate your support. We only recommend products or services that we believe will add value to our readers. Nonetheless, the views and opinions expressed on this website are purely our own. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider or party in question. If you have any questions about the use of affiliate links on this website, please do not hesitate to contact us.<br /><br />

                        12. Contact Us<br />

                        If you have any questions about these Terms, please contact us at contact@explra.com.<br /><br />

                        Thank you for choosing Explra!<br />
                        
                    </p>
                </div>
            </div>
        </section>
    );
}