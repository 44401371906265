import { BookOpenIcon } from "@heroicons/react/24/outline";
import trackClick from "services/analytics";

const URL = 'https://www.amazon.com/dp/B00NB86OYE/?ref_=assoc_tag_ph_1485906643682&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=gerardable-20&linkId=c39b0a90637da23a56b74d881ed151d7'

export default function AudibleAd({trackingId}: {trackingId: string}) {
    return (
        <div className="container px-4 mx-auto max-w-xl text-center print:hidden my-8">
            <div className="border border-gray-300 rounded-md p-4 relative">
                <span className='absolute -top-4 left-4 bg-white p-1 text-gray-300'>Advertisement</span>
                <h5 className="text-l font-semibold text-blue-text leading-relaxed text-base">Looking for something to listen to on your trip?</h5>
                <p>Signup to Audible and get a 30 day free trial</p>
                <a href={URL}
                   target="_blank"
                   className='rounded inline-block md:w-auto px-8 py-4 text-white text-sm font-medium leading-normal bg-explraBtnRed-primary hover:bg-explraBtnRed-secondary mt-4'
                        onClick={() => {
                            trackClick('advertising', 'audibleAdClick')
                        }}>
                        <BookOpenIcon className='inline-block h-6 text-white' /> 30 day free trial
                </a>
            </div>
        </div>
    )
}