import { UserCircleIcon, HeartIcon, GlobeAltIcon, HandThumbUpIcon,  } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Destination.',
    description: 'You know where your going, but not what to see and do. Enter your destination to start your journey.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Travel Profile.',
    description: 'Fill out a few questions about your travel preferences to start generating ideas.',
    icon: UserCircleIcon,
  },
  {
    name: 'Thumbs Up.',
    description:
      'A thumbs up or down is all it takes to find places and activities for your next trip.',
    icon: HandThumbUpIcon,
  },
  
  {
    name: 'Personalised for you.',
    description: 'Your recommendations and itinerary are one of a kind. Using your travel profile and preferences we tailor our recommendations to you.',
    icon: HeartIcon,
  },
]

export default function Feature() {
  return (
    <div className="overflow-hidden bg-white py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Be Inspired</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Trip Planning</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="/assets/images/mocks/mockup-schedule.png"
            alt="Product screenshot"
            className="w-[32rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[38rem] md:-ml-4 lg:-ml-0 p-4"
          />
        </div>
      </div>
    </div>
  )
}