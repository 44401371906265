import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { IScheduleActivity } from "interfaces/interfaces";
import { ReactElement, useEffect, useRef } from "react";

interface Marker {
    position: google.maps.LatLngLiteral
    cluster: number
    title: string
}

function findCenter(markers: Marker[]) {
    if (markers.length === 0) {
        return null; // Return null if array is empty
    }

    // Calculate average latitude
    const avgLat = markers.reduce((sum, coord) => sum + coord.position.lat, 0) / markers.length;
    // Calculate average longitude
    const avgLng = markers.reduce((sum, coord) => sum + coord.position.lng, 0) / markers.length;

    return { lat: avgLat, lng: avgLng };
}

function MapComponent({
    center,
    zoom,
    markers,
  }: {
    center: google.maps.LatLngLiteral
    zoom: number
    markers: Marker[]
  }) {
    const ref: any = useRef();

    useEffect(() => {
        const map = new window.google.maps.Map(ref.current, {
            mapId: "DEMO_MAP_ID",
        });
        let bounds = new google.maps.LatLngBounds();
        markers.map(marker => {
            //new google.maps.marker.AdvancedMarkerElement({
            const newMarker = new google.maps.Marker({
                map: map,
                position: marker.position,
                label: marker.cluster.toString(),
                title: marker.title
            });
            const infowindow = new google.maps.InfoWindow({
                content: marker.title,
                ariaLabel: marker.title,
            })
            newMarker.addListener("click", () => {
                infowindow.open({
                    anchor: newMarker,
                    map,
                });
            });
            bounds.extend(marker.position)
        })
        map.fitBounds(bounds)
    });
  
    return <div ref={ref} id="map" className="w-full h-full" />;
  }
  
export function GoogleMap({activities} : {activities: IScheduleActivity[]}) {
    const apiKey = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`

    const render = (status: Status): ReactElement => {
        if (status === Status.FAILURE) return <div></div>;
        return <div></div>;
    };
    const markers: Marker[] = activities.map(activity => {
        return {
            position: {
                lat: activity.activity.activity.meta.latitude,
                lng: activity.activity.activity.meta.longitude,
            },
            cluster: activity.cluster,
            title: activity.activity.activity.title
        }
    })
    
    const center = findCenter(markers)
    if(!center) {
        return (<></>)
    }

    return (
        <div>
            <Wrapper apiKey={apiKey} render={render}>
                <div className="w-full h-96">
                    <MapComponent center={center} zoom={10} markers={markers} />
                </div>
            </Wrapper>
        </div>
    )
}