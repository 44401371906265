import ExplraButton from "components/button"
import ExplraFeatures from "components/explraFeatures"
import { useDocumentTitle } from "services/setDocumentTitle"


export default function TravelAgentsPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Explra for Travel Agents')
    return (
        <div>
            <div className="">
                <div className="container px-4 mx-auto max-w-4xl">
                    <div className="text-center">
                        <h1 className="text-xl mb-8 font-semibold font-heading text-blue-text">
                        Travel Agents - Explra App
                        </h1>
                        <p className="text-left font-light">
                        <img src="/assets/images/mocks/mockup-home-combined.png" className="m-4 max-w-md float-left"/>
                        The world of travel can be overwhelming, especially when planning a trip. With so many options and choices, it's easy to get lost in the details. That's where Explra comes in - an AI-powered app that creates personalized travel itineraries for its users.
                        <br /><br />
                        For travel agents, this means more time saved on researching destinations and planning trips for their clients. The Explra app provides a seamless platform for travel agents to create customized itineraries for their clients using AI-powered recommendations based on the client's preferences.
                        <br /><br />
                        With the Explra app, travel agents can easily search for destinations by categories such as 'Family Friendly', 'Adventure', or 'Relaxation'. The app also offers recommendations for attractions, and activities to ensure a well-rounded trip for their clients.
                        <br /><br />
                        The app's AI algorithm analyzes the user's preferences and creates a personalized itinerary that is tailored to each individual's interests and travel preferences. Travel agents can even suggest additions or changes based on their expertise, making the experience even more customized for their clients.
                        <br /><br />
                        Explra is revolutionizing the way travel agents plan trips for their clients by providing an AI-powered platform that saves time and creates unforgettable experiences. So if you're a travel agent looking to take your business to the next level, look no further than Explra - where every trip is personalized, and every itinerary is tailored to perfection.
                        <br /><br />
                        Sign up today for free and start planning your clients' dream trips with ease!
                        </p>    
                        <div className="mt-6">
                            <ExplraButton
                                href='/trip-planner'
                                text="Start using Explra"
                                style="primary"
                                size={4} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-16 pt-12 bg-[#F2EAF5]">
                <div className="container px-4 mx-auto">
                    <ExplraFeatures />    
                </div>
            </div> 
        </div>
    )
}