import { ArrowPathIcon, AtSymbolIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import { Form, useFetcher } from "react-router-dom";
import { postEmailShare } from "services/api";


export default function EmailTrip ({tripId}: {tripId: string}) {
    const [ showingInput, setShowingInput ] = useState(false)
    const fetcher = useFetcher();
    const [formData, setFormData] = useState({
        email: "",
        trip: tripId
    });
    const [loader, setLoader] = useState(false)

    async function handleClick() {
        // Change the colour to white and add an input field
        console.log(showingInput)
        setShowingInput(true)
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoader(true)
        setShowingInput(false)
        postEmailShare(formData).then(() => {
            //setLoader(false)
        })    
    }

    return (
        <div>
            <h5 className="text-l font-semibold text-blue-text leading-relaxed text-base">Send itinerary via email.</h5>            
            { (!loader) ?
                <>
                    <p>Send a copy of this itinerary to your email address for reference</p>
                    <Form method="post" action="/email-trip-plan" onSubmit={handleSubmit}>
                        <input type="hidden" value={tripId} name="tripId" />
                        <input className={`${loader && 'disabled'} border-gray-200 m-2 p-2`} type="email" placeholder="you@youremail.com" name="email" autoFocus={false} onChange={handleChange} />
                    </Form>
                </>
                :
                <p>An email has been sent to <em>{formData.email}</em><br /><span className="text-sm">Dont forget to check your spam.</span></p>
            }
        </div>
        // <button className={`${showingInput ? 'border px-0 py-0 text-gray-500 font-light mx-4 w-3/5' : 
        //                    'bg-explraBtnRed-primary hover:bg-explraBtnRed-secondary px-8 py-4 text-white font-medium'}
        //                    rounded inline-block md:w-auto text-sm leading-normal`}
        //         onClick={handleClick}>
        //     { (showingInput && !loader)  &&
        //         <Form method="post" action="/email-trip-plan" onSubmit={handleSubmit}>
        //             <input type="hidden" value={tripId} name="tripId" />
        //             <input className={`${loader && 'disabled'} border-none m-0 p-0`} type="email" placeholder="you@youremail.com" name="email" autoFocus onChange={handleChange} />
        //         </Form>
        //     }
        //     { (!showingInput && loader) && <><ArrowPathIcon className='inline-block h-6 w-6 text-white animate-spin' /> Email </>}
        //     { (!showingInput && !loader) && <><AtSymbolIcon className='inline-block h-6 text-white' /> Email </> }
        // </button>
    )
}