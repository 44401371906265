import { useState } from "react";

import {
    defer,
    useLoaderData,
    Form,
    redirect,
    useNavigation,
    useActionData,
  } from "react-router-dom";
import { ITrip, ITripCreateProfile, ITripStyle } from 'interfaces/interfaces';
import { createTripProfile, getTripPlan, getTripStyles, getTripProfiles, updateTripProfile } from "services/api";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useDocumentTitle } from "services/setDocumentTitle";
import trackClick from "services/analytics";



export async function loader({ request, params }: {request: any, params: any}) {
    const trip = await getTripPlan(params.tripId)
    // This could return none. 
    //const tripProfile = await getTripProfile(params.tripId)
    const tripStyles = (await getTripStyles()).results
    const tripProfiles = (await getTripProfiles(params.tripId))
    
    const data: ITripCreateProfile[] = (await tripProfiles.json()).results

    let tripProfile: ITripCreateProfile = {
        id: '',
        name: '',
        pace: 1,
        trip: '',
        styles: [],
        created_at: '',
        updated_at: ''
    }

    if (Object.keys(data).length >= 1) {
        tripProfile = data[0]
    }    

    return defer({ trip, tripStyles, tripProfile })
}

export async function action({ request, params}: {request: any, params: any}) {
    const formData = await request.formData();
    let updates = Object.fromEntries(formData);    
    updates.styles = formData.getAll("styles")    
    
    let tripProfile
    const profileId = formData.get('profile-id')
    if(profileId) {
        tripProfile = await updateTripProfile(updates, profileId)
    } else {
        tripProfile = await createTripProfile(updates)
    }
    console.log(tripProfile)
    await tripProfile.json()
    if(tripProfile.ok) {
        return redirect(`/trip-plan/${params.tripId}/rate-activities`)
    }
    const errors = {message: 'Ensure you have made a selection'}
    return errors
    
}


function TravelPreferences() {
    const navigation = useNavigation();
    const errors = useActionData() as any;
    const stepper = [
        'Space it out… This trip is about going with the flow and smelling the roses.', 
        'Keep things at a comfortable pace, not too fast & not too slow', 
        'Cram it all in… I\'ve got things to do and places to see!'
    ]

    const { trip, tripStyles, tripProfile } = useLoaderData() as {trip: ITrip, tripStyles: ITripStyle[], tripProfile: ITripCreateProfile}
    const [stepperText, setStepperText] = useState<string>(stepper[1])
    const [formData, setFormData] = useState<ITripCreateProfile>(tripProfile)
    const [docTitle, setDocTitle] = useDocumentTitle('Travel Preferences')
    

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setStepperText(stepper[Number(value)])
        setFormData((prevFormData) => ({...prevFormData, [name]: Number(value)}))
    }

    trackClick('conversion', 'empty', 'AW-970665941/xXzSCN-4_IYZENXf7M4D')
    
    return (
        <div>
            {/* <PlannerProgress 
                btnDisabled={selectedActivities.length <= 0}
                btnLink={`/trip-plan/${trip.id}/refine`}
                heading={`Itinerary - ${trip.city}, ${trip.country}`}
                subheading={`From: ${trip.date_from} - ${trip.date_to}`} >
                <Itinerary activities={selectedActivities} />
            </PlannerProgress> */}
            
            <section>
                <div className="container px-4 mx-auto">
                    <div className="max-w-lg mx-auto font-light text-center">
                        <h1 className="font-semibold text-2xl">Check In</h1>
                        <p>
                            To tailor your trip, we just need a little information about how you want to travel. <br />
                        </p> 
                        <Form method="post" id="trip-preferences-form">
                            <input type="hidden" name="trip" value={trip.id} />
                            <input type="hidden" name="profile-id" value={formData.id} />
                            <div className='my-6'>
                                <img className="w-full object-cover rounded-lg" 
                                     src="/assets/undraw/undraw_World_re_768g.png" 
                                     alt="" />
                                
                                <h2 className="font-medium text-lg">Pace</h2>
                                <p>When we are planning activities and places for you to see, whats your preference with the pace of your trip?</p>
                                <div className="my-4">
                                    <div className=" w-9/12 text-center mx-auto">
                                        <input 
                                            onChange={handleChange}
                                            value={formData.pace}
                                            name="pace"
                                            type="range" id="pace" min="0" max="2" step="1" className="w-full" />
                                    </div>
                                    <span className=" italic">
                                        {stepperText}
                                    </span>                            
                                </div>
                            </div>
                            <div className='my-12'>
                                <h2 className="font-medium text-lg">Characteristics</h2>
                                <p>
                                    Out of the words listed below, what resonates when thinking about the type of trip you want to have and the places you want to see?
                                </p>
                                <ul className="my-6">
                                {tripStyles.map((style) => 
                                    <li key={style.id}
                                        className="inline-block m-3">
                                        <input 
                                            id={style.id}
                                            name="styles" 
                                            className="hidden peer"
                                            defaultChecked={ tripProfile.styles.filter((s) => s.id === style.id ).length >= 1 }
                                            type="checkbox" value={style.id} />
                                        <label
                                            htmlFor={style.id}
                                            className="bg-white hover:cursor-pointer peer-checked:bg-gray-100 peer-checked:border-gray-200 rounded-full px-4 py-2 shadow-sm border-gray-100 border ">
                                                {style.name}
                                        </label>
                                    </li>
                                )}

                                </ul>
                            </div>
                            {errors?.message &&
                                <div className='mt-2 text-center text-red-600 font-thin'>
                                    <span>{errors.message}</span>
                                </div>
                            }
                            <div className="max-w-3xl mx-auto font-light text-right">
                                <button 
                                    className='bg-explraBtnRed-primary hover:bg-explraBtnRed-secondary disabled:bg-explraBtnRed-secondary inline-block w-full md:w-auto text-white font-medium leading-normal text-sm px-8 py-4 rounded transition duration-20'
                                    >{navigation.state === "submitting" ? <ArrowPathIcon className='h-6 w-6 text-white animate-spin mx-auto' /> : 'Continue'}</button>
                            </div>
                        </Form>         
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TravelPreferences