import { Schedule as ScheduleComponent } from "components/schedule";
import { ISchedule, ITrip, Schedule } from "interfaces/interfaces";
import { Await, useLoaderData } from "react-router-dom";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from "react";
import { PrinterIcon } from "@heroicons/react/24/outline";
import EmailTrip from "components/emailTrip";
import {useAuth} from "services/useAuth";
import SigninPromo from "components/signinPromo";
import { useDocumentTitle } from "services/setDocumentTitle";
import trackClick from "services/analytics";

dayjs.extend(utc)


function ScheduleSkeleton() {
    return (
        <div className="relative mt-8 pb-16">
            <div className="container px-4 mx-auto max-w-xl">
                <div className="animate-pulse">
                    <div className="overflow-hidden rounded-lg transition bg-gray-200 h-72 "></div>
                    {/* <div className="h-72 w-full bg-no-repeat bg-cover my-4 rounded-lg" style={{ backgroundImage: `url(${image})` }}></div> */}
                    <div className="space-y-3">
                        <div className="h-12 bg-gray-200 rounded mt-4"></div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                        <div className="grid grid-cols-5 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// Inside container for the schedule
export default function PageSchedule() {
    const { trip, schedule } = useLoaderData() as {trip: ITrip, schedule: ISchedule[]}
    //const auth = useAuth()
    const [docTitle, setDocTitle] = useDocumentTitle('Trip Schedule')

    async function handlePrint() {
        trackClick('print', 'handlePrint')
        window.print()
    }

    

    return (
        <div>
            <div className="container px-4 mx-auto">
                <div className="max-w-4xl mx-auto text-gray-500 leading-relaxed text-lg">
                    <h2 className='mb-2 mt-12 text-2xl font-heading text-center text-blue-text'>Schedule</h2>
                    <span className="block text-center">Traveling From: {dayjs.utc(trip.date_from).format('D MMM YY')} - {dayjs.utc(trip.date_to).format('D MMM YY')}</span>
                    
                    <React.Suspense fallback={<ScheduleSkeleton />}>
                        <Await resolve={schedule}
                            errorElement={<p className="text-red-500">Could not load content. Please refresh.</p>}
                            >
                                {(resolvedSchedule) => { 
                                    const schedule = new Schedule(resolvedSchedule);
                                    return (
                                        <ScheduleComponent 
                                            activities={schedule.activities}
                                            startDate={schedule.city.date_from}
                                            endDate={schedule.city.date_to}
                                        />
                                    )
                                }}
                        </Await>
                    </React.Suspense>
                </div>
            </div>
            {/* {!auth.isAuthed() && 
                <SigninPromo />
            } */}

            <div className="container px-4 mx-auto max-w-xl text-center print:hidden">
                <div className="border border-gray-300 rounded-md p-4">
                    <EmailTrip
                        tripId={trip.id}
                    />
                </div>
            </div>

            <div className="container mt-6 px-4 mx-auto max-w-xl text-center print:hidden">
                <button className='rounded inline-block md:w-auto px-8 py-4 text-white text-sm font-medium leading-normal bg-explraBtnRed-primary hover:bg-explraBtnRed-secondary'
                        onClick={handlePrint}>
                            <PrinterIcon className='inline-block h-6 text-white' /> Print
                </button>
            </div>
            <div className="container px-4 mt-4 mx-auto max-w-xl text-gray-500 leading-relaxed print:hidden">
                <p className="text-center text-blue-text">
                    Not quite what you were looking for? <a className="underline" href="#">Try a different approach.</a>
                </p>
            </div>
            {/* <div className="container px-4 mx-auto"> */}
                    {/* <h1>Traveling From: {dayjs.utc(schedule.start_date).format('D MMM YY')} - {dayjs.utc(schedule.end_date).format('D MMM YY')}</h1> */}
                    {/* <div className="max-w-3xl mx-auto font-light text-blue-text leading-relaxed text-lg" style={{
                        whiteSpace: "pre-line",
                        verticalAlign: "bottom"
                    }}>
                        <div className="rounded-lg border p-2 mt-2 mb-6">
                            Your calendar has been generated. Mark days as "lounge" days to keep a day free from activities. 
                            <br />
                            Use the slider to do more or less each day.
                            <br />
                            Enter your hotel address and arrival/departure times for a more accurate itinerary.
                        </div>
                    </div> */}
                {/* </div> */}
                {/* <div>
                    <label>Enter your accomodation address for better results</label>
                    <input type="text" placeholder="101 Explore St, Yonder" />
                    <label>What time do you like to start your day?</label>
                    <input type="time" value="07:00" />
                    <label>Relaxed/Compact schedule</label>
                    <select>
                        <option>Relaxed</option>
                        <option>Compact</option>
                    </select>
                </div> */}

                {/* <div className="container px-4 mx-auto">
                    <div className="max-w mx-auto text-gray-500 leading-relaxed text-lg" style={{
                        whiteSpace: "pre-line",
                        verticalAlign: "bottom"
                    }}>
                        <Calendar 
                            activities={schedule.activities}
                            startDate={schedule.start_date}
                            endDate={schedule.end_date}
                        />
                    </div>
                </div> */}
        </div>
            
    )
}