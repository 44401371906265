import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { ITripActivity } from "interfaces/interfaces";
import { useState } from "react";
import parse from 'html-react-parser';

export default function SingleActivity ({tripActivity, handleClick} : {tripActivity: ITripActivity, handleClick: any}) {
    const [loading, setLoadingState] = useState(false)

    function handle(tripActivity: ITripActivity) {
        setLoadingState(true)
        handleClick(tripActivity)
        //setLoadingState(false)
    }

    const image = tripActivity.activity.images[0]
    return (
        <div className="p-2 relative my-6 border-b" key={tripActivity.id}>
            <div className="text-right text-blue-500 absolute top-2 right-2">
                <button onClick={() => handle(tripActivity)}>
                    <ArrowPathIcon className={`h-6 ${loading && 'animate-spin' }`} />
                </button>
                {/* <a href="#" className="mt-1 inline-block text-explraRed underline hover:no-underline after:content-['[_↗]']">
                    Replace
                </a> */}
            </div>
            <div className="overflow-hidden transition flex gap-2 text-pretty">
                <div className="flex-none">
                    <img
                        alt=""
                        src={`${image.photo_url}`}
                        className="h-24 object-cover rounded-lg aspect-square"
                        />
                </div>
                <div className="flex">
                    <span className="mt-0.5 text-lg text-gray-900 flex items-center">
                        {tripActivity.activity.title}
                    </span>
                    
                </div>
            </div>
            <div className="bg-white">                               
                <p className="mt-2 text-sm/relaxed text-gray-700">
                    {tripActivity.activity.description}
                </p>
                <ul className="list-disc mt-2 text-sm/relaxed text-gray-500">
                    {tripActivity.activity.tags.map((tag, i) => 
                        <li key={tag.name} className="inline-block mr-1">{tag.name}{tripActivity.activity.tags.length - 1 != i && ','} </li>
                        
                        
                    )}
                </ul>

                {/* <div className="stroke-1 text-gray-300 grid grid-cols-2 border-t mt-4 pt-4">
                    <div className="">
                        <span
                            //onClick={() => handleVote(activity, false)} 
                            className="hover:text-gray-400 hover:cursor-pointer inline-block w-12">
                            <HandThumbDownIcon/>
                        </span>
                    </div>
                    <div className="">
                        <span 
                            //onClick={() => handleVote(activity, true)}
                            className="hover:text-gray-400 hover:cursor-pointer inline-block w-12">
                            <HandThumbUpIcon/>
                        </span>
                    </div>
                </div> */}
            </div>
            
            {tripActivity.activity.website ? <a  href={tripActivity.activity.website} target="_blank">Official Website</a> : ''}
            {image.photo_attribution && <span className="text-sm font-thin italic text-left block mt-4">Photo by: {parse(`${image.photo_attribution}`)}</span>}
        </div>
    )
}