import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Scroll the user to the top of the page on each transition.
 * @returns 
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
        top: 0, 
        left: 0,
        behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}