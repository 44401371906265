import { GlobeAsiaAustraliaIcon } from "@heroicons/react/24/solid";
import PlaceCard from "components/placeCard";
import { useDocumentTitle } from "services/setDocumentTitle";


// NOTE: This could actually just be a feed thats consumed from the api, no need for any AI here, 
// Google Alerts or Google News Feed could just be saved into the DB in this format and tagged with a city and country.
export default function DiscoverPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Discover')
    const places = [
        {
            id: 1,
            title: "A Guide to Experiencing the Fun and Stylish Side of the City",
            link: "https://guide.michelin.com/us/en/article/travel/my-tokyo-by-kiko-mizuhara",
            related_to: "",
            image: "https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2024/06/15/3b4d12d412ae43ffb26ac72e07eda29c_Kiko_Mizuhara_Headshot_2024_Edit.jpg",
            summary: "As you explore the city, let Kiko Mizuhara's favorite spots guide you to some of the best-kept secrets and must-visit locations.",
            date_published: "",
            linkName: "Visit michelin.com"
        },
        {
            id: 2,
            title: "Travel Bucket List for 2024",
            link: "https://www.theblondeabroad.com/travel-bucket-list-for-2024/",
            related_to: "",
            image: "https://www.theblondeabroad.com/wp-content/uploads/2024/01/2024-Bucket-List-Pin.jpg",
            summary: "From jet-setting around the globe to places where your favorite TV show or film is set to renting vacation homes and taking that long overdue girls’ trip, here is my travel bucket list for 2024!",
            date_published: "",
            linkName: "Visit theblondeabroad.com"
        },
        {
            id: 3,
            title: "Choosing the Perfect European Spring Getaway",
            link: "https://www.wanderingearl.com/choosing-the-perfect-european-spring-getaway/",
            related_to: "",
            image: "https://www.wanderingearl.com/wp-content/uploads/2024/04/Copenhagen-photo-1536x1024.jpg",
            summary: "Spring in Europe is a season of renewal, with vibrant colors and activities as the continent awakens from winter. Choosing a destination for a spring vacation can be challenging, but this guide provides a comprehensive overview to help you find the ideal European city for your springtime adventure.",
            date_published: "",
            linkName: "Visit wanderingearl.com"
        },
        {
            id: 4,
            title: "The Ultimate Guide to Monsoon Travel in Goa",
            link: "https://the-shooting-star.com/goa-monsoon-travel-guide/",
            related_to: "",
            image: "https://the-shooting-star.com/wp-content/uploads/2024/06/img_3562-1.jpg",
            summary: "The magic of Goa in the monsoon can still be a treat for any nature lover. Personally, I love sleeping to the show of lightening, thunder, and the sweet feeling of lashing tropical rain.",
            date_published: "",
            linkName: "Visit the-shooting-star.com"
        },
        {
            id: 5,
            title: "Travel Guide: Coventry",
            link: "https://lovetravellingblog.com/2024/06/08/travel-guide-coventry/",
            related_to: "",
            image: "https://lovetravellingblog.com/wp-content/uploads/2024/05/pxl_20240427_110505603_original-1.jpg?w=600",
            summary: "Coventry is the perfect destination for a city break as it's easy to get to and close to the motorway network.",
            date_published: "",
            linkName: "Visit lovetravellingblog.com"
        },
        {
            id: 6,
            title: "Takaka: a golden getaway to Golden Bay",
            link: "https://youngadventuress.com/2024/06/golden-bay.html",
            related_to: "",
            image: "https://youngadventuress.com/wp-content/uploads/2024/05/136A9787-copy.jpg",
            summary: "There aren't many places in New Zealand that make me question my choice to live in Wānaka, but Golden Bay definitely did. Warm, sunny, and most importantly, cheap, Golden Bay piqued my interest as somewhere I could easily live comfortably.",
            date_published: "",
            linkName: "Visit youngadventuress.com"
        }
    ]
    return (
        <div>
            <div className="bg-white mx-auto relative container">
                <div className="">
                    <div>
                    <section className="bg-gradient-to-r from-[#EFC7E6] to-[#95ADDD] overflow-hidden relative rounded-lg">
                        <div className="bg-fixed absolute blur-[8px] min-h-full min-w-full " 
                            style={{
                                backgroundImage: `url('/assets/images/backgrounds/world-map-hex.svg')`}}
                            >
                        </div>
                        <section className="relative pb-4 ">
                            <div className="relative container px-4 mx-auto text-center">
                                <div className="text-7xl font-extrabold my-20">
                                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#EFC7E6] from-20% to-[#522AC4]">
                                        Be Inspired
                                    </span>
                                </div>
                                {/* <h2 className="mt-4 mb-8 lg:mb-12 text-white text-4xl lg:text-6xl font-semibold">
                                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-explraBlue-primary to-violet-500">
                                        
                                    </span>
                                </h2> */}
                                <p className="max-w-2xl mx-auto pb-24 lg:mb-12 text-[#552ebf] text-3xl font-semibold">
                                    Discover articles and blogs from around the web related to your next trip.
                                </p>
                            </div>
                            
                        </section>
                    </section>
            
                </div>
            </div>
            <section className="relative -mt-20 z-20">
                <div className="relative container mx-auto max-w-3xl">
                    <div className="mx-6 bg-white rounded-md shadow-lg py-6">
                        <div className="p-4">
                            <div className="md:rounded-full md:flex mx-4 md:mx-auto">
                                <div className="relative bg-transparent border-transparent md:inline-block w-full">
                                    <label className='flex items-center mb-2'>
                                        <span className="pointer-events-none bg-transparent border-transparent z-10">
                                            <GlobeAsiaAustraliaIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        <span className='ml-2 font-medium'>
                                            Destination
                                        </span>
                                    </label>
                                    <div className='ml-0 md:mx-7'>
                                        <input type="text" className="w-full" placeholder="London, UK" />
                                        <span className="text-sm italic">
                                            Enter the country or city you are interested in to get relevant & up-to-date articles and blogs.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
            <section className="relative py-20">
            <div className="container px-4 mx-auto flex flex-wrap -m-3">
                <div className="w-full">
                    <h1 className="inline-block text-2xl font-semibold font-heading">Recent Articles</h1>
                    {/* <a className="font-medium text-sm text-explraRed underline hover:no-underline" href={urlLink}>View more</a> */}
                    <p className="mb-4 text-gray-500 leading-relaxed">Consume the most recent articles from travel bloggers around the web</p>
                </div>
                <div className="grid grid-rows-6 sm:grid-rows-2 grid-flow-col gap-4">
                    {places.map(place => {
                    return (
                        <PlaceCard item={place} />
                    )
                })}
                </div>
            </div>
        </section>
        </div>      
    </div>
    )
}