import { Link } from "react-router-dom";

const primary = "bg-explraBtnRed-primary hover:bg-explraBtnRed-secondary disabled:bg-explraBtnRed-secondary"
const secondary = "bg-explraBlue-primary hover:bg-explraBlue-secondary "


export default function ExplraButton({ href, text, style, additional, size, clickAction, disabled=false, reloadDocument=false }: { href?: string, text: string, style: string, additional?: string, size?: number, clickAction?: React.MouseEventHandler<HTMLButtonElement>, disabled?: boolean , reloadDocument?: boolean}): any {
    let styleClasses = additional || ""
    if (style === "primary") {
        styleClasses = styleClasses + ' ' + primary
    } else {
        styleClasses = styleClasses + ' ' + secondary
    }

    if (disabled === true) {
        styleClasses = styleClasses + ' cursor-not-allowed '
        href = ''
    }

    if (!size) {
        size = 4
    }
    const px = size * 2
    const classes = `${styleClasses} inline-block w-full md:w-auto text-white font-medium leading-normal text-sm px-${px} py-${size} rounded transition duration-20`

    if (href) {
        return (
            <Link
                className={classes}
                reloadDocument={reloadDocument}
                to={href}>{text}</Link>
        )
    }
    else {
        return (
            <button
                className={classes}
                onClick={clickAction}
                disabled={disabled}>{text}</button>
        )

    }
}