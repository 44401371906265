import { Outlet } from "react-router-dom";
import Header from "components/header"
import Footer from "components/footer"
import ScrollToTop from "components/scrollToTop";
import Analytics from "components/googleAnalytics";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthProvider from "services/authProvider";


const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''

export default function Root() {

  return (
    //<AuthProvider>
    //  <GoogleOAuthProvider clientId={`${clientId}`}>
        <>
          <ScrollToTop />
          <Header />
          <div style={{
            paddingTop: '120px'
          }}>
            <Outlet />
          </div>
          <Footer />
          {process.env.NODE_ENV === 'production' &&
          // Must be last so document.title can be set in any <Outlets />

              <Analytics />

          }
        </>
    //  </GoogleOAuthProvider>
    //  </AuthProvider>
  );
}