import { GoogleMap } from 'components/map';
import SigninPromo from 'components/signinPromo';
import { ISchedule, IScheduleActivity, ITrip, Schedule } from 'interfaces/interfaces';
import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { useDocumentTitle } from 'services/setDocumentTitle';
import {useAuth} from 'services/useAuth'


export default function PageMapSchedule() {
    const { trip, schedule } = useLoaderData() as {trip: ITrip, schedule: ISchedule[]}
    //const auth = useAuth();
    const [docTitle, setDocTitle] = useDocumentTitle('Travel Map - Schedule')
    
    return (
        <div>
            {/* {auth.isAuthed() ? */}
            <React.Suspense fallback={`Loading ...`}>
                <Await resolve={schedule}
                    errorElement={<p className="text-red-500">Could not load content. Please refresh.</p>}
                    >
                        {(resolvedSchedule) => { 
                            const schedule = new Schedule(resolvedSchedule);
                            return (
                                <div className='container px-4 mx-auto my-4 text-center print:hidden'>
                                    <GoogleMap activities={schedule.activities}/>
                                </div>
                        )
                        }}
                </Await>
            </React.Suspense>
            {/* :
            <SigninPromo />
            } */}
        </div>
    )
}