import { ITrip, ITripResponseProfile, ITripActivity } from "interfaces/interfaces"
import { Await, defer, redirect, useLoaderData } from "react-router-dom"
import { getTripActivities, getTripPlan, getTripProfiles, postSimilarRatings } from "services/api"
import React from "react"
import { RateBinary } from "components/rateBinary"
import { useDocumentTitle } from "services/setDocumentTitle"


export async function loader({ request, params }: {request: any, params: any}) {
    // TODO: replace with TripActivities instead of activities.
    // Needs the API endpoint updated as well.
    // TODO: Should prbably be Generate Activities not Get Similar
    //const activities = (await getTripActivities({tripId: params.tripId, status: 0})).results
    // const activities = (await getTripActivities({tripId: params.tripId, status: 0}))
    // const activities = postSimilarRatings(params.tripId, {exclude: [], activities: []}).then((res) => {
    //     console.log(res)
    //     // Redirect if empty returns, since this means we have completed this step from the API
    //     if (Object.keys(res).length === 0) {
    //         console.log("Redirecting")
    //         return redirect(`/trip-plan/${trip.id}/activities`)
    //     }
    //     return res
    // })
    const trip = await getTripPlan(params.tripId)
    const tripProfiles = (await getTripProfiles(params.tripId))
    const tripProfile: ITripResponseProfile = (await tripProfiles.json()).results[0]
    
    return defer({ trip, tripProfile })
}

export function ProfileLoader () {
    return (
        <div className="p-2 relative">
            <div className="animate-pulse duration-100">
                <p className="font-semibold text-lg">Preparing the cabin for takeoff ...</p>
                <p className="italics font-thin italic">Sit back and relax, suggested activities will be with you shortly.</p>
                <div className="overflow-hidden rounded-lg transition bg-gray-200 h-96 "></div>
                <div className="bg-white p-4 sm:p-6">
                    <div className="space-y-3">
                        <div className="h-6 bg-gray-200 rounded"></div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                        <div className="grid grid-cols-5 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function RateActivities () {
    const { trip, tripProfile } = useLoaderData() as {trip: ITrip, tripProfile: ITripResponseProfile}
    const [docTitle, setDocTitle] = useDocumentTitle('Rate Activities')
    
    return (
        <div className="">
            <section className="">
                <div className="container px-4 mx-auto">
                    <div className="max-w-lg mx-auto font-light text-center">
                        <h1 className="font-semibold text-2xl">Tickets Please</h1>
                        <p>
                            Finding your next travel adventure is as easy as yes or no. <br /><br />
                            Help us understand the places you would like to visit and we will generate a more refined list
                            of activites and places to see in the next step.
                        </p> 
                        <div className="mt-4">
                            <RateBinary trip={trip} activitiesToRate={tripProfile.total_trip_activities} />
                        </div>
                        
                    </div>
                </div>
            </section>
        </div>
    )
}