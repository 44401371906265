import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function Analytics() {
  const location = useLocation();
  useEffect(() => {
    console.log("Sending GA", location.pathname + location.search, [] , document.title)
    ReactGA.pageview(location.pathname + location.search, [] , document.title);
    //ReactGA.pageview(location.pathname);
    //ReactGA.ga('send', 'pageview', location.pathname);
    //console.log('Sending to GA', location.pathname + location.search, [] ,document.title)
  }, [location]);
  return null
}