export default function Footer() {
    return (
        <section className="py-20">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-center -mx-4">
                    <div className="w-full lg:w-2/6 px-4">
                        <a className="inline-block mb-6 text-gray-900 text-lg font-semibold" href="/">
                            <img className="h-7" src="/assets/logo/logo-explra-red.svg" alt="" width="auto" />
                        </a>
                    </div>
                    <div className="w-full px-4">
                        <div className="flex flex-wrap items-center justify-end">
                            <ul className="w-full lg:w-auto inline-flex flex-wrap mb-4 lg:mb-0 md:mr-6 lg:mr-12">
                                <li className="mr-12 mb-2 md:mb-0">
                                    <a className="text-sm font-medium" href="/itineraries/">Itineraries</a>
                                </li>
                                <li className="mr-12 mb-2 md:mb-0">
                                    <a className="text-sm font-medium" href="/blog/">Blog</a>
                                </li>
                                <li className="mr-12 mb-2 md:mb-0">
                                    <a className="text-sm font-medium" href="/Discover/">Discover - Be Inspired</a>
                                </li>
                                <li className="mr-12 mb-2 md:mb-0">
                                    <ul>
                                        <li>
                                            <a className="text-sm font-medium" href="/trip-planning/">Trip Planning</a>
                                        </li>
                                        <li>
                                            <a className="text-sm font-medium" href="/travel-research/">Travel Research</a>
                                        </li>
                                        <li>
                                            <a className="text-sm font-medium" href="/itinerary-generation/">Itinerary Generation</a>
                                        </li>
                                        <li>
                                            <a className="text-sm font-medium" href="/travel-agents/">Travel Agents</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mr-12 mb-2 md:mb-0">
                                    <ul>
                                        <li><a className="text-sm font-medium" href="/terms-of-service/">Terms of Service</a></li>
                                        <li><a className="text-sm font-medium" href="/privacy-policy/">Privacy</a></li>
                                    </ul>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-none">
                        <a href="/app">
                            <img className="" src="/assets/images/download-on-app-store.svg" />
                        </a>
                    </div>
                    <div className="flex-none">
                        <div className="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6672b0c3ddcf022bb9c019d4" data-style-height="52px" data-style-width="100%">
                            <a href="https://uk.trustpilot.com/review/explra.com" target="_blank" rel="noopener">Trustpilot</a>
                        </div>
                    </div>
                    <div className="flex-auto">

                    </div>
                    
                </div>
            </div>
        </section>
    )
}