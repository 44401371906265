import ExplraFeatures from "components/explraFeatures"
import HomeHeader from "components/homeHeader"
import SearchDestination from "components/searchDestination"



export default function TripPlanPage() {

    return (
        <div>
            <div className="bg-white mx-auto relative container">
                <div className="">
                    <HomeHeader />
                </div>
                <section className="relative -mt-20 z-20">
                    <div className="relative container mx-auto max-w-3xl">
                        <div className="mx-6 bg-white rounded-md shadow-lg py-6">
                            <div className="p-4">
                                <SearchDestination />
                            </div>
                        </div>
                    </div>
                </section>   
            </div>      
            <div className="mx-auto relative container mt-12">
                    <ExplraFeatures />
            </div>   
        </div>
    )
}