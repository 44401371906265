import { useRouteError } from "react-router-dom";
import { useDocumentTitle } from "services/setDocumentTitle";

export default function ErrorPage() {
    const error: any = useRouteError();
    const [docTitle, setDocTitle] = useDocumentTitle(`Error: ${error.status}`)

    return (
    <section className="py-20">
        <div className="container px-4 mx-auto">
        <div className="max-w-4xl h-64 md:h-112 mb-12 mx-auto">
            <img className="h-full w-full rounded-xl object-cover object-top" src="https://images.unsplash.com/photo-1524673168553-75e292ad3312?ixlib=rb-1.2.1&amp;ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt="" />
        </div>
        <div className="text-center">
            <span className="inline-block mb-8 text-xs font-semibold text-gray-500 uppercase">Error {error.status}</span>
            <h2 className="mb-10 text-4xl font-semibold font-heading">Oh no!</h2>
            <p className="mb-12 text-xl text-gray-500">Something has gone wrong, but we are looking into it.</p>
        </div>
        </div>
    </section>
    );
}