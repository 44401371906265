import { Schedule as ScheduleComponent } from "components/schedule";
import TripHeader from "components/tripHeader";
import { ISchedule, ITrip } from "interfaces/interfaces";
import { NavLink, Outlet, defer, useLoaderData } from "react-router-dom";
import { getSchedule, getTripPlan } from "services/api";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AudibleAd from "components/audibleAd";
import { exchangeToken } from "services/api";
import Auth from "services/auth";
import trackClick from "services/analytics";
import {useAuth} from "services/useAuth";
import {CalendarDaysIcon, GlobeAsiaAustraliaIcon} from "@heroicons/react/24/outline";
import { useDocumentTitle } from "services/setDocumentTitle";

dayjs.extend(utc)

interface User {
    username: string
    first_name: string
    last_name: string
    email: string
}
interface Credentials {
    token: string
    user: User
}

export async function loader({ request, params }: {request: any, params: any}) {
    // const url = new URL(request.url);
    // const destination: string | null = url.searchParams.get("destination")
    // const from: string | null = url.searchParams.get("from")
    // const to: string | null = url.searchParams.get("to")
    const trip = await getTripPlan(params.tripId)
    const schedule = getSchedule(params.tripId)
    //const tripIntro = await getInto(params.tripId)
    return defer({ trip, schedule })
}

function ScheduleSkeleton() {
    return (
        <div className="relative mt-8 pb-16">
            <div className="container px-4 mx-auto max-w-xl">
                <div className="animate-pulse">
                    <div className="overflow-hidden rounded-lg transition bg-gray-200 h-72 "></div>
                    {/* <div className="h-72 w-full bg-no-repeat bg-cover my-4 rounded-lg" style={{ backgroundImage: `url(${image})` }}></div> */}
                    <div className="space-y-3">
                        <div className="h-12 bg-gray-200 rounded mt-4"></div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                        <div className="grid grid-cols-5 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// Container for the schedule section of the trip builder.
export default function TripSchedule() {
    const { trip, schedule } = useLoaderData() as {trip: ITrip, schedule: ISchedule[]}
    //const [docTitle, setDocTitle] = useDocumentTitle('Trip Schedule')
    const image = `${trip.destination.image}&auto=format&fit=crop&w=1024&q=80`

    const navLinkStyle = 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group hover:text-explraBtnRed-secondary'
    
    return (
        <div>
            <div>
            <TripHeader 
                city={trip.destination.city}
                country={trip.destination.country}
                image={image}
                tagline="Find out what awaits ..."
            />
            </div>
            <section className="font-light text-base">
                <div className="container px-4 mx-auto">
                    <div className="max-w-3xl mx-auto text-blue-text leading-relaxed whitespace-pre-line" style={{
                        verticalAlign: "bottom"
                    }}>
                        <p>
                            {trip.destination.description}
                        </p>
                    </div>
                </div>
                <AudibleAd trackingId={trip.id} />
                <div>
                    <div className="container px-4 mx-auto max-w-xl ">
                        <nav className="border-b border-gray-300">
                            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                                {/* TODO: https://reactrouter.com/en/main/start/tutorial#active-link-styling */}
                                <li className="me-2">
                                    {/* TODO: onclick prevent scroll reset. */}
                                    <NavLink 
                                        // className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group active:text-explraRed"
                                        className={({ isActive, isPending }) => isPending ? navLinkStyle : isActive ? `text-explraRed ` + navLinkStyle: navLinkStyle}
                                        relative='route' to={`schedule`}>
                                            <CalendarDaysIcon className='inline-block h-6 pr-2' />Schedule
                                    </NavLink>
                                </li>
                                <li className="me-2">
                                    <NavLink className={({ isActive, isPending }) => isPending ? navLinkStyle : isActive ? `text-explraRed ` + navLinkStyle: navLinkStyle}
                                             relative='route' to={`map`}>
                                        <GlobeAsiaAustraliaIcon className='inline-block h-6 pr-2' />Map
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                        <Outlet />
                    </div>
                </div>
                    
            </section>
        </div>
    )
}