export default function ExplraFeatures() {
    return (
        <section className="relative">
            <div className="container px-4 mx-auto">
                <div className="max-w-2xl mb-16 mx-auto text-center">
                    <h2 className="text-2xl font-medium mb-6">
                        Travel like never before & ignite your wanderlust</h2>
                    <p className="max-w-2xl mx-auto text-lg">                        
                        Build your personally curated travel plan effortlessly! 
                        Using AI and your travel profile we pick events and activities to tailor-make your perfect travel itinerary.
                        {/* Explra offers bespoke event and activity suggestions based on your unique travel profile. Say goodbye to generic itineraries! */}
                    </p>
                </div>
                <div className="flex flex-wrap -mx-3 -mb-10 lg:-mb-16">
                    <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-10 lg:mb-16">
                        <div className="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                            <span className="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full bg-blue-400 text-white"></span>
                            <h3 className="mb-6 text-2xl font-semibold font-heading">Trip Planning</h3>
                            <p className="text-gray-500">Generating your personal itinerary is as simple as filling out a short travel profile and rating "yes" or "no" to generated activities and events.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-10 lg:mb-16">
                        <div className="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                            <span className="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full bg-violet-500 text-white"></span>
                            <h3 className="mb-6 text-2xl font-semibold font-heading">Save your itinerary for offline</h3>
                            <p className="text-gray-500">Take your itinerary with you. We know internet can be difficult when traveling - Email or print your itinerary once its generated to keep for reference.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-10 lg:mb-16">
                        <div className="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                            <span className="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full bg-red-500 text-white"></span>
                            <h3 className="mb-6 text-2xl font-semibold font-heading">Update your plan</h3>
                            <p className="text-gray-500">Update your plan once its generated. Dont like all the suggestions, feel free to swap and update whatever you nee and add your own must see destinations to your plan.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-10">
                        <div className="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                            <span className="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full bg-yellow-400 text-white"></span>
                            <h3 className="mb-6 text-2xl font-semibold font-heading">Official Links to activities</h3>
                            <p className="text-gray-500">Each itinerary provides official partnerlinks should you wish to book ahead or get more information.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-10">
                        <div className="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                            <span className="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full bg-green-500 text-white"></span>
                            <h3 className="mb-6 text-2xl font-semibold font-heading">Smart Scheduling</h3>
                            <p className="text-gray-500">Explra's intelligent scheduling engine organises your activities to minimize travel time - more time for fun and less time on the road!</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-10">
                        <div className="relative pt-16 pb-12 px-8 bg-gray-50 rounded-lg text-center hover:bg-white hover:shadow-2xl">
                            <span className="absolute top-0 inset-x-0 -mt-6 flex justify-center items-center w-16 h-16 mx-auto rounded-full bg-gray-900 text-white"></span>
                            <h3 className="mb-6 text-2xl font-semibold font-heading">Detailed Map and Transport Instructions</h3>
                            <p className="text-gray-500">Never feel lost in an unknown city. Get precise directions to each activity or event with all your activities viewable on a dedicated map.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}