import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import parse from 'html-react-parser';
import { IScheduleActivity } from 'interfaces/interfaces';
dayjs.extend(utc)
const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export default function ScheduleDay({date, activities, idx, weather}: {date: Date, activities: IScheduleActivity[], idx: number, weather: any }) {

    if(activities.length === 0) {
        return null
    }
    
    return (
            <div className="">
                <h2 className="text-xl font-semibold font-heading text-blue-text">Day {idx} <span className='text-lg font-thin'>({dayOfWeek[dayjs(date).day()]}, {dayjs(date).format('MMMM D')})</span></h2>
                    <div className="h-72 w-full bg-no-repeat bg-cover mt-4 rounded-lg" style={{ backgroundImage: `url(${activities[0].activity.activity.images[0].photo_url})` }}></div>
                    {activities[0].activity.activity.images[0].photo_attribution && <span className="mb-4 text-sm font-thin italic text-left block">Photo attribution: {parse(`${activities[0].activity.activity.images[0].photo_attribution}`)}</span>}
                    {activities.map((activity) => 
                        <div>
                            <div className='font-light text-blue-text leading-relaxed text-base mt-2 mb-8'>
                                <span className='inline-block mt-1 mb-4 text-l font-semibold'>
                                    {activity.activity.activity.title}
                                </span>
                                {/* <ul className="list-disc mt-2 text-sm/relaxed text-gray-700">
                                {activity.tags.map((tag, i) => 
                                    <li className="inline-block mx-1">{tag.name}{activity.tags.length - 1 != i && ','} </li>                                    
                                )}
                            </ul> */}
                                {' '}
                                <span className='inline-block mt-1 mb-4 text-l font-light'>
                                    {activity.activity.activity.min_time_spend && `(Suggested time to spend: ${activity.activity.activity.min_time_spend} - ${activity.activity.activity.max_time_spend} hours)`}
                                    {/* (Suggested {dayjs.utc(activity.suggested_start_date).format('ha')} - {dayjs.utc(activity.suggested_end_date).format('ha')}) */}
                                </span>
                                <p>{activity.activity.activity.description}</p>
                                {activity.activity.activity.meta.opening_hours ?
                                    <div className='flex justify-between mt-2'>
                                        <div className='inline-block'>
                                            <span className='font-normal'>Opening Hours</span>
                                            <ul className='list-disc ps-6'>
                                                {activity.activity.activity.meta.opening_hours.weekday_text.map((hours: string) => 
                                                    <li><span className="block">{hours}</span></li>
                                                )}
                                            </ul>
                                            {/* <span>Open from: {activity.open_time} - {activity.close_time}</span> */}
                                        </div>
                                        {/* <div className='text-right'>
                                            {activity.cost ? <span className='inline-block align-bottom'>Estimated Costs: ${activity.cost}</span>: ''}
                                        </div> */}
                                    </div>
                                : ''}
                                {activity.activity.activity.meta.website ?
                                    <a className="text-figmaBlue-text mt-4 inline-block text-0A2463 underline hover:no-underline after:content-['[_↗]']" 
                                       href={activity.activity.activity.meta.website}
                                       target="_blank"
                                       >
                                            Website
                                        </a>
                                    
                                    : ''       
                                }
                            </div>
                        </div>
                    )}
                
            </div>                      
    )
}