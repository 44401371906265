import { c } from 'msw/lib/glossary-de6278a9'
import { getTripActivities } from 'services/api'
import { is, object, number, string, nonempty, pattern, date } from 'superstruct'

export const Destination = object({
    country: nonempty(string()),
    city: nonempty(string()),
})

export const TripPlan = object({
    destination: Destination,
    date_from: pattern(string(), /\d{4}-\d{2}-\d{2}/),  // 2022-02-02
    date_to: pattern(string(), /\d{4}-\d{2}-\d{2}/),
})

export interface IDestination {
    country: string
    city: string
    image: string
    description: string
}

export interface IPostDestination {
    country: string
    city: string
}
export interface IPostTripPlan {
    destination: IPostDestination
    date_from: string
    date_to: string
}
export interface IResponseTripPlan {
    id: string
    date_from: string
    date_to: string
    country: string
    city: string
    created_at: string
    updated_at: string
}

export interface ICard {
    id: number
    title: string
    link: string
    related_to: string
    image: string
    summary: string
    date_published: string
    linkName?: string
}

export interface IResearch {
    id: number
    title: string
    link: string
    related_to: string
    image: string
    summary: string
    date_published: string
}

export interface IRating {
    id: string
    rating: number
    activity: string
    created_at: string
    updated_at: string
}

// Response from the API
export interface IAPIActivityOLD {
    id: string
    activity: IActivity
    title: string
    description: string
    website: string
}

// Extended interface for Activities
export interface IActivityOLD extends IAPIActivityOLD {
    selected: boolean
    rating: number // Rating from 0 to 5, best - wrost
    favourite: boolean
}

export interface ITripActivity {
    id: string
    rating?: IRating
    activity: IActivity
    status: number
    trip: string
    created_at: string
    updated_at: string
}
class TripActivity {
    public id: string
    public rating?: IRating
    public activity: IActivity
    public status: number
    public trip: string
    public created_at: string
    public updated_at: string

    constructor(model: ITripActivity) {
        this.id = model.id
        this.rating = model.rating
        this.activity = new Activity(model.activity)
        this.status = model.status
        this.trip = model.trip
        this.created_at = model.created_at
        this.updated_at = model.updated_at
    }
}


export interface IActivityTags {
    name: string
}
class ActivityTag {
    name: string
    constructor(model: IActivityTags) {
        this.name = model.name
    }
}

export interface IActivityImage {
    id: string
    photo_url: string
    photo_attribution: string[]
}
export interface IActivity {
    id: string
    country: string
    city: string
    images: IActivityImage[]
    title: string
    description: string
    website: string
    tags: IActivityTags[]
    max_time_spend: number
    min_time_spend: number
    meta: IActivityMeta
}

class Activity {
    public id: string
    public country: string
    public city: string
    public images: IActivityImage[]
    public title: string
    public description: string
    public website: string
    public tags: IActivityTags[]
    public meta: IActivityMeta
    public max_time_spend: number
    public min_time_spend: number

    constructor(model: IActivity) {
        this.id = model.id
        this.country = model.country
        this.city = model.city
        this.title = model.title
        this.images = model.images.map(image => new ActivityImage(image))
        this.description = model.description
        this.website = model.website
        this.tags = model.tags.map(tag => new ActivityTag(tag))
        this.max_time_spend = model.max_time_spend
        this.min_time_spend = model.min_time_spend
        this.meta = new ActivityMeta(model.meta)
    }
}

export interface IActivityMeta {
    website: string | null
    address: string
    phone: string | null
    latitude: number
    longitude: number
    opening_hours: OpeningHours | null
    activity: string
}

class ActivityMeta {
    public website: string | null
    public address: string
    public phone: string | null
    public latitude: number
    public longitude: number
    public opening_hours: OpeningHours | null
    public activity: string

    constructor(model: IActivityMeta) {
        this.website = model.website
        this.address = model.address
        this.phone = model.phone
        this.latitude = model.latitude
        this.longitude = model.longitude
        this.opening_hours = model.opening_hours !== null ? new OpeningHours(model.opening_hours) : null
        this.activity = model.activity
    }
}

export interface ITripIntro {
    id: string
    city: string
    country: string
    created_at: string
    updated_at: string
    description: string
    image: string
}

export interface ITrip {
    id: string
    date_from: string
    date_to: string
    destination: IDestination 
    created_at: string
    updated_at: string
}
export interface ITripResponse {
    id: string
    date_from: Date
    date_to: Date
    destination: IDestination 
    created_at: string
    updated_at: string
}

class Trip {
    public id: string
    public date_from: Date
    public date_to: Date
    public destination: IDestination 
    public created_at: string
    public updated_at: string

    constructor(model: ITrip) {
        this.id = model.id
        this.date_from = new Date(model.date_from) // YYYY-MM-DD
        this.date_to = new Date(model.date_to) // YYYY-MM-DD
        this.destination = model.destination
        this.created_at = model.created_at
        this.updated_at = model.updated_at
    }
}

enum ActivityTypeEnum {
    activity = 'activity',
    travel = 'travel'
}

class OpeningHours {
    public weekday_text: string[]

    constructor(model: OpeningHours) {
        this.weekday_text = model.weekday_text
    }
}

class ActivityImage {
    public id: string
    public photo_url: string
    public photo_attribution: string[]

    constructor(model: IActivityImage) {
        this.id = model.id
        this.photo_url = model.photo_url
        this.photo_attribution = model.photo_attribution
    }
}

// class XScheduleActivity {
//     public title: string
//     public id: string
//     public suggested_start_date: number
//     public suggested_end_date: number
//     public description: string
//     public type: ActivityTypeEnum
//     public images: ActivityImage[]
//     // public recommended_time_spent: number
//     public max_time_spend: number
//     public min_time_spend: number
//     public booking_link: string | undefined
//     public cost: number
//     public cluster: number
//     public distance: number
//     public duration: number
//     public opening_hours: OpeningHours | null

//     constructor(model: XScheduleActivity) {
//         this.title = model.title
//         this.id = model.id
//         this.suggested_start_date = model.suggested_start_date * 1000
//         this.suggested_end_date = model.suggested_end_date * 1000
//         this.description = model.description
//         this.type = model.type
//         this.images = model.images
//         // this.recommended_time_spent = model.recommended_time_spent
//         this.min_time_spend = model.min_time_spend
//         this.max_time_spend = model.max_time_spend
//         this.booking_link = model.booking_link
//         this.cost = model.cost
//         this.cluster = model.cluster
//         this.distance = model.distance
//         this.duration = model.duration
//         this.opening_hours = model.opening_hours !== null ? new OpeningHours(model.opening_hours) : null
//     }
// }

export interface IScheduleActivity {
    id: string
    suggested_start_date: Date
    suggested_end_date: Date
    cluster: number
    activity: TripActivity
}

class ScheduleActivity {
    public id: string
    public suggested_start_date: Date
    public suggested_end_date: Date
    public cluster: number
    public activity: TripActivity

    constructor(model: IScheduleActivity) {
        this.id = model.id
        // Dates in UTC
        this.suggested_start_date = new Date(model.suggested_start_date) // "2024-02-29T09:00:00Z" model.suggested_start_date * 1000
        this.suggested_end_date = new Date(model.suggested_end_date) //model.suggested_end_date * 1000
        this.cluster = model.cluster
        this.activity = new TripActivity(model.activity)
    }
}

export interface ISchedule {
    id: number
    activities: IScheduleActivity[]
    city: ITrip
}

export class Schedule {
    public id: number
    public activities: IScheduleActivity[]
    public city: ITripResponse
    
    constructor(model: ISchedule) {
        this.id = model.id
        //this.start_date = model.start_date * 1000
        //this.end_date = model.end_date * 1000
        this.activities = model.activities.map(activity => new ScheduleActivity(activity))
        this.city = new Trip(model.city)
    }
}

// class Schedule implements ISchedule {
//     id: number
//     start_date: number
//     end_date: number
//     activities: IScheduleActivity[]

//     constructor(id: number, start_date: number, end_date: number, activities: IScheduleActivity[]) {
//         this.id = id
//         this.start_date = start_date * 1000
//         this.end_date = end_date * 1000
//         this.activities = activities
//     }
// }


export interface ITripCreateProfile {
    id: string
    name: string
    pace: number
    trip: string
    styles: ITripStyle[]
    created_at: string
    updated_at: string
}

export interface ITripResponseProfile {
    id: string
    name: string
    pace: number
    trip: string
    styles: ITripStyle[]
    total_trip_activities: number
    created_at: string
    updated_at: string
}

export interface ITripStyle {
    id: string
    name: string
    created_at: string
    updated_at: string
}
