export default function HomeHeader() {
    
    return (
        <div>
            <section className="bg-gradient-to-r from-[#EFC7E6] to-[#95ADDD] overflow-hidden relative rounded-lg">
                <div className="bg-fixed absolute blur-[8px] min-h-full min-w-full " 
                    style={{
                        backgroundImage: `url('/assets/images/backgrounds/world-map-hex.svg')`}}
                    >
                </div>
                    
                {/* bg-explraGrey-primary  bg-gradient-to-r from-cyan-500 to-blue-500 */}
                <section className="relative pb-4 ">
                    {/* <img className="hidden lg:block absolute top-0 left-0 mt-40" src="/assets/icons/dots/yellow-dot-left-bars.svg" alt="" />
                    <img className="hidden lg:block absolute bottom-0 right-0 mb-52" src="/assets/icons/dots/red-dot-right-shield.svg" alt="" /> */}
                    <div className="relative container px-4 mx-auto text-center">
                        <div className="text-7xl font-extrabold my-20">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#EFC7E6] from-20% to-[#522AC4]">
                                Explra
                            </span>
                        </div>
                        {/* <h2 className="mt-4 mb-8 lg:mb-12 text-white text-4xl lg:text-6xl font-semibold">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-explraBlue-primary to-violet-500">
                                
                            </span>
                        </h2> */}
                        <p className="max-w-xl mx-auto pb-24 lg:mb-12 text-[#552ebf] text-3xl font-semibold">
                            {/* <b>Its easy and free!</b> <br/> Trip Builder? */}
                            Your ultimate online travel planner!<br />
                            Find new places and activities for your next trip.
                            {/* Enter your destination and explore a tailored list of recommendations just for you! */}
                        </p>
                        {/* <ExplraButton
                            href="/itineraries/"
                            reloadDocument={true}
                            text="View pre-made Itineraries"
                            style="primary"
                            additional="mb-2 md:mb-0 mr-2" />

                        <ExplraButton
                            href="/blog/"
                            reloadDocument={true}
                            text="Read our Blog"
                            style="secondary"
                            additional="mr-4" /> */}
                        
                    </div>
                    
                </section>
            </section>
            
        </div>
    )
}