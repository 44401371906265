import FeaturedBlogSingle from "components/featureBlogSingle";
import { Outlet, Link } from "react-router-dom";
import PlaceCard from "./placeCard";

export default function FeaturedBlog({heading, urlLink, caption}: {heading: string, urlLink: string, caption: string}) {
    const blogs = [
        {
            id: 0,
            title: "Wanderlust Masterclass: Your Comprehensive Guide to Embracing a Nomadic Lifestyle",
            link: "https://explra.com/blog/wanderlust-masterclass-your-comprehensive-guide-to-embracing-a-nomadic-lifestyle/",
            related_to: "",
            image: "https://explra.com/blog/wanderlust-masterclass-your-comprehensive-guide-to-embracing-a-nomadic-lifestyle/wanderlust.jpg",
            summary: "Unleash your inner nomad! Explore the joy of minimalism, work that fuels wanderlust, and tips for embracing adventure while respecting global cultures.",
            date_published: "18/06/2024",
        },
        {
            id: 1,
            title: "Exploring the Sand and Sea of Saint Lucia's Choc Bay",
            link: "https://explra.com/blog/exploring-the-sand-and-sea-of-saint-lucias-choc-bay/",
            related_to: "",
            image: "https://explra.com/blog/exploring-the-sand-and-sea-of-saint-lucias-choc-bay/choc-bay.jpg",
            summary: "Uncover sun-kissed serenity, enthralling history and vibrant culture at Choc Bay, Saint Lucia! Dive into this coastal paradise for an unforgettable Caribbean experience!",
            date_published: "06/16/2023",
        },
        {
            id: 2,
            title: "Unveiling the Richness of Saint Kitts and Nevis",
            link: "https://explra.com/blog/island-adventures-unveiling-the-richness-of-saint-kitts-and-nevis/",
            related_to: "",
            image: "https://explra.com/blog/island-adventures-unveiling-the-richness-of-saint-kitts-and-nevis/saint-kitts.jpg",
            summary: "Discover the magical journey through time, culture, and nature in Saint Kitts and Nevis, a Caribbean gem that bursts with rich history, vibrant celebrations, and captivating landscapes!",
            date_published: "06/11/2023",
        },
    ]
    return (
        <section className="relative py-20">
            <div className="container px-4 mx-auto flex flex-wrap -m-3">
                <div className="w-full">
                    <h1 className="inline-block text-2xl font-semibold font-heading">{heading}</h1>
                    <Link 
                        className="font-medium text-sm text-explraRed underline hover:no-underline ml-2"
                        reloadDocument={true}
                        to={urlLink}> View More</Link>
                    {/* <a className="font-medium text-sm text-explraRed underline hover:no-underline" href={urlLink}>View more</a> */}
                    <p className="mb-4 text-gray-500 leading-relaxed">{caption}</p>
                </div>

                {/* Start Loop */}
                <div className="sm:columns-3 space-y-4">
                    <PlaceCard item={blogs[0]}/>
                    <PlaceCard item={blogs[1]} />
                    <PlaceCard item={blogs[2]} />
                </div>
            </div>
        </section>
    )
}