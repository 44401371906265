import ExplraButton from "components/button"
import ExplraFeatures from "components/explraFeatures"
import { useDocumentTitle } from "services/setDocumentTitle"


export default function TripPlanningPage() {
    const [docTitle, setDocTitle] = useDocumentTitle('Explra for Trip Planning')
    return (
        <div>
            <div className="">
                <div className="container px-4 mx-auto max-w-4xl">
                    <div className="text-center">
                        <h1 className="text-xl mb-8 font-semibold font-heading text-blue-text">
                            Trip Planning with Explra
                        </h1>
                        <p className="text-left font-light">
                        <img src="/assets/images/mocks/mockup-home-combined.png" className="m-4 max-w-md float-left"/>
                        Do you want to explore a new city or country, but don't know where to start? With Explra's Trip Planner, we help you create a customized travel itinerary based on your preferences and interests. Simply answer a few questions about what you want to see and do while visiting the destination, and we'll provide you with a curated list of attractions and events that match your taste.
                        <br /><br />
                        Our app uses advanced algorithms to analyze your travel preferneces, such as interestsand preferences and then recommends personalized itineraries for each destination. Whether you're a foodie, a history buff, or an adventure seeker, Trip Planning will help you plan the perfect trip based on your specific needs and interests.
                        <br /><br />
                        With our app, you can also share your travel plans with friends and family, who can offer their input and suggestions to make the itinerary even better. You can also track your progress as you go through each day of your trip, making adjustments along the way if needed.
                        <br /><br />
                        Explra's Trip Planning feature is a must-have for anyone planning a trip, whether it's your first time traveling or a regular vacation. Start using our app today and start exploring with ease!
                        </p>    
                        <div className="mt-6">
                            <ExplraButton
                                href='/trip-planner'
                                text="Take me to the trip planner"
                                style="primary"
                                size={4} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-16 pt-12 bg-[#F2EAF5]">
                <div className="container px-4 mx-auto">
                    <ExplraFeatures />    
                </div>
            </div> 
        </div>
    )
}